import { CSSProperties, ChangeEventHandler, FocusEventHandler, HTMLInputTypeAttribute, useEffect, useState } from "react";
import { UserUpdateInterface, Users } from "../../../../@types/users.types";
import { environment } from "../../../../common/environments";
import DashboardBack from "../../../dashboard/back";
import { useNavigate, useParams } from "react-router-dom";
import InputComponent from "../../../../component/input";
import "./index.css"
import BlockComponent from "../../../../component/block";
import ButtonComponent from "../../../../component/button";
import { AlertsService } from "../../../../services/alerts.service";
import { useRequestProvider } from "../../../../context/request.provider.context";
import { formatBirthday, formatBirthdayDate, formatCpf, formatCpfInput, formatPhoneNumber } from "../../../../common/utilitaries";
import moment from "moment";
import { RequestsProps } from "../../../../@types/request.types";
import { RequestsService } from "../../../../services/requests.service";
import { DashboardCreateProps } from "../../../../@types/dashboard.component.types";
import { configurationsSubMenus } from "../../props";
import DashboardCreateComponent from "../../../../component/dashboard/create";
import { DefaultResponse } from "../../../../@types/response.types";

const UserUpdateComponent = (props: UserUpdateInterface) => {

    const navigate = useNavigate()
    const params = useParams()
    const { fetchUser } = useRequestProvider()

    const isProfile = props.isProfile ? props.isProfile : false
    const legend = !isProfile ? "Revise o cadastro do usuário" : "Revise as informações do seu cadastro"
    const backUrl = isProfile ? `${environment.ROUTES.MY_PROFILE}` : `${environment.ROUTES.CONFIGURATIONS.USER.LIST}/${ params.id || -1 }`

    const [user, setUser] = useState({} as Users)
    const [isFetched, setFetched] = useState(false)
    const [data, setData] = useState(new Map<any, any>())
    const [file, setFile] = useState(null)

    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: !props.isProfile ? "Usuários" : "",
        lateralMenu: !props.isProfile ? "Configurações" : "Meu perfil",
        subMenus: !props.isProfile ? configurationsSubMenus() : []
    }

    const update = (event: any, name: string) => {
        const value = event.target.value
        const files = event.target.files || []
        if (files.length > 0) {
            const file = files[0]
            return setFile(file)
        }

        if (value.length < 1 || value == undefined) return setData(data.set(name, undefined))
        setData(data.set(name, event.target.value))
    }

    const redirectToList = () => {
        const url = isProfile ? environment.ROUTES.MY_PROFILE : environment.ROUTES.CONFIGURATIONS.USER.LIST
        if (!isProfile) {
            AlertsService.notification({ icon: "error", title: "Não foi possível encontrar esse usuário." })
        }

        setTimeout(() => navigate(url, { replace: true }), isProfile ? 0 : 800)
        return;
    }

    const makeInput = (options: { 
        name?: string;
        value?: string;
        id?: string;
        type: HTMLInputTypeAttribute, 
        withStyle?: boolean;
        isNecessary?: boolean;
        labelName?: string, 
        labelDirection?: "right" | "left"
        labelStyle?: CSSProperties
        placeholder?: string, 
        onChange: ChangeEventHandler<HTMLInputElement> 
        onBlur?: FocusEventHandler;
        onBlurCapture?: FocusEventHandler;
    }) => {
        return (
            <InputComponent
                id={options.id}
                name={options.name}
                value={options.value}
                type={options.type}
                hasLabel={ options.labelName != undefined }
                label={options.labelName}
                placeholder={options.placeholder}
                onChange={options.onChange}
                labelStyle={options.labelStyle || { fontSize: "1rem", margin: "0" }}
                labelDirection={options.labelDirection || "left"}
                style={ options.withStyle ? { fontSize: "1rem", width: "100%", marginBottom: "1vh", borderRadius: "5px" } : undefined }
                isNecessary={options.isNecessary}
                onBlur={options.onBlur}
                onBlurCapture={options.onBlurCapture}
                autoComplete={false}
            />
        )
    }

    const updateInfos = async (event: any) => {
        event.preventDefault()
        if (data.size < 1) {
            return AlertsService.notification({ icon: "warning", title: "Não há dados a serem atualizados" })
        }

        const stringfy = JSON.stringify(Object.fromEntries(data))
        const json = JSON.parse(stringfy)

        let updateObject = {
            ...json,
            photo: file
        }

        const { password1, password2 } = json
        if (password1 && password2 == undefined) {
            return AlertsService.notification({ icon: "error", title: "É necessário repetir novamente a senha." })
        }

        if (password1 != undefined && password2 != undefined) {
            if (password1 != password2) return AlertsService.notification({ icon: "error", title: "As senhas não coicidem" })

            updateObject = {
                ...updateObject,
                password1: undefined,
                password2: undefined,
                password: password1
            }
        }

        const result = await AlertsService.confirmation({
            title: "Atualizar informações do usuário?",
            icon: "question",
        })

        if (!result.isConfirmed) return;

        const message = !isProfile ? "Ocorreu um erro ao atualizar os dados do usuário." : "Ocorreu um erro ao atualizar seus dados."

        try {
            const formData = new FormData()
            for (var key in updateObject) {
                formData.append(key, updateObject[key])
            }

            const url = isProfile ? `${environment.URLS.USER.UPDATE}` : `${environment.URLS.USER.UPDATE}/${user.id}`
            const requestObject: RequestsProps = {
                method: "PUT",
                url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const response = await RequestsService.call(requestObject)
            if (!response) {
                return AlertsService.notification({ icon: "error", title: message })
            }

            const newUser = response.data.data
            props.updateUser(newUser)

            AlertsService.notification({ icon: "success", title: "Sucesso! Dados atualizados com sucesso." })
            setTimeout(() => {
                const returns = isProfile ? environment.ROUTES.MY_PROFILE : `${environment.ROUTES.CONFIGURATIONS.USER.LIST}/${user.id}`

                navigate(returns, { replace: true })
            }, 1200)
        } catch (err) {
            return AlertsService.notification({ icon: "error", title: message })
        }
    }

    useEffect(() => {
        if (isFetched) return;

        const timeout = setTimeout(async () => {
            try {
                if (isFetched) return

                // É atualização de perfil?
                if (isProfile) {
                    if (Object.keys(props.user).length < 1) return redirectToList()

                    setFetched(true)
                    setUser(props.user)
                    return
                }

                // Buscando a turma
                const { USER } = environment.URLS
                const request = await RequestsService.call({
                    method: "GET",
                    url: USER.INFO + "/" + params.id
                })

                if (!request || request && !request.data) throw new Error("no response found")
                const response: DefaultResponse = request.data
                if (!response.success) {
                    return redirectToList()
                }
                
                const user: Users = response.data

                setFetched(true)
                setUser(user)
            } catch (e) {
                AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao buscar o usuário cadastrado." })
            }
        })

        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>
            <DashboardBack backUrl={backUrl} />

            <div className="user-update-block">
                <BlockComponent title="Atualização de informações" legend={ legend } style={{ paddingBottom: "2vh" }}>
                    <div className="user-update-component">
                        { makeInput({ 
                            type: "text", 
                            withStyle: true, 
                            labelName: "Nome completo", 
                            placeholder: user.name, 
                            value: user.name,
                            onChange: (e: any) => update(e, "name") 
                        }) }
                        
                        { makeInput({ 
                            type: "email", 
                            withStyle: true, 
                            labelName: "E-mail", 
                            placeholder: user.email, 
                            onChange: (e: any) => update(e, "email") 
                        }) }
                        
                        { isProfile ? 
                            makeInput({ type: "password", withStyle: true, labelName: "Senha antiga", onChange: (e: any) => update(e, "last_password") }) : 
                            undefined }
                        
                        { makeInput({ 
                            type: "password", 
                            withStyle: true, 
                            labelName: "Senha", 
                            onChange: (e: any) => update(e, "password1") 
                        }) }
                        
                        { makeInput({ 
                            type: "password", 
                            withStyle: true, 
                            labelName: "Repita a senha", 
                            onChange: (e: any) => update(e, "password2") 
                        }) }
                        
                        { makeInput({ 
                            type: "text", 
                            withStyle: true, 
                            labelName: "Registro geral de identificação (RGI)", 
                            placeholder: formatCpf(user.rgi || ""), 
                            value: formatCpf(user.rgi || ""),
                            onBlur: formatCpfInput,
                            onChange: (e: any) => update(e, "rgi") 
                        }) }
                        
                        { makeInput({ 
                            type: "text", 
                            withStyle: true, 
                            labelName: "Telefone", 
                            placeholder: formatPhoneNumber(user.phone), 
                            value: formatPhoneNumber(user.phone),
                            onChange: (e: any) => update(e, "phone") 
                        }) }
                        
                        { makeInput({ 
                            type: "file",
                            withStyle: true, 
                            labelName: "Foto de perfil", 
                            onChange: (e: any) => update(e, "photo") 
                        }) }
                        
                        { makeInput({ 
                            type: "text", 
                            withStyle: true, 
                            labelName: "Data de aniversário", 
                            placeholder: formatBirthday(user.birthday || new Date("01/01/2000")), 
                            value: formatBirthday(user.birthday),
                            onChange: (e: any) => update(e, "birthday") 
                        }) }
                        
                        { makeInput({ 
                            type: "text",
                            withStyle: true, 
                            labelName: "Instagram", 
                            placeholder: user.instagram || "https://instagram.com/", 
                            value: user.instagram,
                            onChange: (e: any) => update(e, "instagram") 
                        }) }

                        <div className="user-update-component-button">
                            <ButtonComponent
                                text={ isProfile ? "Atualizar minhas informações" : "Atualizar informações do usuário" }
                                type="button"
                                onClick={updateInfos}
                            />
                        </div>
                    </div>
                </BlockComponent>
            </div>
        </DashboardCreateComponent>
    )
}

export default UserUpdateComponent;
import { useNavigate, useParams } from "react-router-dom";
import { ModulesInterface, TableColumns } from "../../@types/component.types";
import { environment } from "../../common/environments";
import DashboardBack from "../dashboard/back";
import "./index.css"
import { useEffect, useState } from "react";
import { RequestsService } from "../../services/requests.service";
import { ModuleResponse, Modules, ModulesClase } from "../../@types/modules.types";
import { AlertsService } from "../../services/alerts.service";
import { SiGoogleclassroom } from "react-icons/si";
import BlockComponent from "../../component/block";
import PieGraphComponent from "../../component/graphs/pie";
import { GraphData } from "../../@types/graphs.types";
import { MdDelete, MdModeEdit } from "react-icons/md";
import TableComponent from "../../component/table";
import ButtonComponent from "../../component/button";
import { DefaultResponse } from "../../@types/response.types";
import { useRequestProvider } from "../../context/request.provider.context";
import Swal from "sweetalert2";
import { Users } from "../../@types/users.types";
import DashboardCreateComponent from "../../component/dashboard/create";
import { DashboardCreateProps } from "../../@types/dashboard.component.types";
import { modulesSubMenus } from "./props";
import { Classes, ClassesResponse } from "../../@types/classes.types";

const ModulesInfoComponent = (props: ModulesInterface) => {
    
    const navigate = useNavigate()
    const params = useParams()
    const columns: TableColumns[] = [
        { id: "id", label: "Id", format: (value: any) => `#${value}` },
        { id: "name", label: "Nome" },
        { id: "link", label: "Link" },
        { id: "actions", label: "Ações" }
    ]
    const columnsUsers: TableColumns[] = [
        { id: "id", label: "Id", format: (value: any) => `#${value}` },
        { id: "name", label: "Nome" },
        { id: "actions", label: "Ações" }
    ]
    const columnsClasses: TableColumns[] = [
        { id: "id", label: "Id", format: (value: any) => `#${value}` },
        { id: "name", label: "Nome" },
        { id: "students", label: "Alunos cadastrados", format: (value: any) => `${value} aluno(s)` },
        { id: "actions", label: "Ações" }
    ]
    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: "Módulos",
        lateralMenu: "Módulos",
        subMenus: modulesSubMenus()
    }

    const { fetchUsers, fetchClasses } = useRequestProvider()
    const [isFetched, setFetched] = useState(false)
    const [module, setModule] = useState<Modules>()
    const [frequency, setFrequency] = useState<GraphData[]>([])

    const [clasesRows, setClasesRows] = useState<any>([])
    const [usersRows, setUsersRows] = useState<any>([])
    const [classesRows, setClassesRows] = useState<any>([])

    const updateModuleInfo = (module: Modules) => {
        setModule(module)
        setFetched(true)

        setClasesRows(module.clases.map((clase: ModulesClase) => {
            return {
                id: clase.id,
                name: clase.name,
                link: clase.link,
                actions: (
                    <div className="modules-info-content-icons">
                        <MdModeEdit onClick={(e) => editOrCreateClase(e, { clase })} />
                        <MdDelete onClick={(e) => removeClase(e, clase)} />
                    </div>
                )
            }
        }))

        setUsersRows(module.users.map((user: Users) => {
            return {
                id: user.id,
                name: user.name,
                actions: (
                    <div className="modules-info-content-icons">
                        <MdDelete onClick={(e) => removeUser(e, user, module)} />
                    </div>
                )
            }
        }))

        setClassesRows(module.classes.map((classes: Classes) => {
            return {
                id: classes.id,
                name: classes.name,
                students: classes.users?.length || 0,
                actions: (
                    <div className="modules-info-content-icons">
                        <MdDelete onClick={(e) => removeClasses(e, module, classes)} />
                    </div>
                )
            }
        }))

    }

    const searchModule = async (withAlert?: boolean, withTimeout?: boolean) => {
        try {
            const request = await RequestsService.call({
                method: "GET",
                url: environment.URLS.MODULES.BY_ID + params.id,
                withTimeout,
                timeoutSeconds: withTimeout ? 2000 : undefined
            })

            if (!request || !request.data) throw new Error("No response found")
            const response: ModuleResponse = request.data
            if (!response.success) throw new Error(response.message)

            const module: Modules = response.data

            updateModuleInfo(module)

            if (withAlert !== undefined && withAlert) {
                await AlertsService.notification({ icon: "success", title: response.message })
            }

        } catch (err: any) {
            return AlertsService.notification({ icon: "error", 
                title: err.response?.data?.message || "Ocorreu um erro ao obter as informações do módulo" })
        }
    }

    const searchModuleFrequency = async (withAlert?: boolean) => {
        try {
            const request = await RequestsService.call({
                method: "GET",
                url: environment.URLS.REPORTS.MODULES_FREQUENCY,
                params: {
                    moduleId: params.id
                }
            })

            if (!request || !request.data) throw new Error("No response found")
            const response: any = request.data
            if (!response.success) throw new Error(response.message)

            const data = response.data || []
            if (data.length < 1) throw new Error("No data found")

            const dados = data[0]

            setFrequency([
                { name: "Iniciado", value: dados.started || 0 },
                { name: "Concluído", value: dados.concluded || 0 }
            ])

            if (withAlert != undefined && withAlert) {
                await AlertsService.notification({ icon: "success", title: response.message })
            }
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || "Ocorreu um erro ao obter os módulos cadastrados" })
        }
    }
    
    const deleteModule = async (event: any) => {
        event.preventDefault()

        const result = await AlertsService.confirmation({
            title: "Deletar módulo?",
            html: `Você estará deletando o módulo <strong>${module?.name}</strong>. Ação não reversível`,
            confirmButtonText: "Deletar",
            cancelButtonText: "Cancelar"
        })

        if (!result.isConfirmed) return

        try {
            const request = await RequestsService.call({
                method: "DELETE",
                url: environment.URLS.MODULES.DELETE + module?.id,
                params: {
                    removePermanently: true
                }
            })

            if (!request || !request.data) throw new Error("No response found")
            const response: DefaultResponse = request.data
            if (!response.success) throw new Error(response.message)

            AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })

            setTimeout(() => navigate(environment.ROUTES.MODULES.HOME, { replace: true }), 1000)
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || "Ocorreu um erro ao deletar o módulo" })
        }
    }

    const editOrCreateClase = async (event: any, options: { clase?: ModulesClase, isCreate?: boolean }) => {
        event.preventDefault()
        
        const clase = options.clase
        const isCreate = options.isCreate != undefined ? options.isCreate : false

        const nameResult = await AlertsService.confirmation({
            title: "Nome da aula",
            html: "Informe o novo nome da aula.",
            input: "text",
            inputPlaceholder: !isCreate && clase ? clase.name : "Informe o nome da aula",
            inputValue: !isCreate && clase ? clase.name : "",
            inputValidator: (value: any) => {
                if (!isCreate) return null
                if (value != undefined) return null
                return "Informe o nome corretamente"
            },
            confirmButtonText: "Próximo passo",
            cancelButtonText: "Cancelar"
        })

        if (!nameResult.isConfirmed) return
        const name = nameResult.value

        const linkResult = await AlertsService.confirmation({
            title: "Link da aula",
            html: "Informe o novo link da aula",
            input: "text",
            inputPlaceholder: !isCreate && clase ? clase.link : "Informe o link",
            inputValue: !isCreate && clase ? clase.link : "",
            inputValidator: (value: any) => {
                if (!isCreate) return null
                if (value != undefined) return null
                return "Informe o link corretamente"
            },
            confirmButtonText: "Próximo passo",
            cancelButtonText: "Cancelar"
        })

        if (!linkResult.isConfirmed) return
        const link = linkResult.value

        const result = await AlertsService.confirmation({
            title: "Confirmação",
            html: `
            Confira as informações se estão corretas.<br /><br />
            Nome: ${name}<br />
            Link da aula: ${link}
            `,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        })

        if (!result.isConfirmed) return

        try {
            const url = isCreate ? environment.URLS.MODULES.CLASE.ADD : environment.URLS.MODULES.CLASE.UPDATE + clase?.id
            const request = await RequestsService.call({
                method: isCreate ? "POST" : "PUT",
                url,
                data: {
                    name,
                    link,
                    moduleId: isCreate && module ? module.id : undefined
                }
            })

            if (!request || !request.data) throw new Error("No data response")
            const response: DefaultResponse = request.data

            if (response.success) {
                await Promise.all([ searchModule(false), searchModuleFrequency(false) ])
            }

            return AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || err.message || "Ocorreu um erro ao atualizar a aula" })
        }
    }

    const removeClase = async (event: any, clase: ModulesClase) => {
        event.preventDefault()

        const result = await AlertsService.confirmation({
            icon: "question",
            title: "Remover Aula?",
            html: `Você estará removendo a aula <strong>${clase.name}</strong>`,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        })

        if (!result.isConfirmed) return

        try {
            const request = await RequestsService.call({
                method: "DELETE",
                url: environment.URLS.MODULES.CLASE.DELETE + clase.id
            })

            if (!request || !request.data) throw new Error("No data response")
            const response: DefaultResponse = request.data

            if (response.success) {
                await Promise.all([ searchModule(), searchModuleFrequency() ])
            }

            return AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || err.message || "Ocorreu um erro ao remover a aula" })
        }
    }

    const addUser = async (event?: any): Promise<any> => {
        if (event) event.preventDefault()

        const userResult = await AlertsService.confirmation({
            title: `Informe o nome do usuário`,
            input: "text",
            icon: "question",
            showLoaderOnConfirm: true,
            confirmButtonText: "Buscar",
            cancelButtonText: "Cancelar",
            preConfirm: async (name) => {
                try {
                    const users = await fetchUsers({ name })
                    if (!users) {
                        throw new Error("Não foi possível buscar os usuários")
                    }

                    return users.success ? users.data : undefined
                } catch (err) {
                    Swal.showValidationMessage(`Ocorreu um erro: ${err}`)
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })

        if (!userResult.isConfirmed || !userResult.value) return
        // Não retornou nada
        if (userResult.value && userResult.value.length < 1) {
            const resultBack = await AlertsService.confirmation({
                title: `A busca não retornou resultados.`,
                icon: "question",
                confirmButtonText: "Voltar",
                cancelButtonText: "Cancelar busca",
            })

            if (!resultBack.isConfirmed) return
            return await addUser()
        }

        const inputOptions: any = {}
        userResult.value.forEach((user: Users) => {
            if (user) {
                inputOptions[user.id] = user.name
            }
        })

        const result = await AlertsService.confirmation({
            title: "Selecione um usuário",
            input: "select",
            inputOptions,
            confirmButtonText: "Adicionar usuário",
            cancelButtonText: "Cancelar operação"
        })

        if (!result.isConfirmed || !result.value) return;

        try {
            const request = await RequestsService.call({
                method: "POST",
                url: environment.URLS.MODULES.ADD_USER,
                data: {
                    user_id: result.value,
                    module_id: module?.id
                }
            })

            if (!request || !request.data) throw new Error("No data response")
            const response: DefaultResponse = request.data

            if (response.success) {
                await Promise.all([ searchModule(), searchModuleFrequency() ])
            }

            return AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || err.message || "Ocorreu um erro ao adicionar um usuário ao módulo" })
        }
    }

    const removeUser = async (event: any, user: Users, module: Modules) => {
        event.preventDefault()

        const result = await AlertsService.confirmation({
            title: "Remover aluno",
            html: `Você tem certeza que deseja remover o aluno <strong>${ user.name }</strong> do módulo?`,
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        })

        if (!result.isConfirmed) return

        try {
            const request = await RequestsService.call({
                method: "DELETE",
                url: environment.URLS.MODULES.REMOVE_USER,
                data: {
                    user_id: user.id,
                    module_id: module?.id
                }
            })

            if (!request || !request.data) throw new Error("No data response")
            const response: DefaultResponse = request.data

            if (response.success) {
                await Promise.all([ searchModule(), searchModuleFrequency() ])
            }

            return AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || err.message || "Ocorreu um erro ao remover o aluno do módulo" })
        }
    }

    const addClasse = async (event?: any): Promise<any> => {
        if (event) event.preventDefault()

        const classes: ClassesResponse = await fetchClasses()
        if (!classes || (classes && !classes.success)) {
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao obter as turmas cadastradas" })
        }
        
        const classesList: Classes[] = classes.data || []
        const classesInput: any[] = classes.data?.map((classe: Classes) => classe.name) || []
        let classesSelected: number[] = []
        let classesDisplay: any[] = []

        const classesResult = await AlertsService.confirmation({
            title: "Selecione a turma",
            html: "Selecione a turma que terá acesso ao conteúdo do módulo",
            input: "select",
            confirmButtonText: "Próximo passo",
            cancelButtonText: "Cancelar",
            inputOptions: classesInput,
            inputLabel: "Turma(s) selecionada(s): Nenhuma",
            inputPlaceholder: "Selecione uma turma",
            inputValidator: () => new Promise((resolve) => 
                resolve(classesDisplay.length < 1 ? "É necessário selecionar pelo menos um dia da semana." : null)),
            didOpen: () => {
                Swal.getInput()?.addEventListener("change", async (event: any) => {
                    const { options, selectedIndex } = event.target
                    const classeName = options[selectedIndex].text
                    const classe: any = classesList.find((classe: Classes) => classe.name === classeName)

                    // Está mostrando
                    if (classesDisplay.find((name: any) => name === classe.name)) {
                        classesDisplay = classesDisplay.filter((name: string) => name !== classe.name)
                        classesSelected = classesSelected.filter((id: number) => id !== classe.id)
                    } else {
                        classesDisplay.push(classe.name)
                        classesSelected.push(classe.id)
                    }

                    classesDisplay = classesDisplay.filter((classe: any) => classe)
                    const sizeOf = classesDisplay.length
                    const text = `Turma(s) selecionada(s): ${sizeOf < 1 ? "Nenhuma" : classesDisplay.join(", ")}.`
                    document.getElementsByClassName("swal2-input-label")[0].innerHTML = text
                })
            }
        })

        if (!classesResult.isConfirmed) return

        const result = await AlertsService.confirmation({
            title: "Confirmação",
            html: `Confirma as novas turmas para o módulo:<br /><br />
            <strong>Turmas</strong>: ${classesDisplay.join(", ")}.<br />
            `,
            confirmButtonText: "Adicionar turma",
            cancelButtonText: "Cancelar operação"
        })

        if (!result.isConfirmed) return

        try {
            const request = await RequestsService.call({
                method: "PUT",
                url: environment.URLS.MODULES.UPDATE + module?.id,
                data: {
                    classes: module?.classes.map((classe: Classes) => classe.id).concat(classesSelected)
                }
            })

            if (!request || !request.data) throw new Error("No response found")
            const response: DefaultResponse = request.data
            if (!response.success) throw new Error(response.message)

            AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })

            if (response.success) {
                updateModuleInfo(response.data)
            }
        } catch (err: any) {
            return AlertsService.notification({
                icon: "error",
                title: err.response.data.message || err.message || "Ocorreu um erro na criação do módulo"
            })
        }
    }

    const removeClasses = async (event: any, module: Modules, classe: Classes) => {
        event.preventDefault()

        const result = await AlertsService.confirmation({
            icon: "question",
            title: "Remover Turma?",
            html: `Você estará removendo a turma <strong>${classe.name}</strong> deste módulo`,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        })

        if (!result.isConfirmed) return

        try {
            const request = await RequestsService.call({
                method: "DELETE",
                url: environment.URLS.MODULES.REMOVE_CLASSE,
                params: {
                    moduleId: module.id,
                    classeId: classe.id
                }
            })

            if (!request || !request.data) throw new Error("No response found")
            const response: DefaultResponse = request?.data
            if (!response.success) throw new Error(response.message)

            AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })

            if (response.success) {
                searchModule(false)
            }
        } catch (err: any) {
            return AlertsService.notification({
                icon: "error",
                title: err?.response?.data?.message || "Ocorreu um erro ao deletar a turma do módulo"
            })
        }
    }
 
    useEffect(() => {
        if (isFetched) return

        const timeout = setTimeout(() => {
            searchModule(true)
            searchModuleFrequency(false)
        }, 0)
        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>
            <DashboardBack backUrl={ environment.ROUTES.MODULES.HOME } />

            <div className="modules-info-conents-block">
                <div className="modules-info">
                    <div className="modules-info-content">
                        <div className="modules-info-content-actions">
                            <ButtonComponent type="button" text={(<MdDelete />)} onClick={deleteModule} />
                        </div>

                        <div className="modules-info-content-header">
                            <div className="modules-info-content-header-icon">
                                <SiGoogleclassroom />
                            </div>

                            <div className="modules-info-content-title">
                                <p><strong>{ !isFetched || !module ? "..." : module.name }</strong></p>
                            </div>
                        </div>

                        <div className="modules-info-content-info">
                            <p><strong>Descrição:</strong> { !isFetched || !module ? "..." : module.description }</p>
                            <p><strong>Aulas:</strong> { !isFetched || !module ? "..." : module.clases.length } aula(s)</p>
                            <p><strong>Alunos:</strong> { !isFetched || !module ? "..." : module.users.length } aluno(s)</p>
                            <p><strong>Turmas:</strong> { !isFetched || !module ? "..." : module.classes.length } turma(s)</p>
                        </div>
                    </div>

                    <div className="modules-info-graph">
                        <BlockComponent title="Gráfico de conclusão" legend="Veja a relação inicio x conclusão do módulo">
                            <PieGraphComponent
                                pieData={frequency}
                                color={["#0091EA", "#00C853"]}
                                content={{
                                    hasLoaded: true,
                                    hasError: false,
                                    showTotal: {
                                        enable: true,
                                        title: "Total:"
                                    }
                                }}
                            />
                        </BlockComponent>
                    </div>

                    <div className="modules-info-clases">
                        <BlockComponent 
                            title="Tutoriais cadastrados" 
                            legend="Lista de tutoriais cadastrados para o módulo">
                            
                            <div className="modules-content-actions">
                                <ButtonComponent 
                                    type="button" 
                                    text="Cadastrar tutorial" 
                                    onClick={(e) => editOrCreateClase(e, { isCreate: true })}
                                />
                            </div>

                            <TableComponent
                                columns={columns}
                                rows={clasesRows}
                                rowsPerPageOptions={[10, 25, 50]}
                                style={{
                                    borderRadius: "10px"
                                }}
                            />
                        </BlockComponent>
                    </div>
                </div>

                <div className="modules-classes-and-students">
                    <div className="modules-classes">
                        <BlockComponent 
                            title="Turmas"
                            legend="Lista de turmas atribuídas ao módulo"
                            style={{ paddingBottom: "1.5vh" }}>

                            <div className="modules-content-actions">
                                <ButtonComponent 
                                    type="button" 
                                    text="Adicionar turma" 
                                    style={{ backgroundColor: "#0091EA" }}
                                    onClick={addClasse}
                                />
                            </div>

                            <TableComponent
                                columns={columnsClasses}
                                rows={classesRows}
                                rowsPerPageOptions={[10, 25, 50]}
                                style={{
                                    borderRadius: "10px"
                                }}
                            />
                        </BlockComponent>
                    </div>

                    <div className="modules-students">
                        <BlockComponent 
                            title="Alunos"
                            legend="Lista de alunos atribuídos ao módulo"
                            style={{ paddingBottom: "1.5vh" }}>

                            <div className="modules-content-actions">
                                <ButtonComponent 
                                    type="button" 
                                    text="Adicionar aluno" 
                                    style={{ backgroundColor: "#0091EA" }}
                                    onClick={addUser}
                                />
                            </div>

                            <TableComponent
                                columns={columnsUsers}
                                rows={usersRows}
                                rowsPerPageOptions={[10, 25, 50]}
                                style={{
                                    borderRadius: "10px"
                                }}
                            />
                        </BlockComponent>
                    </div>
                </div>
            </div>
        </DashboardCreateComponent>
    )
}

export default ModulesInfoComponent
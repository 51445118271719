import { useEffect, useState } from "react"
import { MenuPrivateProps, TableColumns } from "../../../@types/component.types"
import ButtonComponent from "../../../component/button"
import TableComponent from "../../../component/table"
import "./index.css"
import { AlertsService } from "../../../services/alerts.service"
import { RequestsService } from "../../../services/requests.service"
import { environment } from "../../../common/environments"
import { Plans, PlansResponse } from "../../../@types/plans.types"
import { MdDelete, MdModeEdit } from "react-icons/md";
import { formatPlanPrice } from "../../../common/utilitaries"
import { DashboardCreateProps } from "../../../@types/dashboard.component.types"
import { configurationsSubMenus } from "../props"
import DashboardCreateComponent from "../../../component/dashboard/create"
import Swal from "sweetalert2"

const PlansComponent = (props: MenuPrivateProps) => {

    const [rows, setRows] = useState<any>([])
    const columns: TableColumns[] = [
        { id: "id", label: "Id", format: (value: any) => `#${value}` },
        { id: "name", label: "Nome" },
        { id: "price", label: "Valor", format: (value: any) => `R$${formatPlanPrice(value.toLocaleString('pt-br'))}` },
        { id: "isRecurrent", label: "Recorrente", format: (value: any) => Boolean(value) ? "Sim" : "Não"},
        { id: "days", label: "Dias", format: (value: any) => value + " dias"},
        { id: "paymentMethods", label: "Métodos de Pagamento", format: (value: any) => value.split(",").join(", ")},
        { id: "actions", label: "Ações" }
    ]

    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: "Planos",
        lateralMenu: "Configurações",
        subMenus: configurationsSubMenus()
    }

    const nameBox = async (htmlText: string, placeHolder: string, value?: string) => {
        try {
            const nameResult = await AlertsService.confirmation({
                title: "Nome do plano",
                html: htmlText,
                input: "text",
                inputPlaceholder: placeHolder,
                inputValue: value || "",
                confirmButtonText: "Próximo passo",
                cancelButtonText: "Cancelar"
            })
    
            if (!nameResult.isConfirmed) return undefined
            return nameResult.value
        } catch (err) {
            return undefined
        }
    }

    const priceBox = async (htmlText: string, placeHolder: any, inputValue?: any) => {
        try {
            const priceResult = await AlertsService.confirmation({
                title: "Preço do plano",
                html: htmlText,
                input: "text",
                inputPlaceholder: formatPlanPrice((placeHolder || "").toLocaleString("pt-br")),
                inputValue: formatPlanPrice((inputValue || "").toLocaleString("pt-br")),
                inputValidator: (value: any) => {
                    const searchWord = (word: string) => RegExp(`\\b${word}\\b`).test(value)
    
                    try {
                        if (value === null || value === undefined || value === "") return "O preço não pode ser nulo!"
                        if (!searchWord(",")) return "Exemplo de preço aceito: 90,00."
                        return null;
                    } catch (err: any) {
                        return err.message
                    }
                },
                confirmButtonText: "Próximo passo",
                cancelButtonText: "Cancelar",
            })
    
            if (!priceResult.isConfirmed) return undefined
            return priceResult.value
        } catch (err) {
            console.log(err)

            return undefined
        }
    }

    const recurrentBox = async (isRecurrent: boolean) => {
        try {
            const isRecurrentResult = await AlertsService.confirmation({
                title: "Plano recorrente?",
                html: "Informe se o pagamento do plano é recorrente ou apenas 1 (uma) vez.",
                input: "select",
                inputOptions: {
                    0: "Não recorrente",
                    1: "Recorrente",
                },
                inputValue: isRecurrent ? 1 : 0,
                confirmButtonText: "Próximo passo",
                cancelButtonText: "Cancelar",
            })
    
            if (!isRecurrentResult.isConfirmed) return undefined
            return isRecurrentResult.value
        } catch (err) {
            return undefined
        }
    }

    const searchPlans = async () => {
        try {
            const request = await RequestsService.call({
                method: "GET",
                url: environment.URLS.PLANS.ALL
            })

            if (!request || !request.data) throw new Error("No response found")
            const response: PlansResponse = request.data
            if (!response.success) throw new Error(response.message)

            const _rows = (response.data || []).map((plan) => {
                return {
                    id: plan.id,
                    name: plan.name,
                    price: plan.price,
                    isRecurrent: plan.isRecurrent,
                    days: plan.days,
                    paymentMethods: plan.paymentMethods,
                    actions: (
                        <div className="configurations-plans-content-icons">
                            <MdModeEdit onClick={ (e) => planFunction(e, plan) } />
                            <MdDelete onClick={(e) => planDelete(e, plan)} />
                        </div>
                    )
                }
            })

            setRows(_rows)
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || "Ocorreu um erro ao obter os planos cadastrados" })
        }
    }

    const planFunction = async (event: any, plan: Plans, isEdit?: boolean) => {
        event.preventDefault()

        const planName = await nameBox("Informe o novo nome do plano", plan.name, plan.name)
        if (!planName) return

        const planPrice = await priceBox("Informe o novo preço do plano. Exemplo: 90,00", plan.price, plan.price)
        if (!planPrice) return

        const isRecurrentValue = await recurrentBox(plan.isRecurrent)
        if (!isRecurrentValue) return

        try {
            const request = await RequestsService.call({
                method: "PUT",
                url: environment.URLS.PLANS.UPDATE + plan.id,
                data: {
                    name: planName,
                    price: (planPrice).replace(",", "."),
                    isRecurrent: isRecurrentValue
                }
            })

    
            if (!request || request && !request.data) throw new Error("No response found")
            const response: any = request.data
            const success = response.success

            await searchPlans()

            return AlertsService.notification({ icon: success ? "success" : "error", title: response.message })
        } catch (err) {
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao atualizar o plano" })
        }
    }

    const planDelete = async (event: any, plan: Plans) => {
        event.preventDefault()

        const result = await AlertsService.confirmation({
            icon: "question",
            title: "Você tem certeza?",
            html: `O plano <strong>${plan.name}</strong> será deletado do banco de dados.`,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        })

        if (!result.isConfirmed) return

        try {
            const request = await RequestsService.call({
                method: "DELETE",
                url: environment.URLS.PLANS.DELETE + plan.id
            })
    
            if (!request || request && !request.data) throw new Error("No response found")
            const response: any = request.data
            const success = response.success

            await searchPlans()

            return AlertsService.notification({ icon: success ? "success" : "error", title: response.message })
        } catch (err) {
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao deletar o plano" })
        }
    }

    const planCreate = async (event: any) => {
        event.preventDefault()

        const planName = await nameBox("Informe o nome do plano a ser criado", "Nome do plano")
        if (!planName) return

        const planPrice = await priceBox("Informe o preço do plano a ser criado", "90,00")
        if (!planPrice) return

        const planRecurrent = await recurrentBox(false)
        if (!planRecurrent) return

        const descriptionResult = await AlertsService.confirmation({
            title: "Descrição do plano",
            html: "Insira a descrição do plano a ser criado",
            input: "textarea",
            confirmButtonText: "Próximo passo",
            cancelButtonText: "Cancelar",
        })

        if (!descriptionResult.isConfirmed) return
        const planDescription = descriptionResult.value || ""

        const daysResult = await AlertsService.confirmation({
            title: "Dias do plano",
            html: "Informe os dias do plano a ser criado",
            input: "number",
            inputValidator: (value: any) => value === undefined ? "Informe o dia do plano corretamente" : null,
            confirmButtonText: "Próximo passo",
            cancelButtonText: "Cancelar",
        })

        if (!daysResult.isConfirmed) return
        const planDays = daysResult.value

        let paymentMethodsSelected: any[] = []
        const paymentMethods = ["CREDIT_CARD", "DEBIT_CARD", "PIX", "BOLETO"]
        const paymentMethodsResult = await AlertsService.confirmation({
            title: "Métodos de pagamento aceitos",
            html: "Selecione os métodos de pagamentos que serão aceitos para esse tipo de plano",
            confirmButtonText: "Próximo passo",
            cancelButtonText: "Cancelar",
            input: "select",
            inputOptions: paymentMethods,
            inputLabel: "Métodos selecionados: Nenhum.",
            inputPlaceholder: "Selecione um método de pagamento",
            inputValidator: () => new Promise((resolve) => 
                resolve(paymentMethodsSelected.length < 1 ? "Selecione um método de pagamento!" : null)),
            didOpen: () => {
                Swal.getInput()?.addEventListener('change', async (event: any) => {
                    const method = paymentMethods[event.target.value]

                    if (paymentMethodsSelected.find(p => p == method)) {
                        paymentMethodsSelected = paymentMethodsSelected.filter(p => p != method)
                    } else {
                        paymentMethodsSelected.push(method)
                    }

                    const sizeOf = paymentMethodsSelected.length
                    const text = `Métodos selecionados: ${sizeOf < 1 ? "Nenhum" : paymentMethodsSelected.join(", ")}.`
                    document.getElementsByClassName("swal2-input-label")[0].innerHTML = text
                })
            }
        })

        if (!paymentMethodsResult.isConfirmed) return

        const confirm = await AlertsService.confirmation({
            title: "Confirmar criação do plano",
            html: `
            <strong>Nome</strong>: ${planName}<br />
            <strong>Preço</strong>: ${formatPlanPrice(planPrice.toLocaleString("pt-br"))}<br />
            <strong>Descrição</strong>: ${planDescription}<br />
            <strong>Dias</strong>: ${planDays} dias <br />
            <strong>Recorrente</strong>: ${planRecurrent == 1 ? "Sim" : "Não"}<br />
            <strong>Métodos de pagamentos</strong>: ${paymentMethodsSelected.join(", ")}<br />
            `,
            confirmButtonText: "Criar plano",
            cancelButtonText: "Cancelar plano"
        })

        if (!confirm.isConfirmed) return;

        try {
            const request = await RequestsService.call({
                method: "POST",
                url: environment.URLS.PLANS.CREATE,
                data: {
                    name: planName,
                    price: planPrice.replace(",", "."),
                    description: planDescription,
                    days: planDays,
                    isRecurrent: planRecurrent == 1 ? true : false,
                    paymentMethods: paymentMethodsSelected.join(",")
                }
            })

    
            if (!request || request && !request.data) throw new Error("No response found")
            const response: any = request.data
            const success = response.success

            await searchPlans()

            return AlertsService.notification({ icon: success ? "success" : "error", title: response.message })
        } catch (err) {
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao criar o plano" })
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => searchPlans(), 0)
        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>
            <div className="configurations-plans">
                <div className="configurations-plans-content">
                    {/* Novo plano */}
                    <div className="configurations-plans-content-button">
                        <ButtonComponent
                            type="button"
                            text="Novo plano"
                            onClick={planCreate}
                        />
                    </div>
                </div>

                <TableComponent 
                    columns={columns} 
                    rows={rows} 
                    rowsPerPageOptions={[10, 25, 50]} 
                />
            </div>
        </DashboardCreateComponent>
    )
}

export default PlansComponent
import moment from "moment";
import { FilterProps } from "../../@types/filter.types";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";

import "./index.css"

const FilterComponent = (props: FilterProps) => {
    const buildIcon = () => {
        if (!props.hasIcon)
            return;

        let iconAlign = ""
        switch (props.iconAlign) {
            case "left":
                iconAlign = "component-filter-icon-left"
                break;
            case "right":
                iconAlign = "component-filter-icon-right"
                break;
        }

        return (
            <div className={ `component-filter-item-icon ${iconAlign}` }>
                <span>{props.icon}</span>
            </div>
        )
    }

    const buildType = () => {
        switch (props.type) {
            case "select": {
                return (
                    <select className="component-filter-select component-filter-style"
                        id={props.id}
                        name={props.name}
                        title={props.title}
                        onChange={props.onChange}
                        defaultValue={props.value}
                        style={props.style}
                    >
                        {props.childrens}
                    </select>
                )
            }
            case "input": {
                return (
                    <input className="component-filter-input component-filter-style"
                        name={props.name}
                        title={props.title}
                        id={props.id}
                        placeholder={props.placeholder}
                        defaultValue={props.value}
                        type="input"
                        onChange={props.onChange}
                        readOnly={props.readOnly}
                        style={props.style}
                    />
                )
            }
            case "text": {
                return (
                    <input className="component-filter-input component-filter-style"
                        name={props.name}
                        title={props.title}
                        id={props.id}
                        placeholder={props.placeholder}
                        type="text"
                        onChange={props.onChange}
                        readOnly={props.readOnly}
                        style={props.style}
                    />
                )
            }
            case "date": {
                const parseDateToString = () => {
                    const startDate = moment().startOf("month").toDate()
                    const endDate = moment().endOf("month").toDate()
                    return [props.dateShow?.startDate || startDate, props.dateShow?.endDate || endDate]
                }

                return (
                    <DateTimeRangePicker
                        onChange={props.dateChange}
                        value={parseDateToString()}
                        className="component-filter-style component-filter-date"
                        format="dd/MM/y"
                    />
                )
            }
        }
    }

    return (
        <div className="component-filter-item">
            {/* Build icon */}
            {props.iconAlign === "left" ? buildIcon() : ""}

            {/* Build type */}
            {buildType()}

            {/* Build icon */}
            {props.iconAlign === "right" ? buildIcon() : ""}
        </div>
    )
}

export default FilterComponent;
import axios, { AxiosRequestConfig } from 'axios';
import { RequestsProps } from '../@types/request.types';
import { environment } from '../common/environments';
import { StorageService } from './storage.service';
import { AlertsService } from './alerts.service';
import { useNavigate } from 'react-router-dom';

/**
 * @name call - Realiza uma requisição 
 * 
 * @param props 
 * 
 * @returns Promise
 */
const call = (props: RequestsProps): Promise<any> => {
    // Atribuíndo o token //
    const token = StorageService.getToken()
    if (token) {
        if (props.headers) {
            props.headers["authorization"] = token
        } else {
            props.headers = {
                "authorization": token
            }
        }
    }

    if (props.params) {
        if (props.params["token"]) {
            props.headers = {
                "authorization": props.params["token"]
            }

            props.params["token"] = undefined
        }
    }

    const abortController = new AbortController()
    const parameters = {
        baseURL: props.baseUrl || environment.SERVER.BASEURL,
        method: props.method,
        url: props.url,
        data: props.data,
        headers: props.headers,
        params: props.params,
        responseType: props.responseType || "json",
        signal: abortController.signal
    } as AxiosRequestConfig

    return new Promise((resolve, reject) => {

        const callAxios = () => axios(parameters).then(resolve).catch((err: any) => {
            const message = err.response?.data?.message
            if (message === "O token de acesso expirou. Logue novamente para poder ter acesso") {
                StorageService.removeToken()
                AlertsService.notification({
                    icon: "warning",
                    title: message,
                })

                // Enviando-o para a área de login //
                window.location.href = "/"
            }

            reject(err)
        })


        if (props.withTimeout && props.timeoutSeconds) {
            const timeout = setTimeout(() => {
                clearTimeout(timeout)
                callAxios()
            }, props.timeoutSeconds)
        } else {
            callAxios()
        }
    })
}

export const RequestsService = {
    call,
}
import { Modules, MyModules } from "../@types/modules.types"

/**
 * @name saveToken - Salva o token no Session Storage
 * 
 * @param token 
 * 
 * @returns Function
 */
 const saveToken = (token: any) => {
    return sessionStorage.setItem("session@token", token)
}

/**
 * @name saveSession - Salva as informações do usuário na Session Storage
 * 
 * @param data 
 * 
 * @returns Function
 */
const saveSession = (data: any) => {
    return sessionStorage.setItem("session@data", JSON.stringify(data))
}

/**
 * @name getToken - Obtém o token de acesso do usuário
 * 
 * @returns String
 */
const getToken = () => {
    return sessionStorage.getItem("session@token");
}

/**
 * @name getData - Obtém a data do usuário
 * 
 * @returns String
 */
const getData = () => {
    return sessionStorage.getItem("session@data") || "";
}

/**
 * @name hasData - Verifica se existe a data do usuário
 * 
 * @returns Boolean
 */
const hasData = () => {
    return sessionStorage.getItem("session@data") != null
}

/**
 * @name removeToken - Remove as credenciais expiradas 
 * 
 * @returns Boolean
 */
const removeToken = () => {
    sessionStorage.removeItem("session@data")
    sessionStorage.removeItem("session@token")
    return;
}

/**
 * @name saveMyModules - Salva os módulos do usuário
 */
const saveMyModules = (modules: MyModules[]) => {
    return sessionStorage.setItem("session@data:modules", JSON.stringify(modules))
}

/**
 * @name getMyModuleById - Obtém um módulo pelo id
 * 
 * @returns 
 */
const getMyModuleById = (moduleId: number) => {
    try {
        const parsedData = JSON.parse(sessionStorage.getItem("session@data:modules") || "")
        const modules = (parsedData || []) as MyModules[]
        return modules.find((myModule: MyModules) => myModule.id === moduleId)
    } catch (err: any) {
        return undefined
    } 
}

export const StorageService = {
    saveToken,
    saveSession,
    getToken,
    getData,
    hasData,
    removeToken,
    saveMyModules,
    getMyModuleById,
}
import "./index.css"

import { MenuPrivateProps, SubMenuItemProps } from "../../@types/component.types"
import { useEffect, useState } from "react"
import { Classes, ClassesFilter } from "../../@types/classes.types"
import { useRequestProvider } from "../../context/request.provider.context"
import { AlertsService } from "../../services/alerts.service"
import ButtonComponent from "../../component/button"
import TurmasFilter from "./filter"
import { getWeekNameById } from "../../common/utilitaries"
import { useNavigate } from "react-router-dom"
import { environment } from "../../common/environments"
import Swal from "sweetalert2"
import { RequestsProps } from "../../@types/request.types"
import { RequestsService } from "../../services/requests.service"
import { DefaultResponse } from "../../@types/response.types"
import DashboardCreateComponent from "../../component/dashboard/create"
import { DashboardCreateProps } from "../../@types/dashboard.component.types"
import { BiListUl } from "react-icons/bi"
import { modalClasse, turmaSubMenus } from "./props"

const Turmas = (props: MenuPrivateProps) => {

    const navigator = useNavigate()
    const { fetchClasses } = useRequestProvider()

    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: "Turmas",
        lateralMenu: "Turmas",
        subMenus: turmaSubMenus()
    }
    
    const [statics, setStatics] = useState(new Map<any, any>())
    const [isFetched, setFetched] = useState(false)
    const [isFetching, setFetching] = useState(false)
    const [classes, setClasses] = useState(new Array<Classes>())

    const updateStatic = (key: any, value: any) => setStatics(statics.set(key, value))
    const updateStatics = (...items: any[]) => items.map(item => updateStatic(item[0], item[1]))

    // Obtendo as classes
    const searchClasses = async (filter?: ClassesFilter) => {
        try {
            const isMobile = window.innerWidth < 720
            const response = await fetchClasses({
                ...filter,
                limit: !isMobile ? 20 : undefined
            })
            
            setFetched(true)
            setFetching(false)

            if (!response) return

            const isSearch = filter && filter.isSearch ? filter.isSearch : false
            const count = isSearch ? 0 : (statics.get("count") || 0)
            const data = response.data || []

            updateStatics(
                [ "page", response.page, ],
                [ "total", response.total, ],
                [ "totalPage", response.totalPage, ],
                [ "count", parseFloat(count + data.length) ]
            )

            setClasses(isSearch ? data : classes.concat(data))

            AlertsService.notification({ 
                icon: data.length < 1 ? "warning" : "success", 
                title: !filter?.isScroll ? data.length < 1 ? "A consulta não retornou resultados" : response.message : "Lista atualizada com sucesso."
            })
        } catch (e) {
            AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao buscar as classes." })
        }
    }

    /**
     * @name onScroll - Função que busca novas notícias ao chegar no final
     * 
     * @param e 
     */
    const onScroll = (e: any) => {
        e.preventDefault()
        if (statics.get("scrollLoading")) return

        const page = statics.get("page") || 1
        const totalPage = statics.get("totalPage") || 1

        const { target } = e;
        const { scrollHeight, scrollTop, clientHeight } = target;
        if (scrollHeight - scrollTop < (clientHeight + 300)) {
            const nextPage = Number(page + 1)
            if (nextPage <= totalPage) {
                updateStatic("scrollLoading", true)
                searchClasses({ isScroll: true, page: nextPage })
            }
        }
    }

    /**
     * @name showNumberResults - Mostra o resultado das turmas obtidas
     */
    const showNumberResults = () => {
        const total = statics.get("total") || 0
        const count = statics.get("count") || -1

        return (
            <div className="turmas-content-subtitle">
                <p>Mostrando { isFetching ? "..." : count === -1 ? 0 : count.toLocaleString("pt-BR") } de { !isFetched ? "..." : total.toLocaleString("pt-BR") } resultados</p>
            </div>
        )
    }

    const showClasses = () => {
        // Já foi buscado as turmas?
        if (!isFetched) return <p className="turmas-content-items-loading">Buscando turmas presenciais...</p>
        // Verificando se já foi buscado as turmas
        if (isFetched && classes.length < 1) {
            // Não há valores
            return (
                <p style={{ color: "white", textAlign: "center", margin: "2vh", fontSize: "1.2rem" }}>Não foram encontrados turmas cadastradas no sistema.</p>
            )
        }

        const formatDaysWeek = (classe: Classes) => {
            // Não existe dados
            if (!classe || (classe && !classe.daysOfWeek)) return <></>
            // Existe dados
            return (classe.daysOfWeek || "").split(",").map(week => (
                <span className="turmas-content-item-header-weekDay" key={week}>
                    { `${getWeekNameById(week)}` }
                </span>
            ))
        }

        return classes.map(classe => (
            <div className="turmas-content-item" key={classe.id}>
                <div className="turmas-content-item-header">
                    <div className="turmas-content-item-header-days">
                        { formatDaysWeek(classe) }
                    </div>

                    <span>
                        Horário: <span className="turmas-content-item-header-weekDay">{ classe.hours }</span>
                    </span>
                </div>

                <div className="turmas-content-item-title">
                    <p>{ classe.name }</p>
                </div>

                <div className="turmas-content-item-description">
                    <p>{ classe.description }</p>
                </div>

                <div className="turmas-content-item-footer">
                    <ButtonComponent
                        text="Ver turma"
                        type="button"
                        class="turmas-content-item-footer-button"
                        onClick={ () => navigator(`/classes/${classe.id}`, { replace: true }) }
                    />
                </div>
            </div>
        ))
    }

    const createClasses = async (event: any) => {
        event.preventDefault();
        
        const classeData = await modalClasse({
            nameTitle: "Nome da turma",
            nameHtml: "Informe o nome da turma a ser criado",
            descriptionTitle: "Descrição da  turma",
            descriptionHtml: "Digite a descrição da turma a ser criada",
            hourTitle: "Horário da turma",
            hourHtml: "Informe o horário da turma presencial",
            daysTitle: "Dias da semana",
            daysHtml: "Escolha os dias das semana no qual terá a aula da turma"
        })

        if (!classeData) return

        try {
            const requestObject: RequestsProps = {
                method: "POST",
                url: environment.URLS.CLASSES.CREATE,
                data: {
                    ...classeData
                }
            }
    
            const request = await RequestsService.call(requestObject)
            if (!request || (request && !request.data)) throw new Error("no response")
            const response: DefaultResponse = request.data

            setFetching(true)
            setFetched(false)
            setClasses([])

            await searchClasses()

            return AlertsService.notification({ icon: "success", title: response.message })
        } catch (err) {
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao criar a turma no banco de dados" })
        }
    }

    useEffect(() => {
        // Já foi buscado?
        if (isFetched) return
        // Timeout
        const timeout = setTimeout(async () => {
            // Verificando se já foi buscado as turmas
            if (isFetched) return
            // Verificando se está sendo buscado as turmas
            if (isFetching) return
            // Atualizando
            setFetching(true)
            // Buscando as turmas //
            await searchClasses()
        }, environment.SERVER.TIMEOUT.FETCH_CLASSES)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>
            <div className="turmas-content">
                <div className="turmas-content-header">
                    <div className="turmas-content-header-button">
                        <ButtonComponent
                            type="button"
                            text="Nova turma"
                            class="turmas-content-button"
                            onClick={createClasses}
                        />
                    </div>
                    
                    {/* Números */}
                    {showNumberResults()}

                    {/* Filtro */}
                    <TurmasFilter searchClasses={searchClasses} />
                </div>

                {/* Conteúdo */}
                <div className="turmas-content-items" onScroll={onScroll}>
                    {showClasses()}
                </div>
            </div>
        </DashboardCreateComponent>
    )
}

export default Turmas
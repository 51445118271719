import { useState } from "react";
import { ConfigurationsFilterComponent } from "../../../@types/configurations.types";
import FilterComponent from "../../../component/filter";
import ButtonComponent from "../../../component/button";
import { AlertsService } from "../../../services/alerts.service";

const ConfigurationsFilter = (props: ConfigurationsFilterComponent) => {

    const userStatus = [
        { id: undefined, name: "Status do usuário" },
        { id: "Ativo", name: "Ativo" },
        { id: "Inativo", name: "Inativo" },
        { id: "Todos", name: "Todos" },
    ]

    const [filtro, setFiltro] = useState(new Map<any, any>())
    const updateFilter = (key: any, value: any) => setFiltro(filtro.set(key, value))

    /**
     * @name changeFiltro - Modifica valores do filtro 
     * 
     * @param event 
     */
    const changeFilter = (event: any) => {
        // Removendo o evento default
        event.preventDefault()
        // Pegando o target
        const { target } = event
        // Pegando o id e o valor
        const { id, value } = target
        // Atualizando filtro
        updateFilter(id, value)
    }

    /**
     * @name searchFilter - Busca as turmas através do filtro
     */
    const searchFilter = (event: any) => {
        // Removendo o evento default
        event.preventDefault()
        // Verificando se há algo no filtro
        if (filtro.size < 1) return AlertsService.notification({ icon: "warning", title: "Não há um filtro escolhido para ser buscado" })
        // Chamando a função que busca as classes
        const jsonStringify = JSON.stringify(Object.fromEntries(filtro))
        // Chamando
        const parse = JSON.parse(jsonStringify)
        props.searchUsers({
            ...parse,
            isSearch: true
        })
    }

    /**
     * @name resetFilter - Reseta o filtro
     */
    const resetFilter = (event: any) => {
        // Removendo o evento default
        event.preventDefault()
        // Verificando se o filtro está com valores
        if (filtro.size < 1) return AlertsService.notification({ icon: "warning", title: "O filtro já está em seu padrão." })
        // Resetando filtro
        setFiltro(new Map<any, any>())
        // Imprimindo mensagem de sucesso
        AlertsService.notification({ icon: "success", title: "Filtro resetado com sucesso" })
    }

    return (
        <div className="configurations-user-content-filter">
            { /* Ativo ou desativado */ }
            <FilterComponent
                type="select"
                id="userStatus"
                name="userStatus"
                title="Status dos usuários"
                onChange={changeFilter}
                childrens={
                    userStatus.map((status, index) => (
                        <option key={index} value={status.id}>{status.name}</option>
                    ))
                }
            />

            {/* Buscar */}
            <ButtonComponent 
                text="Buscar"
                type="submit"
                class="configurations-user-content-filter-button"
                onClick={searchFilter}
            />

            {/* Resetar */}
            <ButtonComponent 
                text="Resetar"
                type="reset"
                class="configurations-user-content-filter-button configurations-user-content-filter-button-reset"
                onClick={resetFilter}
            />
        </div>
    )
}

export default ConfigurationsFilter;
import { useNavigate } from "react-router-dom"
import { MenuPrivateProps } from "../../@types/component.types"
import "./index.css"
import { useRequestProvider } from "../../context/request.provider.context"
import { useEffect, useState } from "react"
import { Users } from "../../@types/users.types"
import { ConfigurationsUsersFilter } from "../../@types/configurations.types"
import { AlertsService } from "../../services/alerts.service"
import ButtonComponent from "../../component/button"
import ConfigurationsFilter from "./filter"
import { environment } from "../../common/environments"
import { formatUserPhoto } from "../../common/utilitaries"
import { DashboardCreateProps } from "../../@types/dashboard.component.types"
import { configurationsSubMenus } from "./props"
import DashboardCreateComponent from "../../component/dashboard/create"

const Configuration = (props: MenuPrivateProps) => {

    const navigator = useNavigate()
    const { fetchUsers } = useRequestProvider()

    const [statics, setStatics] = useState(new Map<any, any>())
    const [isFetched, setFetched] = useState(false)
    const [isFetching, setFetching] = useState(false)
    const [users, setUsers] = useState(new Array<Users>())

    const updateStatic = (key: any, value: any) => setStatics(statics.set(key, value))
    const updateStatics = (...items: any[]) => items.map(item => updateStatic(item[0], item[1]))

    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: "Usuários",
        lateralMenu: "Configurações",
        subMenus: configurationsSubMenus()
    }

    /**
     * @name showNumberResults - Mostra o resultado dos usuários obtidos
     */
    const showNumberResults = () => {
        const total = statics.get("total") || 0
        const count = statics.get("count") || -1

        return (
            <div className="configurations-user-content-subtitle">
                <p>Mostrando { isFetching || count == -1 ? "..." : count.toLocaleString("pt-BR") } de { !isFetched ? "..." : total.toLocaleString("pt-BR") } resultados</p>
            </div>
        )
    }

    /**
     * @name searchUsers - Obtendo os usuários
     * 
     * @param filter 
     */
    const searchUsers = async (filter?: ConfigurationsUsersFilter) => {
        try {
            // Buscando
            const response = await fetchUsers({ ...filter })

            setFetched(true)
            setFetching(false)
            // Retornou algo?
            if (!response) return

            const isSearch = filter && filter.isSearch ? filter.isSearch : false
            const count = isSearch ? 0 : (statics.get("count") || 0)
            const isArray = response.data instanceof Array
            const data = isArray ? response.data as Users[] : [response.data as Users]

            updateStatics(
                ["page", response.page],
                ["total", response.total],
                ["totalPage", response.totalPage],
                ["count", parseFloat(count + data.length)]
            )
            // Atualizando o count //
            // Atualizando as turmas //
            setUsers(isSearch ? data : users.concat(data))
            // Notificação de sucesso
            AlertsService.notification({ 
                icon: data.length < 1 ? "warning" : "success", 
                title: !filter?.isScroll ? data.length < 1 ? "A consulta não retornou resultados" : response.message : "Lista atualizada com sucesso."
            })
        } catch (e) {
            AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao buscar os usuários." })
        }
    }

    /**
     * @name showUsers - Mostrando usuários
     */
    const showUsers = () => {
        // Já foi buscado os usuários?
        if (!isFetched) return <p className="turmas-content-items-loading">Buscando usuários...</p>
        // Verificando se já foi buscado os usuários
        if (isFetched && users.length < 1) {
            // Não há valores
            return (
                <h1>Não há valores!</h1>
            )
        }

        const showInfo = (user: Users, type: "GROUPS" | "MODULES" | "CLASSES") => {
            switch (type) {

                case "GROUPS": {
                    return <p><strong>Grupos: </strong>{
                        user.groups.length < 1 ? "Nenhum grupo atribuído" : `${user.groups.map(group => group.name).join(", ")}.`
                    }</p>
                }

                case "MODULES": {
                    return <p><strong>Módulos: </strong>{
                        user.modules.length < 1 ? "Nenhum módulo atribuído." : `${user.modules.map(module => module.name).join(", ")}.`
                    }</p>
                }

                case "CLASSES": {
                    return <p><strong>Turmas: </strong>{
                        user.classes.length < 1 ? "Nenhuma classe atribuída." : `${user.classes.map(classe => classe.name).join(", ")}.`
                    }</p>
                }

            }
        }

        return users.map(user => (
            <div className="configurations-user-content-item" key={user.id}>
                <div className="configurations-user-content-name">
                    <img src={ formatUserPhoto(user) } alt="new" />
                    <p>{user.name}</p>
                </div>

                <div className="configurations-user-content-infos">
                    <p><strong>E-mail: </strong>{ user.email }</p>

                    {/* { showInfo(user, "GROUPS") } */}
                    {/* { showInfo(user, "MODULES") } */}
                    { showInfo(user, "CLASSES") }
                </div>

                <ButtonComponent 
                    text="Mais informações" 
                    type="button" 
                    class="configurations-user-content-action"
                    onClick={() => 
                        navigator(`${environment.ROUTES.CONFIGURATIONS.USER.LIST}/profile/${user.id}`, { replace: true })
                    }
                />
            </div>
        ))
    }

    useEffect(() => {
        // Já foi buscado?
        if (isFetched) return
        // Timeout
        const timeout = setTimeout(async () => {
            // Verificando se já foi buscado os usuários
            if (isFetched) return
            // Verificando se está sendo buscado os usuários
            if (isFetching) return
            // Atualizando
            setFetching(true)
            // Buscando os usuários //
            await searchUsers()
        }, environment.SERVER.TIMEOUT.FETCH_USERS)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>
            <div className="configurations-user-content">
                {/* Header */}
                <div className="configurations-user-content-header">
                    {/* Button */}
                    <div className="configurations-user-content-button">
                        <ButtonComponent
                            type="button"
                            text="Novo usuário"
                            onClick={() => navigator(`${environment.ROUTES.CONFIGURATIONS.USER.CREATE}`, { replace: true })}
                        />
                    </div>
                    
                    {/* Números */}
                    {showNumberResults()}

                    {/* Filtro */}
                    <ConfigurationsFilter searchUsers={searchUsers} />
                </div>

                { /* Conteúdo */ }
                <div className="configurations-user-content-items">
                    { showUsers() }
                </div>
            </div>
        </DashboardCreateComponent>
    )

}

export default Configuration
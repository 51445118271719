import { useState } from "react"
import InputComponent from "../../component/input"
import logo from '../../files/images/Logo preto.png'
import ButtonComponent from "../../component/button"
import { RequestsService } from "../../services/requests.service"
import { environment } from "../../common/environments"
import { AlertsService } from "../../services/alerts.service"
import { DefaultResponse } from "../../@types/response.types"
import { useNavigate } from "react-router-dom"

const PasswordRecoveryComponent = () => {

    const navigate = useNavigate()
    const [data, setData] = useState<Map<any, any>>(new Map<any, any>)
    const [waitingCode, setWaitingCode] = useState(false)

    async function handleChange(event: any) {
        const { target } = event
        const { name, value } = target
        setData(data.set(name, value))
    }

    async function handleSubmit(event: any) {
        event.preventDefault()

        try {
            // Esperando código
            if (waitingCode) {
                const code = data.get("code")
                if (!code) {
                    return AlertsService.notification({ icon: "error", title: "É necessário informar o código de redefinição de senha." })
                }

                const password = data.get("password")
                if (!password) {
                    return AlertsService.notification({ icon: "error", title: "É necessário informar sua nova senha." })
                }
                
                const request = await RequestsService.call({
                    method: "POST",
                    url: environment.URLS.USER.PASSWORD_RECOVERY,
                    data: {
                        passId: code,
                        password
                    }
                })

                if (!request || request && !request.data) throw new Error("No response found")
                const response: DefaultResponse = request.data

                if (response.success) {
                    setTimeout(() => navigate("/", { replace: true }), 1500)
                }

                return AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })
            }

            const email = data.get("email")
            if (!email) {
                return AlertsService.notification({ icon: "error", title: "É necessário informar seu e-mail para recuperar a senha." })
            }

            const request = await RequestsService.call({
                method: "POST",
                url: environment.URLS.USER.GENERATE_PASSWORD_RECOVERY,
                data: { email }
            })

            if (!request || request && !request.data) throw new Error("No response found")
            const response: DefaultResponse = request.data

            if (response.success) {
                setTimeout(() => setWaitingCode(true), 1000)
            }

            return AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })
        } catch (err: any) {
            let message = err.message || "Ocorreu um erro ao tentar recuperar sua senha"
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }

            return AlertsService.notification({
                icon: "error",
                title: message 
            })
        }
    }

    return (
        <div className="login-container login-align-itens">
            <div className="login-box">
                {/* Header */}
                <div className="login-box-header">
                    <img src={logo} alt="logo"></img>
                </div>

                {/* Content */}
                <div className="login-box-content">
                    <form onSubmit={handleSubmit}>
                        {/* Text */}
                        <h2 style={{ marginTop: "1.5vh" }}>Olá! Bem-vindo</h2>
                        <p>
                            {!waitingCode ? 
                                "Informe o seu e-mail para que seja enviado um código de recuperação de senha" :
                                "Preencha os campos corretamente para redefinir sua senha."
                            }
                        </p>

                        {/* E-mail input */}
                        {
                            waitingCode ? <></> :
                            <InputComponent
                                name="email"
                                placeholder="E-mail"
                                type="email"
                                onChange={handleChange}
                            />
                        }

                        {/* Code */}
                        {
                            !waitingCode ? <></> :
                            <InputComponent
                                name="code"
                                placeholder="Código de redefinição de senha"
                                type="text"
                                autoComplete={false}
                                onChange={handleChange}
                                style={{
                                    marginBottom: "2vh"
                                }}
                            />
                        }

                        {/* Password */}
                        {
                            !waitingCode ? <></> :
                            <InputComponent
                                name="password"
                                placeholder="Sua nova senha"
                                type="password"
                                onChange={handleChange}
                            />
                        }

                        {/* Button send */}
                        <ButtonComponent
                            text={ waitingCode ? "Atualizar senha" : "Recuperar senha" }
                            type="submit"
                            style={{
                                marginTop: "2.6vh",
                                marginBottom: "1vh",
                                backgroundColor: "#2a79bd",
                                padding: "1vh",
                                borderRadius: "10px",
                                fontSize: "1rem"
                            }}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PasswordRecoveryComponent
import Swal from "sweetalert2";
import { NotificationsInterface, SubMenuItemProps, TableColumns } from "../../@types/component.types";
import BlockComponent from "../../component/block";
import ButtonComponent from "../../component/button";
import { AlertsService } from "../../services/alerts.service";
import "./index.css"
import { RequestsService } from "../../services/requests.service";
import { environment } from "../../common/environments";
import { Users } from "../../@types/users.types";
import { useEffect, useState } from "react";
import { NotificationsListResponse, NotificationsStatusResponse } from "../../@types/notifications.types";
import { FaWhatsapp } from "react-icons/fa";
import { formatPhoneNumber } from "../../common/utilitaries";
import moment from "moment";
import TableComponent from "../../component/table";
import { BiListUl } from "react-icons/bi";
import DashboardCreateComponent from "../../component/dashboard/create";
import { DashboardCreateProps } from "../../@types/dashboard.component.types";

const NotificationsComponent = (props: NotificationsInterface) => {

    // QRCode
    const [isConnected, setConnected] = useState(false)
    const [isConnecting, setConnecting] = useState(false)

    const [qrCode, setQrCode] = useState("")
    const [isShowQrcode, setShowQrCode] = useState(false)
    const [notificationsFetched, setNotificationsFetched] = useState(false)

    const [rows, setRows] = useState([] as any[])

    const columns: TableColumns[] = [
        { id: "id", label: "Id", format: (value: any) => `#${value}` },
        { id: "content", label: "Conteúdo" },
        { id: "phone", label: "Telefone", format: (value: any) => formatPhoneNumber(value) },
        { id: "created_at", label: "Data", format: (value: any) => moment(value).format("DD/MM/YYYY") },
    ] 

    const isMobile = window.innerWidth < 720
    const { ROUTES } = environment
    const subMenus: SubMenuItemProps[] = [
        { name: "Notificações", redirectTo: ROUTES.NOTIFICATIONS, hasIcon: true, icon: <BiListUl /> },
    ]

    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        menuActiveName: "Notificações",
        lateralMenu: "Notificações",
        user: props.user,
        subMenus
    }

    const createNotification = async (event?: any): Promise<any> => {
        if (event) event.preventDefault()

        try {
            const object: { 
                user_id?: number
                user_name?: string
                phone?: string
                message?: string
            } = {}
    
            const typeResult = await AlertsService.confirmation({
                title: "Selecione o tipo para ser enviado a mensagem",
                html: "Selecione entre buscar por usuário ou envio pelo número de WhatsApp",
                input: "radio",
                inputOptions: {
                    "byUser": "Usuário",
                    "byPhone": "Número WhatsApp"
                },
                inputValidator: value => {
                    if (!value) {
                        return "Você precisa escolher uma opção."
                    }
                },
                confirmButtonText: "Próximo passo",
                cancelButtonText: "Cancelar"
            })
    
            if (!typeResult.isConfirmed) return;
            const value = typeResult.value
    
            if (value === "byUser") {
                const userResult = await AlertsService.confirmation({
                    title: `Informe o nome do usuário`,
                    icon: "question",
                    input: "text",
                    showLoaderOnConfirm: true,
                    confirmButtonText: "Buscar",
                    cancelButtonText: "Cancelar",
                    preConfirm: async (name) => {
                        try {
                            const response = await RequestsService.call({
                                method: "GET",
                                url: environment.URLS.USER.LIST,
                                params: {
                                    name
                                }
                            })
    
                            if (!response || response && !response.data) throw new Error("Não foi possível buscar os usuários")
                            return response.data.success ? response.data.data : undefined
                        } catch (err) {
                            Swal.showValidationMessage(`Ocorreu um erro: ${err}`)
                        }
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                })
    
                if (!userResult.isConfirmed || !userResult.value) return
                // Não retornou nada
                if (userResult.value && userResult.value.length < 1) {
                    const resultBack = await AlertsService.confirmation({
                        title: `A busca não retornou resultados.`,
                        icon: "error",
                        confirmButtonText: "Voltar",
                        cancelButtonText: "Cancelar busca",
                    })
    
                    if (!resultBack.isConfirmed) return
                    return await createNotification()
                }
    
                const inputOptions: any = {}
                userResult.value.forEach((user: Users) => {
                    if (user) inputOptions[user.id] = user.name
                })
    
                const result = await AlertsService.confirmation({
                    title: "Selecione um usuário",
                    input: "select",
                    inputOptions,
                    confirmButtonText: "Selecionar",
                    cancelButtonText: "Cancelar operação"
                })
    
                if (!result.isConfirmed || !result.value) return;
    
                object.user_id = parseInt(result.value)
                object.user_name = inputOptions[result.value]
            }
    
            if (value === "byPhone") {
                const phoneResult = await AlertsService.confirmation({
                    title: `Informe o número de WhatsApp`,
                    html: "Digite o número de WhatsApp para ser enviado o alerta (11 dígitos totais). <strong>Apenas dígitos!</strong>",
                    input: "text",
                    inputPlaceholder: "92999999999",
                    confirmButtonText: "Próximo passo",
                })
    
                if (!phoneResult.isConfirmed || !phoneResult.value) return
                const phone: any = phoneResult.value
    
                try {
                    if (phone.toString().length !== 11) throw new Error("Phone is not correct")
                    Number(phone.toString())
                    object.phone = phone.toString()
                } catch (err) {
                    const resultBack = await AlertsService.confirmation({
                        title: `O número informado está incorreto!`,
                        html: "Formato: 92999999999, 11 dígitos totais. <strong>Apenas dígitos!</strong>",
                        icon: "error",
                        confirmButtonText: "Voltar",
                        cancelButtonText: "Cancelar",
                    })
    
                    if (!resultBack.isConfirmed) return
                    return await createNotification()
                }
            }
    
            const messageResult = await AlertsService.confirmation({
                input: "textarea",
                title: "Digite a mensagem",
                html: "Digite a mensagem a ser enviada para o número/usuário",
                confirmButtonText: "Próximo passo",
                cancelButtonText: "Cancelar"
            })
    
            if (!messageResult.isConfirmed) return;
            object.message = messageResult.value
    
            const result = await AlertsService.confirmation({
                title: "Confirme os dados",
                html: `
                Verifique se os dados informados estão corretos. <br> <br>
                ${ object.user_id !== undefined ? `Aluno: ${object.user_name}` : `Número: ${object.phone}` } <br>
                Mensagem: ${object.message}
                `
            })
    
            if (!result.isConfirmed) return;
    
            const request = await RequestsService.call({
                method: "POST",
                url: environment.URLS.NOTIFICATIONS.SEND_MESSAGE,
                data: {
                    ...object
                }
            })
    
            if (!request || !request.data) throw new Error("No response found")
            const response: any = request.data
            return AlertsService.notification({ icon: "success", title: response.message })
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || "Ocorreu um erro ao criar a notificação" })
        }
    }

    const searchNotifications = async () => {
        try {
            const request = await RequestsService.call({
                method: "GET",
                url: environment.URLS.NOTIFICATIONS.LIST
            })

            if (!request || !request.data) throw new Error("No response found")
            const response: NotificationsListResponse = request.data
            if (!response.success) throw new Error(response.message)

            const _rows = (response.data || []).map((data) => {
                return {
                    id: data.id,
                    content: data.content,
                    phone: data.user ? data.user.phone : data.phone,
                    created_at: data.created_at
                }
            })

            setRows(_rows)
            setNotificationsFetched(true)
        } catch (err) {
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao buscar o histórico de notificações" })
        }
    }

    const checkStatusAPI = async () => {
        try {
            const request = await RequestsService.call({
                method: "GET",
                url: environment.URLS.NOTIFICATIONS.SESSION_CONNECTED
            })

            if (!request || !request.data) throw new Error("No response found")
            const response: NotificationsStatusResponse = request?.data
        
            if (response.isConnected) {
                setConnecting(false)
                setConnected(true)
            } else if (!response.isConnected) {
                setConnected(false)
            }

            AlertsService.notification({ icon: !response.isConnected ? "warning" : "success", title: response.message })
            if (response.isConnecting) {
                setConnected(false)
                setConnecting(true)
                setQrCode(response.data || "")
                setShowQrCode(true)
                getQrCode()
            }
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || "Ocorreu um erro ao buscar o status do WhatsApp API" })
        }
    }

    const initializeConnection = async (e: any) => {
        e.preventDefault()
        // Verificando se já está em processo de conexão
        if (isConnecting) {
            return AlertsService.notification({ icon: "info", title: "A conexão já está em andamento. Por favor, aguarde." })
        }

        setConnecting(true)

        try {
            const request = await RequestsService.call({
                method: "POST",
                url: environment.URLS.NOTIFICATIONS.SESSION
            })

            if (!request || !request.data) throw new Error("No response found")
            const response: NotificationsStatusResponse = request.data

            setQrCode(response.data || "")
            setShowQrCode(true)
            getQrCode()

            return AlertsService.notification({ icon: "success", title: response.message })
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || "Ocorreu um erro em inicializar a conexão com o WhastApp API." })
        }
    }

    const getQrCode = () => {
        const get = setInterval(async () => {
            try {
                const request = await RequestsService.call({
                    method: "GET",
                    url: environment.URLS.NOTIFICATIONS.SESSION_QRCODE,
                })

                if (!request || !request.data) throw new Error("No response found")
                const response: NotificationsStatusResponse = request.data

                setQrCode(response.data || "")
                setShowQrCode(true)
            } catch (err: any) {
                clearInterval(get)

                setQrCode("")
                setShowQrCode(false)
                setConnecting(false)
                checkStatusAPI()
            }
        }, environment.SERVER.TIMEOUT.FETCH_QR_CODE)
    }

    const showQrCode = () => {
        if (qrCode === "") return <></>
        return (
            <div className="notifications-qrcode">
                <img alt="QRCode" src={ qrCode } />
            </div>
        )
    }

    const showActions = () => {

        const showButtonInitialize = () => {
            if (isConnected || (isShowQrcode && qrCode === "") || isConnecting) return <></>

            return (
                <ButtonComponent
                    text="Iniciar conexão"
                    type="button"
                    onClick={initializeConnection}
                />
            )
        }

        const showUpdateStatus = () => {
            if (!isConnected) return <></>

            return (
                <div className="notifications-actions-list">
                    <ButtonComponent
                        text="Status da API"
                        type="button"
                        style={{
                            background: "aqua",
                            color: "black"
                        }}
                        onClick={checkStatusAPI}
                    />

                    <ButtonComponent
                        text="Criar notificação"
                        type="button"
                        onClick={createNotification}
                    />
                </div>
            )
        }

        return (
            <div className="notifications-actions">
                { showButtonInitialize() }
                { showUpdateStatus() }
            </div>
        )
    }

    useEffect(() => {
        const timeout = setTimeout(async () => {
            // Obtendo o histórico de notificações
            if (!notificationsFetched) await searchNotifications()
            // Obtendo o status da API
            if(!isConnected) await checkStatusAPI()
        })

        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>

            <div className="notifications-graphs">
                {/* Status da API */}
                <BlockComponent title="Status da API" legend="Verifique o status da API do WhatsApp" className="notifications-graphs-status">
                    <div className="notifications-status">
                        <div className="notifications-status-api">
                            <FaWhatsapp />
                            <p>WhatsApp API: </p>
                            {
                                isConnected ? <span className="text-green">Conectado</span> :
                                isConnecting ? <span>Inicializando...</span> :
                                <span className="text-red">Desconectado</span>
                            }
                        </div>

                        { showQrCode() }
                        { showActions() }
                    </div>
                </BlockComponent>

                {/* Histórico de mensagens */}
                <BlockComponent title="Histórico de notificações" legend="Histórico de notificações nos últimos 3 meses" className="notifications-graphs-historic">
                    <TableComponent
                        columns={columns}
                        rows={rows}
                        rowsPerPage={25}
                        rowsPerPageOptions={[25, 50, 100]}
                        maxHeight={ !isMobile ? 650 : undefined }
                    />
                </BlockComponent>
            </div>

        </DashboardCreateComponent>
    )
}

export default NotificationsComponent;
import React, { useState } from "react";
import { BiUser, BiLockAlt } from 'react-icons/bi'
import { useNavigate } from "react-router-dom";
import {environment} from '../../common/environments'

import "./page.css"

// Contexts //
import { useAuth } from '../../context/auth.context'

// Files //
import logo from '../../files/images/Logo preto.png'

// Components //
import InputComponent from "../../component/input";
import ButtonComponent from "../../component/button";

// Services //
import { AlertsService } from "../../services/alerts.service";

// Types //
import { LoginData } from "../../@types/login.types";

const Login: React.FC = () => {

    const navigate = useNavigate()
    const { signIn } = useAuth()
    const [isLogging, setLogging] = useState(false)
    const [data, setData] = React.useState<LoginData>({
        email: '',
        password: '',
        isPermanent: false
    });

    function handleChange(event: any) {
        const { target } = event
        const { name, value } = target

        switch (name) {
            case "password": {
                return setData({ email: data.email, password: value, isPermanent: data.isPermanent })
            }
            case "email": {
                return setData({ email: value, password: data.password, isPermanent: data.isPermanent })
            }
            case "isPermanent": {
                return setData({ email: data.email, password: data.password, isPermanent: !data.isPermanent })
            }
        }
    }

    async function handleSubmit(event: any) {
        event.preventDefault()
        if (isLogging) return;

        // Set logging //
        setLogging(true)
        // Response //
        const response = await signIn(data).catch((err: any) => {
            setLogging(false)

            let message = "Ocorreu um erro ao fazer o login no sistema."
            if (err && err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }

            AlertsService.notification({ icon: 'error', title: message, timer: 2500 })
        })
        
        // Ocorreu um erro //
        if (!response || response && response.error) return setLogging(false)
        // Enviando para o dashboard
        setTimeout(() => navigate("/dashboard"), 2500)
    }

    return (
        <div className="login-container login-align-itens">
            <div className="login-box">
                {/* Header */}
                <div className="login-box-header">
                    <img src={logo} alt="logo"></img>
                </div>

                {/* Content */}
                <div className="login-box-content">
                    <h2>Bem-vindo à Tocatambor</h2>

                    <div className="login-box-content-section" style={{ marginTop: "2vh" }}>
                        <h3>Não possui uma conta?</h3>

                        <ButtonComponent
                            text="CADASTRE-SE COM SEU E-MAIL"
                            type="button"
                            style={{
                                marginTop: "1vh",
                                marginBottom: "1vh",
                                backgroundColor: "#cfb10a",
                                padding: "1vh",
                                borderRadius: "10px",
                                fontSize: "1rem",
                                color: "black",
                                fontWeight: "bold"
                            }}
                            onClick={() => navigate("/register", { replace: true })}
                        />
                    </div>

                    <div className="login-box-content-division">
                        <div className="login-box-content-line" />
                        <p>OU</p>
                        <div className="login-box-content-line" />
                    </div>

                    <div className="login-box-content-section">
                        <h3>Possui uma conta?</h3>

                        <form onSubmit={handleSubmit}>
                            {/* E-mail input */}
                            <InputComponent
                                name="email"
                                placeholder="E-mail"
                                type="email"
                                icon={<BiUser key={"user-icon"} />}
                                hasIcon={true}
                                onChange={handleChange}
                            />

                            {/* Password input */}
                            <InputComponent
                                name="password"
                                placeholder="Senha"
                                type="password"
                                icon={<BiLockAlt key={"password-icon"} />}
                                hasIcon={true}
                                onChange={handleChange}
                            />

                            {/* Always Logged input */}
                            <InputComponent
                                name="isPermanent"
                                type="checkBox"
                                onChange={handleChange}
                                style={{
                                    width: "none",
                                    paddingTop: "15px",
                                    marginTop: "12px",
                                    marginLeft: "5px"
                                }}
                                hasLabel={true}
                                labelDirection="right"
                                label="Manter-se conectado"
                            />

                            {/* Button send */}
                            <ButtonComponent
                                text="Entrar"
                                type="submit"
                                style={{
                                    marginTop: "1.5vh",
                                    marginBottom: "1vh",
                                    backgroundColor: "rgb(42, 121, 189)",
                                    padding: "1vh",
                                    borderRadius: "10px",
                                    fontSize: "1rem"
                                }}
                            />
                        </form>
                    </div>
                </div>

                <div className="login-box-actions">
                    <a target="_blank" rel="noreferrer noopener" href="/password-recovery">Esqueci minha senha</a>
                </div>

                <div className="login-box-footer">
                    <p>Powered by <a href="https://tocatambor.com.br/" target="_blank" rel="Tocatambor noreferrer">TocaTambor</a></p>
                    <p>Version: {environment.VERSION}</p>
                </div>
            </div>
        </div>
    )
}

export default Login;
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { LoginRedirectRoute } from './redirect/login.redirect.route';
import { PrivateRoute } from './private.route';

// Common //
import { environment } from '../common/environments';

// Services //
import { RequestsService } from '../services/requests.service';
import { AlertsService } from '../services/alerts.service';

// Context //
import { useAuth } from '../context/auth.context';

// Pages //
import Login from '../pages/login';
import Dashboard from '../pages/dashboard';
import { Groups } from '../@types/groups.types';
import Turmas from '../pages/turmas';
import { RequestProvider } from '../context/request.provider.context';
import TurmasInfo from '../pages/turmas/info';
import Configuration from '../pages/configurations';
import ConfigurationsUserInfo from '../pages/configurations/user';
import UserUpdateComponent from '../pages/configurations/user/update';
import UserCreateComponent from '../pages/configurations/user/create';
import { Users } from '../@types/users.types';
import NotificationsComponent from '../pages/notifications';
import PlansComponent from '../pages/configurations/plans';
import ModulesComponent from '../pages/modules';
import ModulesInfoComponent from '../pages/modules/info';
import PasswordRecoveryComponent from '../pages/password-recovery';
import RegisterComponent from '../pages/register';
import MyModulesComponent from '../pages/my-modules';
import { Invoices } from '../@types/invoices.types';
import moment from 'moment';
import MyModulesInfoComponent from '../pages/my-modules/info';

const RoutesList = () => {

    const navigator = useNavigate()
    const { isLogged, removeLoggedAfterTokenExpired, } = useAuth()

    const [groupsList, setGroups] = useState([] as Groups[]);
    const [user, setUser] = useState({} as Users)

    const requestUser = async () => {
        try {
            const response = await RequestsService.call({
                method: "GET",
                url: environment.URLS.USER.INFO,
                params: {
                    listOfInvoices: true
                }
            })

            if (!response || (response && !response.data)) throw new Error("No response found")
            const user: Users = response.data.data
            // Colocando os grupos
            setGroups(user.groups)
            // Setando o user
            setUser(user)

            // Verificando se há faturas expiradas ou em aberto
            const invoices: Invoices[] = user.invoices
            const actually = invoices
                .filter((invoice: Invoices) => !invoice.paid)
                .filter((invoice: Invoices) => invoice.status !== environment.INVOICES.STATUS.WAITING_GENERATION)

            if (actually.length > 0) {
                const invoice = actually[0]
                const dueDate = moment(invoice.expire_at || "01/01/2000")
                const date1 = new Date(dueDate.toDate()).getTime()
                const date2 = new Date(moment().format("MM/DD/YYYY")).getTime()
                const overdueInvoice = date2 > date1

                const title = overdueInvoice ? "Fatura vencida" : "Fatura a vencer"
                const description = overdueInvoice ? 
                    "Você possui uma fatura vencida no sistema. Contate-nos através de nossos <strong>canais de comunicação</strong> e fique em dia para ter acesso ao nosso conteúdo e aulas presenciais" : 
                    "Você possui uma fatura a vencer no sistema. Fique em dia conosco e não fique sem nosso conteúdo!"

                AlertsService.confirmation({
                    title,
                    html: description,
                    showCancelButton: false,
                    showConfirmButton: false,
                    showCloseButton: overdueInvoice ? false : true,
                    allowOutsideClick: overdueInvoice ? false : true
                })
            }
        } catch (err: any) {
            let message = ""
            if (err.response && err.response.data) {
                const { message: responseMessage } = err.response.data
                // Removendo o token e a data //
                removeLoggedAfterTokenExpired()

                // Message //
                message = responseMessage
            }

            AlertsService.notification({
                icon: "info",
                title: message || "Não foi possível efetuar seu login no sistema. Tente novamente mais tarde!"
            })

            // Enviando-o para a área de login //
            navigator("/", { replace: true })
        }
    }

    // Verifica os grupos do usuário //
    useEffect(() => {
        if (!isLogged()) return

        const requestGroups = setTimeout(async () => {
            // Verificando se o usuário está logado //            
            if (!isLogged()) return
            await requestUser()
        });

        return () => clearTimeout(requestGroups)
    }, [isLogged()])

    return (
        <Routes>
            <Route path="/" element={(
                <LoginRedirectRoute route={environment.ROUTES.DASHBOARD}>
                    <Login />
                </LoginRedirectRoute>
            )} />

            <Route path="/password-recovery" element={(
                <LoginRedirectRoute route={environment.ROUTES.DASHBOARD}>
                    <PasswordRecoveryComponent />
                </LoginRedirectRoute>
            )} />

            <Route path="/register" element={(
                <LoginRedirectRoute route={environment.ROUTES.DASHBOARD}>
                    <RegisterComponent />
                </LoginRedirectRoute>
            )} />

            <Route path={environment.ROUTES.DASHBOARD} element={(
                <PrivateRoute>
                    <RequestProvider>
                        <Dashboard groups={groupsList} user={user} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.MY_PROFILE} element={(
                <PrivateRoute>
                    <RequestProvider>
                        <ConfigurationsUserInfo groups={groupsList} user={user} isProfile={true} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.MY_PROFILE + "/update"} element={(
                <PrivateRoute>
                    <RequestProvider>
                        <UserUpdateComponent groups={groupsList} isProfile={true} user={user} updateUser={setUser} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.MODULES.MY_MODULES} element={(
                <PrivateRoute>
                    <RequestProvider>
                        <MyModulesComponent groups={groupsList} user={user} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.MODULES.MY_MODULES_BY_ID} element={(
                <PrivateRoute>
                    <RequestProvider>
                        <MyModulesInfoComponent groups={groupsList} user={user} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.TURMAS.HOME} element={(
                <PrivateRoute isAdmin={true} groups={groupsList}>
                    <RequestProvider>
                        <Turmas groups={groupsList} user={user} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.TURMAS.BY_ID} element={(
                <PrivateRoute isAdmin={true} groups={groupsList}>
                    <RequestProvider>
                        <TurmasInfo groups={groupsList} user={user}/>
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.CONFIGURATIONS.USER.LIST} element={(
                <PrivateRoute isAdmin={true} groups={groupsList}>
                    <RequestProvider>
                        <Configuration groups={groupsList} user={user} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.CONFIGURATIONS.PLANS.LIST} element={(
                <PrivateRoute isAdmin={true} groups={groupsList}>
                    <RequestProvider>
                        <PlansComponent groups={groupsList} user={user} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.CONFIGURATIONS.USER.BY_ID} element={(
                <PrivateRoute isAdmin={true} groups={groupsList}>
                    <RequestProvider>
                        <ConfigurationsUserInfo groups={groupsList} user={user} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.CONFIGURATIONS.USER.UPDATE_ADMIN} element={(
                <PrivateRoute isAdmin={true} groups={groupsList}>
                    <RequestProvider>
                        <UserUpdateComponent groups={groupsList} isProfile={false} user={user} updateUser={setUser} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.CONFIGURATIONS.USER.CREATE} element={(
                <PrivateRoute isAdmin={true} groups={groupsList}>
                    <RequestProvider>
                        <UserCreateComponent groups={groupsList} isAdmin={true} user={user} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.NOTIFICATIONS} element={(
                <PrivateRoute isAdmin={true} groups={groupsList}>
                    <RequestProvider>
                        <NotificationsComponent groups={groupsList} user={user} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.MODULES.HOME} element={(
                <PrivateRoute isAdmin={true} groups={groupsList}>
                    <RequestProvider>
                        <ModulesComponent groups={groupsList} user={user} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.MODULES.BY_ID} element={(
                <PrivateRoute isAdmin={true} groups={groupsList}>
                    <RequestProvider>
                        <ModulesInfoComponent groups={groupsList} user={user} />
                    </RequestProvider>
                </PrivateRoute>
            )} />

            <Route path='*' element={<Navigate to={environment.ROUTES.DASHBOARD} />} />
        </Routes>
    );
}

export default RoutesList

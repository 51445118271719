import moment from "moment";
import { Users } from "../@types/users.types";
import { SubItemMenuComponentProps } from "../@types/menu.component.types";
import { environment } from "./environments";
import { BiBell, BiCog, BiHome, BiUser, BiUserCheck } from 'react-icons/bi';
import { SiGoogleclassroom } from "react-icons/si";
import SubItemComponent from "../component/menu/subitem";
import { AlertsService } from "../services/alerts.service";
import { SubMenuItemProps } from "../@types/component.types";
import SubMenuItemComponent from "../component/menu/submenu";
import * as CPF from "gerador-validador-cpf"
import { Invoices } from "../@types/invoices.types";
import { Groups } from "../@types/groups.types";

interface ConvertMsToTimeInterface {
    isSeconds?: boolean;
    isMinutes?: boolean;
    isHours?: boolean;
}

export const formatPaymentMethods = (paymentMethods: string, showMethods?: boolean) => {
    const methods: any = {
        "PIX": "Pix",
        "CREDIT_CARD": "Cartão de Crédito",
        "DEBIT_CARD": "Cartão de Débito",
        "BOLETO": "Boleto Bancário",
    }
    
    let listOfMethods: string[] = []
    // É para mostrar no registro
    if (showMethods) {
        if (!paymentMethods.includes(",")) {
            return Object.values(methods).join(",") + "."
        }
        
        listOfMethods = paymentMethods.split(",").map((method: any) => methods[method])
    }

    return listOfMethods.join(", ") + "."
}

export const logoutUser = (removeLoggedAfterTokenExpired: Function, navigate: Function): (event?: any) => void => {
    return async (event?: any) => {
        if (event) event.preventDefault()

        const result = await AlertsService.confirmation({
            title: "Você deseja realmente sair?",
            icon: "question",
            confirmButtonText: "Sim, sair.",
            cancelButtonText: "Não."
        })
    
        if (!result.isConfirmed) return
        
        removeLoggedAfterTokenExpired()
    
        AlertsService.notification({ icon: "success", title: "Você saiu com sucesso do sistema" })
        setTimeout(() => navigate("/", { replace: true }), 1000)
    }
}

export const menuItems = () => {
    const { ROUTES } = environment

    const subItems: SubItemMenuComponentProps[] = [
        { name: "Dashboard", redirectTo: ROUTES.DASHBOARD, isAdmin: false, hasIcon: true, icon: <BiHome />, startLine: true },
        { name: "Meu perfil", redirectTo: ROUTES.MY_PROFILE, isAdmin: false, hasIcon: true, icon: <BiUser />, endLine: true },

        { name: "Turmas", redirectTo: ROUTES.TURMAS.HOME, isAdmin: true, hasIcon: true, icon: <BiUserCheck />  },
        { name: "Módulos", redirectTo: ROUTES.MODULES.MY_MODULES, isAdmin: false, removeAdmin: true, hasIcon: true, icon: <SiGoogleclassroom />, endLine: true },
        { name: "Módulos", redirectTo: ROUTES.MODULES.HOME, isAdmin: true, hasIcon: true, icon: <SiGoogleclassroom /> },
        // { name: "Mensalidades", redirectTo: ROUTES.MENSALIDADES, isAdmin: true, hasIcon: true, icon: <BiWalletAlt /> },
        { name: "Notificações", redirectTo: ROUTES.NOTIFICATIONS, isAdmin: true, hasIcon: true, icon: <BiBell />  },
        { name: "Configurações", redirectTo: ROUTES.CONFIGURATIONS.USER.LIST, isAdmin: true, hasIcon: true, icon: <BiCog /> , endLine: true },
    ]

    return subItems
}

export const constructSubitem = (props: any) => (subItem: SubItemMenuComponentProps, index: number) => {
    const userIsAdmin = props.user?.groups?.find((group: Groups) => group.isAdmin)
    if (subItem.removeAdmin && userIsAdmin) return <></>
    
    const startLine = subItem.startLine !== undefined ? subItem.startLine : false
    const endLine = subItem.endLine !== undefined ? subItem.endLine : false

    return (
        <>
            { !startLine ? undefined : <div className="subitem-component-line-separator" /> }

            <SubItemComponent
                key={`#${subItem.name}-subitem-${index}`}
                isAdmin={ subItem.isAdmin }
                groups={ props.groups }
                name={ subItem.name } 
                route={ props.name }
                redirectTo={ subItem.redirectTo }
                hasIcon={ subItem.hasIcon }
                icon={ subItem.icon }
                style={ subItem.style }
                onClick={ subItem.onClick }
            />

            { !endLine ? undefined : <div className="subitem-component-line-separator" /> }
        </>
    )
}

export const constructSubMenu = (subMenu: SubMenuItemProps, index: number) => {
    return (
        <SubMenuItemComponent
            key={`#${subMenu.name}-submenu-${index}`}
            name={subMenu.name}
            redirectTo={subMenu.redirectTo}
            hasIcon={subMenu.hasIcon}
            icon={subMenu.icon}
        />
    )
}

export const getWeekNameById = (weekId: any) => {
    const weekDays = [
        "Dom",
        "Seg",
        "Ter",
        "Qua",
        "Qui",
        "Sex",
        "Sab",
     ]

     return weekDays[weekId]
}

export const convertMsToTime = (milliseconds: number, props?: ConvertMsToTimeInterface) => {
    try {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;
        
        return props?.isHours ? hours : props?.isMinutes ? minutes : props?.isSeconds ? seconds : milliseconds;
    } catch {
        return milliseconds
    }
}

export const formatDateByDate = (date: Date) => {
    try {
        return moment(date).utc().format("DD/MM/yyyy");
    } catch {
        return moment().utc().format("DD/MM/yyyy")
    }
}

export const formatPhoneNumber = (phone: string, isRegister?: boolean) => {
    try {
        //Filter only numbers from the input
        let cleaned = ('' + phone).replace(/\D/g, '');

        //Check if the input is of correct
        if (isRegister) {
            if (cleaned.length !== 11) {
                return isRegister ? "" : phone
            }

            return cleaned.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4')
        }

        if (cleaned.length === 10) {
            return cleaned.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3')
        } else {
            return cleaned.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4')
        }
    } catch {
        return isRegister ? "" : phone
    }
}

export const existsValueInArray = (value: string, array: string[]) => {
    try {
        var exists = false
        for (var i = 0; i < array.length; i++) {
            const _value = array[i]
            if (String(value) === String(_value)) {
                exists = true;
                break;
            }
        }
        
        return exists
    } catch  {
        return false
    }
}

export const formatBirthday = (value?: Date) => {
    try {
        if (!value) throw new Error("Data nao informada")
        const date = moment(value).format("DD/MM/YYYY")
        return date
    } catch {
        return "Não informado"
    }
}

export const formatUserPhoto = (user: Users) => {
    try {
        const photo = user.photo
        if (!photo?.includes("null") && photo) return photo
        return "https://cdn-icons-png.flaticon.com/512/149/149071.png"
    } catch {
        return "https://cdn-icons-png.flaticon.com/512/149/149071.png"
    }
}

export const formatInvoiceType = (name: string) => {
    try {
        switch (name) {
            case "FATURA PAGA": return "Pago"
            case "AGUARDANDO PAGAMENTO": return "Aguardando"
            case "EM ANÁLISE": return "Em análise"
            case "COBRANÇA REJEITADA": return "Rejeitado"
            case "COBRANÇA CANCELADA": return "Cancelado"
            case "COBRANÇA EXPIRADA": return "Expirado"
            case "AGUARDANDO GERAÇÃO": return "Gerando"
            default: return name
        }
    } catch (err) {
        return name
    }
}

export const formatPlanPrice = (price: any) => {
    try {
        const priceString = String(price)
        const includeCents = priceString.includes(",")
        let newPrice = ""

        if (includeCents) {
            const split = priceString.split(",")
            const hasMoreValue = split[1] && split[1].length > 1
            newPrice = `${price}${ !hasMoreValue ? "0" : "" }`
        } else {
            newPrice = `${price},00`
        }

        return newPrice
    } catch (err) {
        return price
    }
}

export const formatCpf = (value: any) => {
    let cpf = value.replace(/[^\d]/g, "")
    if (cpf.length > 11) cpf = cpf.substring(0, 11)
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
}

export const formatCpfInput = (event: any) => {
    const { name, value } = event.target
    if (value === undefined || value === "") return
    const input: any = document.getElementById(name)
    if (input === undefined) return

    let cpf = value.replace(/[^\d]/g, "")
    if (!CPF.validate(cpf)) {
        input.value = ""
        return AlertsService.notification({ icon: "error", title: "Informe um CPF válido" })
    }

    if (cpf.length > 11) cpf = cpf.substring(0, 11)
    if (cpf.length === 11) {
        const isValid = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/
        if (!isValid.test(cpf)) {
            input.value = formatCpf(value)
            return
        }
    }
    
    input.value = ""
    return AlertsService.notification({ icon: "error", title: "Informe um CPF válido" })
}

export const formatPhoneInput = (event: any) => {
    const { name, value } = event.target
    if (value === undefined || value === "") return
    const input: any = document.getElementById(name)
    if (input === undefined) return

    const formatted = formatPhoneNumber(value, true)
    if (formatted !== "") {
        input.value = formatted
        return
    }

    input.value = ""
    return AlertsService.notification({ icon: "error", title: "Informe um número de celular válido" })
}

export const formatBirthdayDate = (value: any) => {
    const date = moment(value, "DD/MM/yyyy").utc().format("DD/MM/yyyy");
    if (date === "Invalid date") throw new Error("Invalid date")
    return date
}

export const formatBirthdayInput = (event: any) => {
    const { name, value } = event.target
    if (value === undefined || value === "") return
    const input: any = document.getElementById(name)
    if (input === undefined) return

    try {
        if ((value || "").length < 10) throw new Error("Invalid date")
        input.value = formatBirthdayDate(value)
    } catch (err) {
        input.value = ""
        return AlertsService.notification({ icon: "warning", title: "A data de nascimento informada está incorreta!" })
    }
}

export const formatPassword = (event: any) => {
    const { name, value } = event.target
    if (value === undefined || value === "") return
    const input: any = document.getElementById(name)
    if (input === undefined || input === null) return

    try {
        if ((value || "").toString().includes(" ")) throw new Error("Password has spaces")
        const newPassword = value.replace(/\s/g, '')
        if (newPassword.length < 6) throw new Error("Password incorrect")
        input.value = newPassword
    } catch (err) {
        input.value = ""
        return AlertsService.notification({ icon: "error", title: "Informe sua senha corretamente!" })
    }
}

export const getActualInvoice = (invoices: Invoices[]) => {
    try {
        return invoices
            .filter((invoice: Invoices) => !invoice.paid)
            .filter((invoice: Invoices) => invoice.status === environment.INVOICES.STATUS.WAITING_PAYMENT)[0]
    } catch (err: any) {
        return undefined
    }
}
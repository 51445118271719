import { useParams } from "react-router-dom"
import { ConfigurationsUserInfoProps } from "../../../@types/configurations.types"
import { useEffect, useState } from "react"
import { Invoices, InvoicesType } from "../../../@types/invoices.types"
import { environment } from "../../../common/environments"
import { AlertsService } from "../../../services/alerts.service"
import BlockComponent from "../../../component/block"
import ButtonComponent from "../../../component/button"
import moment from "moment"
import { Groups } from "../../../@types/groups.types"
import { useRequestProvider } from "../../../context/request.provider.context"
import { getActualInvoice } from "../../../common/utilitaries"

const UserInvoicesComponent = (props: ConfigurationsUserInfoProps) => {
    
    const isProfile = props.isProfile ? props.isProfile : false
    const { updateInvoice } = useRequestProvider()

    const title = isProfile ? "Histórico de faturas" : "Faturas do usuário"
    const legend = isProfile ? "Todas os histórico de faturas" : "Todas as faturas do usuário"
    const actualInvoice = getActualInvoice(props.user.invoices)

    const payActuallyInvoice = async (e: any) => {
        e.preventDefault()
        
        if (!actualInvoice) {
            return AlertsService.notification({ icon: "warning", title: "Não foi possível buscar a fatura atual desse usuário" })
        }

        const result = await AlertsService.confirmation({
            title: `Baixa na fatura do usuário`,
            html: `Você deseja dar baixa na fatura do ${props.user.name}?`
        })

        if (!result.isConfirmed) {
            return AlertsService.notification({ icon: "warning", title: "Você optou por não dar baixa na fatura." })
        }

        try {
            const response = await updateInvoice({
                invoiceId: actualInvoice.id,
                paid: true
            })
    
            if (!response) {
                return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao dar baixa na fatura." })
            }
            
            AlertsService.notification({ icon: "success", title: "Sucesso! Fatura foi paga com sucesso." })
        } catch (err) {
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao dar baixa na fatura." })
        }
    }

    const updateActuallyInvoice = async (e: any) => {
        e.preventDefault()

        const result = await AlertsService.confirmation({
            title: `Atualizar fatura`,
            html: `Você deseja atualizar a fatura do ${props.user.name}?`
        })
    }

    const showNextInvoice = () => {
        const isAdmin = props.user.groups?.filter((group: Groups) => group.isAdmin).length > 0 ? true : false
        const title = isProfile ? "Próxima fatura a vencer" : "Próxima fatura do usuário"
        const legend = isProfile ? "Sua próxima fatura que estará vencendo" : "Próxima fatura do usuário a vencer"
        const hasInvoice = actualInvoice !== undefined && actualInvoice.status === environment.INVOICES.STATUS.WAITING_PAYMENT
        const actualPrice = `R$ ${(actualInvoice?.plan?.price || 0).toFixed(2)}`
        const dueDate = moment(actualInvoice?.expire_at || "01/01/2000")
        const dueDateFormat = dueDate.format("DD/MM/YYYY")
        const date1 = new Date(dueDate.toDate()).getTime()
        const date2 = new Date(moment().format("MM/DD/YYYY")).getTime()
        const overdueInvoice = date2 > date1

        const nextDue = 
            overdueInvoice && !isAdmin && hasInvoice ? `Fatura venceu em ${dueDateFormat}` :
            hasInvoice && !isAdmin ? `Vencimento em ${dueDateFormat}` :
            isProfile ? "Não há uma próxima fatura a vencer para você" :
            "Não há próximo vencimento para este usuário."

        const showActions = () => {
            if (!isAdmin && hasInvoice) {
                const linkToPay = actualInvoice?.online?.links?.filter((link: any) => link.type === "PAY")[0].url
                
                return (
                    <ButtonComponent
                        text="Pagar fatura"
                        type="button"
                        onClick={() => window.open(linkToPay, "_blank")}
                        style={{
                            backgroundColor: "green"
                        }}
                    />
                )
            }

            // Usuário administrador
            // É a aba profile
            if (isAdmin || isProfile || !hasInvoice) return
            // Mostrando
            return (
                <>
                    <ButtonComponent
                        text="Dar baixa"
                        type="button"
                        onClick={payActuallyInvoice}
                        style={{
                            backgroundColor: "green"
                        }}
                    />

                    <ButtonComponent
                        text="Atualizar"
                        type="button"
                        onClick={updateActuallyInvoice}
                        style={{
                            backgroundColor: "#ff9900"
                        }}
                    />
                </>
            )
        }

        const showActualPrice = () => {
            if (!hasInvoice) return (
                <div className="configurations-user-info-content-invoice-price" 
                    style={{ padding: ".25rem" }}/>
            )

            return (
                <div className="configurations-user-info-content-invoice-price">
                    { actualPrice }
                </div>
            )
        }

        return (
            <BlockComponent title={title} legend={legend}>
                <div className="configurations-user-info-content-invoice">
                    { showActualPrice() }
                    <div className="configurations-user-info-content-invoice-legend">
                        { nextDue }
                    </div>
                    <div className="configurations-user-info-content-invoice-actions">
                        { showActions() }
                    </div>
                </div>
            </BlockComponent>
        )
    }

    const showInvoices = () => {

        const constructInvoice = (invoice: Invoices, index: number) => {
            return (
                <div key={`#invoice-list-${index}`} className="configurations-user-info-content-invoices-content">
                    { `R$ ${ (invoice.plan?.price || 0).toFixed(2) }` }
                                        
                    {/* Outros */}
                    <div className="configurations-user-info-content-invoices-content-others">
                        <span>{ `Data de vencimento: ${moment(invoice.expire_at).format("DD/MM/YYYY")}` }</span>
                        <span>{ invoice.status }</span>
                    </div>
                </div>
            )
        }

        const showInvoices = () => {
            const message = !isProfile ? "Não há faturas pagas anteriormente" : "Você não possui faturas pagas anteriormente"
            const hasInvoices = props.user?.invoices?.length > 0
            if (!hasInvoices) {
                return <p style={{ padding: "1vh", textAlign: "center" }}>
                    { !hasInvoices ? "Não foi encontrada nenhuma fatura" : message }
                </p>
            }

            const compareInvoice = (invoiceA: Invoices, invoiceB: Invoices) => {
                const dateA = moment(invoiceA.expire_at).toDate()
                const dateB = moment(invoiceB.expire_at).toDate()
                return dateA.getTime() < dateB.getTime() ? 1 : dateA.getTime() > dateB.getTime() ? -1 : 0
            }

            return (props.user.invoices.sort(compareInvoice).map(constructInvoice)
            )
        }

        return (
            <div className="configurations-user-invoices-block">
                <BlockComponent title={title} legend={legend} style={{ paddingBottom: "1.5vh" }}>
                    <div className="configurations-user-info-content-invoices">
                        { showInvoices() }
                    </div>
                </BlockComponent>
            </div>
        )
    }

    return (
        <>{ props.type === "INVOICES" ? showInvoices() : showNextInvoice() }</>
    )
}

export default UserInvoicesComponent
import "./index.css"
import { UserInfoInterface, Users } from "../../../../@types/users.types";
import { formatBirthday, formatPhoneNumber, formatUserPhoto } from "../../../../common/utilitaries";
import { CSSProperties } from "react";
import { SiInstagram } from "react-icons/si";
import ButtonComponent from "../../../../component/button";
import { FaUserEdit } from "react-icons/fa";
import { environment } from "../../../../common/environments";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { AlertsService } from "../../../../services/alerts.service";
import { Groups } from "../../../../@types/groups.types";
import { RequestsService } from "../../../../services/requests.service";
import { DefaultResponse } from "../../../../@types/response.types";

const UserInfoComponent = (prop: UserInfoInterface) => {

    const navigator = useNavigate()

    const deleteUser = async (e: any) => {
        e.preventDefault();

        const isAdmin = prop.user.groups.filter((group: Groups) => group.isAdmin)
        if (isAdmin.length > 0) {
            return AlertsService.notification({ icon: "error", title: "Você não pode deletar usuários administradores" })
        }

        const confirm = await AlertsService.confirmation({
            icon: "info",
            title: "Excluir usuário?",
            html: "Escolha a forma como o usuário será deletado em uma das opções abaixo: ",
            input: "radio",
            inputOptions: {
                0: "Temporária",
                1: "Permanente"
            },
            inputValidator: (value) => {
                if (!value) {
                    return "Necessário escolher um tipo de exclusão"
                }
            },
            showCancelButton: true,
            cancelButtonText: "Cancelar operação",
            confirmButtonText: "Confirmar"
        })

        if (!confirm || !confirm.isConfirmed) return
        const permanently = confirm.value == 0 ? false : true

        try {
            const request = await RequestsService.call({
                method: "DELETE",
                url: environment.URLS.USER.DEL + prop.user.id,
                params: {
                    removePermanently: permanently
                }
            })

            if (!request || request && !request.data) throw new Error("No response found")
            const response: DefaultResponse = request.data

            if (response.success) {
                setTimeout(() => navigator(environment.ROUTES.CONFIGURATIONS.USER.LIST, { replace: true }), 1000)
            }

            return AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })
        } catch (e) {
            console.error(e)
            AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao deletar o usuário" })
        }
    }

    /**
     * @name showUserDetails - Mostra informações do usuário
     * 
     * @param name 
     * @param value 
     * @param options 
     * 
     * @returns Component
     */
    const showUserDetails = (name: string, value: any, options?: { style: CSSProperties }) => {
        return (<p style={options?.style}><strong>{name}:</strong> { value }</p>)
    }

    /**
     * @name showUserGroups - Mostra os grupos do usuário
     * 
     * @returns Component
     */
    const showUserGroups = () => {
        if (!prop.user || (prop.user && !prop.user.groups)) return "Nenhum grupo atribuído."
        if (prop.user.groups.length < 1) return "Nenhum grupo atribuído."

        return prop.user.groups.map((group, index) => (
            <span className="user-info-component-description-group" key={`${group.name}-#${index}`}>
                {group.name}
            </span>
        ))
    }

    /**
     * @name showUserModules - Mostra os módulos do usuário
     * 
     * @returns Component
     */
    const showUserModules = () => {
        if (!prop.user || (prop.user && !prop.user.modules)) return "Nenhum módulo atribuído."
        if (prop.user.modules.length < 1) return "Nenhum módulo atribuído."

        return prop.user.modules.map((group, index) => (
            <span className="user-info-component-description-module" key={`${group.name}-#${index}`}>
                {group.name}
            </span>
        ))
    }
    
    /**
     * @name showUserClasses - Mostra as classes do usuário
     * 
     * @returns Component
     */
    const showUserClasses = () => {
        if (!prop.user || (prop.user && !prop.user.classes)) return "Nenhuma turma atribuída."
        if (prop.user.classes.length < 1) return "Nenhuma turma atribuída."
        return `${prop.user.classes.map(classe => classe.name).join(", ")}.`
    }

    /**
     * @name showSocials - Mostra as redes sociais do usuário
     * 
     * @returns Component
     */
    const showSocials = () => {
        const user: Users = prop.user
        if (!user.instagram || user.instagram === "undefined") return;

        const showSocial = (text: string, link: string, icon: any) => {
            return (
                <a className="user-info-component-description-social" target="_blank" rel="noreferrer noopener" href={link}>
                    <span>{ icon }</span>
                    <span>{ text }</span>
                </a>
            )
        }

        return (
            <div className="user-info-component-description-socials">
                { user.instagram ? showSocial("Instagram", user.instagram, <SiInstagram />) : <></> }
            </div>
        )
    }

    const urlEdit = !prop.isProfile ? 
        `${environment.ROUTES.CONFIGURATIONS.USER.LIST}/update/${prop.user.id}` : 
        `${environment.ROUTES.MY_PROFILE}/update`

    return (
        <div className="user-info-component">
            {/* Edit User */}
            <div className="user-info-component-edit">
                <ButtonComponent 
                    text="Editar perfil"
                    type="button"
                    class="user-info-component-edit-button"
                    hasIcon={true}
                    icon={<FaUserEdit />}
                    onClick={() => navigator(urlEdit, { replace: true }) }
                />

                { prop.isProfile ? <></> :
                    <ButtonComponent 
                        class="user-info-component-delete-button" 
                        type="button" 
                        text={(<MdDelete />)} 
                        onClick={deleteUser} 
                    />
                }
            </div>

            {/* HEADER */}
            <div className="user-info-component-header">
                <div className="user-info-component-header-icon">
                    <img src={formatUserPhoto(prop.user)} alt="new" />
                </div>

                <div className="user-info-component-header-name">
                    <p>{prop.user.name}</p>
                </div>
            </div>

            {/* Description */}
            <div className="user-info-component-description">
                { /* Telefone */ }
                { showUserDetails("Telefone", formatPhoneNumber(prop.user.phone)) }
                { /* E-mail */ }
                { showUserDetails("E-mail", prop.user.email) }
                { /* Data de nascimento */ }
                { showUserDetails("Data de nascimento", formatBirthday(prop.user.birthday)) }
                
                <br />
                { /* Grupos */ }
                { showUserDetails("Grupos", showUserGroups(), { style: { marginBottom: ".5vh" } }) }
                { /* Módulos */ }
                { showUserDetails("Módulos", showUserModules(), { style: { marginBottom: ".5vh" } }) }
                { /* Turmas */ }
                { showUserDetails("Turmas", showUserClasses(), { style: { marginBottom: ".5vh" } }) }
                {/* Socials */}
                { showSocials() }
            </div>
        </div>
    )
}

export default UserInfoComponent;
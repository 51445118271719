import { BiHome } from "react-icons/bi"
import { MenuPrivateProps, SubMenuItemProps } from "../../@types/component.types"
import { Users } from "../../@types/users.types"
import { environment } from "../../common/environments"
import { Groups } from "../../@types/groups.types"
import PieGraphComponent from "../../component/graphs/pie"
import { useEffect, useState } from "react"
import { RequestsProps } from "../../@types/request.types"
import { AlertsService } from "../../services/alerts.service"
import { DefaultResponse } from "../../@types/response.types"
import { RequestsService } from "../../services/requests.service"
import { GraphData } from "../../@types/graphs.types"
import BarGraphComponent from "../../component/graphs/bar"
import { constructSubMenu, formatInvoiceType } from "../../common/utilitaries"
import moment from "moment"
import "./index.css"
import MenuDesktopComponent from "../../component/menu-desktop"
import DashboardCreateComponent from "../../component/dashboard/create"
import { DashboardCreateProps } from "../../@types/dashboard.component.types"
import UserFrequencyComponent from "../configurations/user/user.frequency"
import UserInvoicesComponent from "../configurations/user/user.invoices"

const Dashboard = (props: MenuPrivateProps) => {

    const [error, setError] = useState(new Map<any, any>())
    const updateError = (name: any, value: boolean) => setError(loaded.set(name, value))

    const [loaded, setLoaded] = useState(new Map<any, any>())
    const updateLoaded = (name: any, value: boolean) => setLoaded(loaded.set(name, value))

    const [userReports, setUserReports] = useState([] as GraphData[])
    const [invoicesReports, setInvoicesReports] = useState([] as GraphData[])

    const { ROUTES } = environment
    const subMenus: SubMenuItemProps[] = [
        { name: "Home", redirectTo: ROUTES.DASHBOARD, hasIcon: true, icon: <BiHome /> },
    ]

    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: "Home",
        lateralMenu: "Dashboard",
        subMenus
    }

    const searchUsersReports = async () => {
        if (userReports.length > 0) return;

        const requestObject: RequestsProps = {
            method: "GET",
            url: environment.URLS.REPORTS.USERS
        }

        try {
            const request = await RequestsService.call(requestObject)
            if (!request.data) throw new Error("No data returned")
            
            const response: DefaultResponse = request.data
            // Ocorreu um erro
            if (!response.success) throw new Error(response.message)
            // Dados
            const data = response.data
            // Setando dados
            setUserReports([
                { name: "Ativos", value: data.totalActive || 0 },
                { name: "Inativos", value: data.totalDeleted || 0 },
            ])
            // Loaded
            updateLoaded("users", true)
        } catch (err) {
            updateError("users", true)
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao obter o relatório de usuários" })
        }
    }

    const searchInvoicesReports = async () => {
        if (invoicesReports.length > 0) return;

        const sixMonthsAgo = moment().subtract(6, 'month')
        const requestObject: RequestsProps = {
            method: "GET",
            url: environment.URLS.REPORTS.INVOICES,
            params: {
                startDate: sixMonthsAgo.format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            }
        }

        try {
            const request = await RequestsService.call(requestObject)
            if (!request.data) throw new Error("No data returned")
            
            const response: DefaultResponse = request.data
            // Ocorreu um erro
            if (!response.success) throw new Error(response.message)
            // Dados
            const data = response.data
            // Setando dados
            const reports: GraphData[] = []

            for (let i = 0; i < data.length; i++) {
                const d: any = data[i]
                const name = d.id

                reports.push(
                    { name: formatInvoiceType(name), type: "Total", value: d.total || 0 }, 
                    { name: formatInvoiceType(name), type: "Valor total", value: (d.planValues || 0) }, 
                    { name: formatInvoiceType(name), type: "Desconto", value: d.discounts || 0 }
                )
            }

            setInvoicesReports(reports)
            // Loaded
            updateLoaded("invoices", true)
        } catch (err) {
            updateError("invoices", true)
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao obter o relatório de faturas" })
        }
    }

    const showWelcome = () => {
        if (!props.user) return;
        const name = props.user.name
        if (name == undefined) return
        const firstName = name.split(" ")[0]

        return (
            <div className="dashboard-welcome">
                <h1>{ `Bem-vindo, ${firstName}.` }</h1>
            </div>
        )
    }

    const constructDashboardUser = () => {
        if (!props.user || !props.user.groups) return;

        const user: Users = props.user
        const isAdmin = user.groups.filter((group: Groups) => group.isAdmin).length > 0
        if (isAdmin) {
            return <></>
        }

        return (
            <div className="dashboard-user">
                <div className="dashboard-user-block">
                    {/* Frequência geral */}
                    <UserFrequencyComponent groups={props.groups} user={props.user} isProfile={true} />

                    {/* Próxima fatura */}
                    <UserInvoicesComponent groups={props.groups} user={props.user} isProfile={true} type="NEXT INVOICE" />
                </div>

                {/* Lista de faturas */}
                <UserInvoicesComponent groups={props.groups} user={props.user} isProfile={true} type="INVOICES" />
            </div>
        )
    }

    const constructDashboardAdmin = () => {
        if (!props.user || !props.user.groups) return;

        const user: Users = props.user
        const isAdmin = user.groups.filter((group: Groups) => group.isAdmin).length > 0
        if (!isAdmin) {
            return <></>
        }

        return (
            <div className="dashboard-admin">
                <div className="dashboard-admin-content">
                    {/* Usuários cadastrados */}
                    <div className="dashboard-admin-users-reports">
                        <PieGraphComponent
                            pieData={userReports}
                            color={["#179917", "#a50200"]}
                            content={{
                                title: "Usuários ativos e inativos",
                                styleTitle: { fontSize: 10 },
                                description: "Quantidade de usuários ativos e inativos na plataforma",
                                styleDescription: { fontSize: 12 },
                                hasLoaded: loaded.get("users") != undefined ? loaded.get("users") : undefined,
                                hasError: error.get("users") != undefined ? error.get("users") : undefined,
                                showTotal: {
                                    enable: true,
                                    title: "Total geral:"
                                }
                            }}
                        />
                    </div>
                </div>

                {/* Faturas */}
                <div className="dashboard-admin-invoices-reports">
                    <BarGraphComponent
                        type="GROUPED"
                        barData={invoicesReports}
                        content={{
                            hasLoaded: true,
                            title: "Status das faturas",
                            styleTitle: { fontSize: 10 },
                            description: "Mostrando os status das faturas nos últimos 6 meses",
                            styleDescription: { fontSize: 12 },
                        }}
                    />
                </div>

                {/*  */}
            </div>
        )
    }

    useEffect(() => {
        if (!props.user || !props.user.groups) return;

        const timeout = setTimeout(async () => {
            if (!props.user || !props.user.groups) return;

            const user: Users = props.user
            const isAdmin = user.groups.filter((group: Groups) => group.isAdmin).length > 0

            if (isAdmin) {
                await searchUsersReports()
                await searchInvoicesReports()
            }
        })

        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>
            {/* Welcome */}
            { showWelcome() }

            {/* Dashboard content */}
            { constructDashboardUser() }
            { constructDashboardAdmin() }
        </DashboardCreateComponent>
    )
}

export default Dashboard
import { useEffect, useState } from "react"
import { MenuPrivateProps } from "../../@types/component.types"
import { DashboardCreateProps } from "../../@types/dashboard.component.types"
import DashboardCreateComponent from "../../component/dashboard/create"
import { myModulesSubMenus } from "./props"
import { Modules, ModulesResponse, MyModules } from "../../@types/modules.types"
import "./index.css"
import MyModulesFilterComponent from "./filter"
import { AlertsService } from "../../services/alerts.service"
import { RequestsService } from "../../services/requests.service"
import { environment } from "../../common/environments"
import { ClassesResponse } from "../../@types/classes.types"
import ButtonComponent from "../../component/button"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { StorageService } from "../../services/storage.service"

const MyModulesComponent = (props: MenuPrivateProps) => {

    const navigate = useNavigate()

    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: "Módulos",
        lateralMenu: "Módulos",
        subMenus: myModulesSubMenus()
    }

    const [isFetched, setFetched] = useState(false)
    const [modules, setModules] = useState<MyModules[]>([])

    /**
     * @name searchModulesAndClases - Mostra os módulos e classes cadastrados para o cliente
     */
    const searchModulesAndClases = async () => {
        try {
            const [requestModules, requestClasses] = await Promise.all([
                RequestsService.call({
                    method: "GET",
                    url: environment.URLS.MODULES.ME
                }),
                RequestsService.call({
                    method: "GET",
                    url: environment.URLS.CLASSES.ME
                })
            ])

            if ((!requestModules || !requestModules.data) && (!requestClasses || !requestClasses.data)) throw new Error("No response found")
            const modulesResponse: ModulesResponse = requestModules.data
            const classesResponse: ClassesResponse = requestClasses.data
            if (!modulesResponse.success) throw new Error(modulesResponse.message)
            if (!classesResponse.success) throw new Error(classesResponse.message)

            const modulesData = modulesResponse.data || []
            const clasesData = classesResponse.data || []
            const data: MyModules[] = []

            for (let i = 0; i < modulesData.length; i++) {
                const module = modulesData[i]
                if (!module) continue
                data.push(module)
            }

            for (let i = 0; i < clasesData.length; i++) {
                const classes = clasesData[i]
                if (!classes) continue

                classes.modules?.forEach((module: Modules) => {
                    const hasModule = data.find((my_module: MyModules) => my_module.name === module.name)
                    if (hasModule) return
                    data.push(module)
                })
            }

            setFetched(true)
            setModules(data)
            StorageService.saveMyModules(data)

            console.log(data)

            return AlertsService.notification({ icon: "success", title: "Módulos obtidos com sucesso!" })
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao buscar os seus módulos atribuídos" })
        }
    }

    /**
     * @name showNumberResults - Mostra o resultado dos módulos obtidos
     */
    const showNumberResults = () => {
        const total = modules.length
        const totalMessage = !isFetched ? "..." : total.toLocaleString("pt-BR")

        return (
            <div className="mymodules-contents-header-text">
                <p>Mostrando { totalMessage } resultados</p>
            </div>
        )
    }

    /**
     * @name showModules - Mostra os módulos cadastrados
     */
    const showModules = () => {
        if (!isFetched) 
            return <p className="modules-contents-items-loading">Buscando módulos...</p>
        if (isFetched && modules.length < 1) 
            return <h1 className="modules-contents-items-loading">Não há valores!</h1>

        return modules.map((module: MyModules) => (
            <div className="modules-contents-item" key={module.id}>
                <div className="modules-contents-item-header">
                    <p>{ module.clases?.length } aula(s) cadastrada(s)</p>
                </div>

                <div className="modules-contents-item-title">
                    <p>{ module.name }</p>
                </div>

                <div className="modules-contents-item-description">
                    <p>{ module.description }</p>
                </div>

                <div className="modules-contents-item-footer">
                    <ButtonComponent
                        text="Ver módulo"
                        type="button"
                        class="modules-contents-item-footer-button"
                        onClick={() => navigate(`/my-modules/${module.id}`, { replace: true })}
                    />
                </div>
            </div>
        ))
    }

    useEffect(() => {
        const timeout = setTimeout(() => searchModulesAndClases(), 0)
        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>
            <div className="modules-contents">
                {/* Header */}
                <div className="mymodules-contents-header">
                    {/* Números */}
                    { showNumberResults() }

                    {/* Filtro */}
                    <MyModulesFilterComponent />
                </div>

                {/* Conteúdo */}
                <div className="modules-contents-items">
                    { showModules() }
                </div>
            </div>
        </DashboardCreateComponent>
    )
}

export default MyModulesComponent
import { MenuComponentProps, SubItemMenuComponentProps } from "../../@types/menu.component.types"
import "./index.css"
import logo from '../../files/images/Logo preto.png'
import { constructSubitem, formatUserPhoto, logoutUser, menuItems } from "../../common/utilitaries"
import ButtonComponent from "../button"
import { BiExit } from "react-icons/bi"
import { useAuth } from "../../context/auth.context"
import { useNavigate } from "react-router-dom"
import { Groups } from "../../@types/groups.types"
import { environment } from "../../common/environments"
import { SiGoogleclassroom } from "react-icons/si"

const MenuDesktopComponent = (props: MenuComponentProps) => {

    const { removeLoggedAfterTokenExpired } = useAuth()
    const navigate = useNavigate()
    const env = environment
    const ROUTES = env.ROUTES
    const isAdmin = props.user?.groups?.filter((group: Groups) => group.isAdmin).length > 0

    const itemsUser: SubItemMenuComponentProps[] = isAdmin ? [] : [
        { name: "Módulos", redirectTo: ROUTES.MODULES.MY_MODULES, isAdmin: false, hasIcon: true, icon: <SiGoogleclassroom />, endLine: true },
    ]

    const items = menuItems().concat(
        ...itemsUser,
        { name: "Sair", isAdmin: false, hasIcon: true, icon: <BiExit />, onClick: logoutUser(removeLoggedAfterTokenExpired, navigate) }
    )

    return (
        <div className="menu">
            <div className="menu-header">
                <img alt="logo" src={logo} />
            </div>

            <div className="menu-content">
                <div className="menu-content-profile">
                    <img src={ formatUserPhoto(props.user) } alt="user-photo-sidebar" />
                    <p>{props.user.name}</p>
                </div>

                { items.map(constructSubitem(props)) }
            </div>
        </div>
    )
}

export default MenuDesktopComponent
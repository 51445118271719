import './index.css'

import { SubMenuComponentProps } from '../../../@types/menu.component.types'
import { useLocation, useNavigate } from 'react-router-dom'

const SubMenuItemComponent = (props: SubMenuComponentProps) => {

    const navigator = useNavigate()
    const location = useLocation()
    const className = "dashboard-sub-menu-item"
        + (location.pathname.startsWith(props.redirectTo) ? " dashboard-sub-menu-item-line" : "")

    const buildIcon = () => {
        if (!props.hasIcon) return
        return ( <span>{props.icon}</span> )
    }

    return (
        <div className={className} onClick={ () => navigator(props.redirectTo, { replace: true }) }>
            {/* icon */}
            { buildIcon() }
            
            {/* Text */}
            <p>{props.name}</p>
        </div>
    )

}

export default SubMenuItemComponent
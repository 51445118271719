import { useNavigate, useParams } from "react-router-dom";
import { ConfigurationsUserInfoProps } from "../../../@types/configurations.types";
import { useRequestProvider } from "../../../context/request.provider.context";
import { useEffect, useState } from "react";
import { Users } from "../../../@types/users.types";
import { AlertsService } from "../../../services/alerts.service";
import { environment } from "../../../common/environments";
import DashboardBack from "../../dashboard/back";
import "../index.css"
import "./index.css"
import PieGraphComponent from "../../../component/graphs/pie";
import { Invoices, InvoicesType } from "../../../@types/invoices.types";
import BlockComponent from "../../../component/block";
import moment from "moment";
import ButtonComponent from "../../../component/button";
import UserInfoComponent from "./info";
import { GraphData } from "../../../@types/graphs.types";
import { DashboardCreateProps } from "../../../@types/dashboard.component.types";
import { configurationsSubMenus } from "../props";
import DashboardCreateComponent from "../../../component/dashboard/create";
import { Groups } from "../../../@types/groups.types";
import { RequestsService } from "../../../services/requests.service";
import { DefaultResponse } from "../../../@types/response.types";
import UserFrequencyComponent from "./user.frequency";
import UserInvoicesComponent from "./user.invoices";

const ConfigurationsUserInfo = (props: ConfigurationsUserInfoProps) => {

    const navigate = useNavigate()
    const params = useParams()
    
    const isProfile = props.isProfile ? props.isProfile : false

    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: !isProfile ? "Usuários" : "",
        lateralMenu: isProfile ? "Meu perfil" : "Configurações",
        subMenus: !isProfile ? configurationsSubMenus() : []
    }

    const [fetched, setFetched] = useState(new Map<any, any>())
    const updateFetched = (key: any, value: any) => setFetched(fetched.set(key, value))

    const isFetched = (key: any) => fetched.get(key) !== undefined && fetched.get(key)
    const isUserFetched = () => isFetched("user")
    const [user, setUser] = useState({} as Users)

    const redirectToList = () => {
        const url = isProfile ? environment.ROUTES.DASHBOARD : environment.ROUTES.CONFIGURATIONS.USER.LIST
        if (!isProfile) {
            AlertsService.notification({ icon: "error", title: "Não foi possível encontrar esse usuário." })
        }

        setTimeout(() => navigate(url, { replace: true }), isProfile ? 0 : 800)
        return;
    }

    useEffect(() => {
        // Já foi buscado?
        if (isUserFetched() || props.isProfile) return
        // Timeout
        const timeout = setTimeout(async () => {
            try {
                // Verificando se já foi buscado as turmas
                if (isUserFetched()) return
                // Buscando a turma
                const { USER } = environment.URLS
                const url = isProfile ? USER.INFO : USER.INFO + "/" + params.id
                const request = await RequestsService.call({
                    method: "GET",
                    url,
                    params: {
                        listOfInvoices: true
                    }
                })

                if (!request || (request && !request.data)) throw new Error("no response found")
                const response: DefaultResponse = request.data
                if (!response.success) {
                    return redirectToList()
                }
                
                const user: Users = response.data

                updateFetched("user", true)
                setUser(user)
            } catch (e) {
                AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao buscar o usuário cadastrado." })
            }
        })

        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>
            { isProfile ? <></> : <DashboardBack backUrl={environment.ROUTES.CONFIGURATIONS.USER.LIST} /> }

            <div className="configurations-user-block">
                {/* User Info */}
                <UserInfoComponent isProfile={props.isProfile} user={props.isProfile ? props.user : user} />

                <div className="configurations-user-other-block">
                    {/* Frequência */}
                    <UserFrequencyComponent groups={props.groups} user={props.user} isProfile={props.isProfile} />

                    {/* Next invoice */}
                    {/* { showNextInvoice() } */}
                    <UserInvoicesComponent groups={props.groups} user={props.isProfile ? props.user : user} isProfile={props.isProfile} type="NEXT INVOICE" />
                </div>

                {/* Faturas */}
                {/* { showInvoices() } */}
                <UserInvoicesComponent groups={props.groups} user={props.isProfile ? props.user : user} isProfile={props.isProfile} type="INVOICES" />
            </div>
        </DashboardCreateComponent>
    )
}

export default ConfigurationsUserInfo;
import React from "react";
import { Navigate } from "react-router-dom";
import { PrivateRouteState } from "../@types/route.types";
import { useAuth } from "../context/auth.context";

const PrivateRoute: React.FC<PrivateRouteState> = (props: PrivateRouteState) => {

    const { isLogged } = useAuth()

    const redirectToChildren = () => {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        )
    }

    const redirectChildren = () => {
        // Verificando se o usuário têm permissoes //
        if (props.isAdmin) {
            if (props.groups && props.groups.length > 0) {
                if (props.groups.findIndex(group => group.isAdmin) === -1) {
                    return <></>
                }
            }
        }

        return redirectToChildren()
    }

    // Usuário não está logado, redirecionando-o para tela de login //
    if (!isLogged()) {
        return <Navigate to="/" replace />
    }

    if (!props.isAdmin && isLogged()) {
        return redirectChildren()
    }

    return props.groups ? redirectChildren() : <Navigate to="/" replace />
}

export { PrivateRoute }
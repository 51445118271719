import { Pie } from "@ant-design/plots";
import { PieGraph } from "../../../@types/graphs.types";
import './index.css'

const PieGraphComponent = (props: PieGraph) => {
    
    if (!props.content) return <></>;
    if (!props.content.hasLoaded) return <></>;

    const showGraph = () => {
        if (props.content?.hasError) return <h1>Ocorreu um erro ao buscar os dados do gráfico.</h1>
        if (props.pieData.length < 1) return <h1>Não há dados a serem mostrados.</h1>

        return (
            <Pie
                ref={props.content?.reference}
                appendPadding={10}
                data={props.pieData}
                angleField="value"
                colorField="name"
                radius={0.8}
                legend={{
                    position: "bottom",
                    itemName: { style: { fill: "white", fontSize: 15 } }
                }}
                label={{
                    type: 'spider',
                    labelHeight: 28,
                    content: '{name} ({value})\n{percentage}',
                    style: { fill: "white", fontSize: 12 }
                }}
                theme={{
                    styleSheet: { fontFamily: "Poppins" }
                }}
                interactions={[
                    { type: 'pie-legend-active' },
                    { type: 'element-active' },
                ]}
                color={props.color}
                style={{
                    height: props.pieData ? "34vh" : undefined,
                    padding: props.pieData ? "2vh 0" : undefined
                }}
                className="component-pie-canvas"
            />
        )
    }
    
    const showTotal = () => {
        if (!props.content?.showTotal?.enable) return;
        if (props.pieData.length < 1) return;
        if (props.content.hasError) return;

        const total = props.pieData.reduce((p: any, c: any) => p + c.value, 0)
        return (
            <p>{props.content.showTotal.title + ' ' + total}</p>
        )
    }

    const showTitle = () => {
        if (!props.content?.title) return;
        return (
            <div className="component-graph-title" style={props.content.styleTitle}>
                <h1>{props.content.title}</h1>
            </div>
        )
    }

    const showDescription = () => {
        if (!props.content?.description) return;
        return (
            <div className="component-graph-legend" style={props.content.styleDescription}>
                <p>{props.content.description}</p>
            </div>
        )
    }

    return (
        <div className="component-pie" style={props.content.style}>
            { showTitle() }
            { showDescription() }

            <div className="component-pie-graph">
                { showGraph() }
                { showTotal() }
            </div>
        </div>
    )

}

export default PieGraphComponent;
import { useNavigate } from "react-router-dom"
import { DashboardBackProps } from "../../../@types/dashboard.component.types"

const DashboardBack = (props: DashboardBackProps) => {

    const navigate = useNavigate()

    return (
        <div className="dashboard-back" onClick={() => navigate(props.backUrl, { replace: true })}>
            <p>{ props.text || "< Voltar à página anterior" }</p>
        </div>
    )
}

export default DashboardBack
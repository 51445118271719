import { useNavigate, useParams } from "react-router-dom";
import { Classes, ClassesInfoProps, ClassesSchedules } from "../../../@types/classes.types";
import "../index.css"
import "./index.css"
import { SiGoogleclassroom } from "react-icons/si";
import { useEffect, useState } from "react";
import { useRequestProvider } from "../../../context/request.provider.context";
import { AlertsService } from "../../../services/alerts.service";
import { environment } from "../../../common/environments";
import { formatUserPhoto, getWeekNameById } from "../../../common/utilitaries";
import ButtonComponent from "../../../component/button";
import { Users } from "../../../@types/users.types";
import DashboardBack from "../../dashboard/back";
import Swal from "sweetalert2";
import { RequestsService } from "../../../services/requests.service";
import { RequestsProps } from "../../../@types/request.types";
import { DefaultResponse } from "../../../@types/response.types";
import moment from "moment";
import { FrequencyResponse } from "../../../@types/frequency.types";
import BlockComponent from "../../../component/block";
import PieGraphComponent from "../../../component/graphs/pie";
import { GraphData } from "../../../@types/graphs.types";
import { MdDelete } from "react-icons/md";
import DashboardCreateComponent from "../../../component/dashboard/create";
import { DashboardCreateProps } from "../../../@types/dashboard.component.types";
import { BiListUl } from "react-icons/bi";
import { modalClasse, turmaSubMenus } from "../props";
import { FaEdit, FaWhatsapp } from "react-icons/fa";
import { Groups } from "../../../@types/groups.types";

const TurmasInfo = (props: ClassesInfoProps) => {
    const navigate = useNavigate()
    const params = useParams()

    const { fetchClass, fetchUsers } = useRequestProvider()
    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: "Turmas",
        lateralMenu: "Turmas",
        subMenus: turmaSubMenus()
    }

    const [errors, setErrors] = useState([] as any[])
    const [isFetched, setFetched] = useState(false)
    const [classe, setClass] = useState({} as Classes)
    const [frequencies, setFrequencies] = useState([] as GraphData[])

    const [presences, setPresences] = useState([] as { studentId: number, isPresent: boolean }[])
    const presenceGet = (student: Users) => presences.filter(presence => presence.studentId === student.id)
    const presenceAdd = (student: Users, isPresent: boolean) => setPresences(presences.concat({ studentId: student.id, isPresent }))
    const presenceRemove = (student: Users) => setPresences(presences.filter(presence => presence.studentId !== student.id))

    const formatDaysWeek = (classe: Classes) => {
        // Não existe dados
        if (!classe || classe && !classe.daysOfWeek) return <></>
        // Existe dados
        return (classe.daysOfWeek || "").split(",").map(week => (
            <span className="turmas-content-item-header-weekDay" key={week}>
                { `${getWeekNameById(week)}` }
            </span>
        ))
    }

    const fetchClasse = async () => {
        // Buscando a turma
        const response = await fetchClass(params.id, { 
            withInvoicePaid: true, 
            withSchedules: true,
            withUsersGroups: true
        }).catch(err => {
            AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao buscar a turma cadastrada." })
        })

        setFetched(true)
        if (!response) {
            AlertsService.notification({ icon: "error", title: "Não foi possível encontrar essa turma." })
            setTimeout(() => navigate(environment.ROUTES.TURMAS.HOME, { replace: true }), 800)
            return;
        }

        // Atualizando informações
        setClass((response.data || {}) as Classes)
    }

    const fetchFrequencies = async () => {
        if (!classe) return;

        const findObject: RequestsProps = {
            method: "GET",
            url: `${environment.URLS.CLASSES.PRESENCE.FREQUENCY}${params.id}`
        }

        try {
            const response = await RequestsService.call(findObject)
            const data: FrequencyResponse = response.data

            if (!data.success) {
                return AlertsService.notification({ icon: "error", title: data.message })
            }

            const present = data.data?.filter(frequency => frequency.isPresent)
            const notPresent = data.data?.filter(frequency => !frequency.isPresent)
            setFrequencies([
                { name: "Presenças", value: present?.length || 0 }, 
                { name: "Faltas", value: notPresent?.length || 0 }
            ])
        } catch (err) {
            setErrors(errors.concat("frequencies"))
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao obter a lista de frequências" })
        }
    }

    const messageClasse = async (event: any) => {
        event.preventDefault()

        const messageResult = await AlertsService.confirmation({
            title: "Mensagem para turma",
            html: "Digite a mensagem que será enviada para todos os alunos da turma",
            input: "textarea",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Enviar mensagem",
            cancelButtonText: "Cancelar"
        })

        if (!messageResult.isConfirmed) return

        const message = messageResult.value || ""
        if (message === "") {
            return AlertsService.notification({ title: "Ocorreu um erro ao enviar a mensagem para turma", icon: "error" })
        }

        try {
            const requestObject: RequestsProps = {
                method: "POST",
                url: environment.URLS.CLASSES.MESSAGE + classe.id,
                data: {
                    message
                }
            }

            const request = await RequestsService.call(requestObject)
            if (!request || (request && !request.data)) throw new Error("no response")
            const response: DefaultResponse = request.data
            
            return AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })
        } catch (err: any) {
            const message = err.response?.data?.message || "Ocorreu um erro ao enviar a mensagem para turma"
            return AlertsService.notification({ title: message, icon: "error" })
        }
    }

    const updateClasse = async (event: any) => {
        event.preventDefault()

        const classeData = await modalClasse({
            classe: classe,
            
            nameTitle: "Novo nome da turma",
            nameHtml: "Informe o novo nome da turma a ser criado",
            descriptionTitle: "Nova descrição da  turma",
            descriptionHtml: "Digite a nova descrição da turma a ser criada",
            hourTitle: "Novo horário da turma",
            hourHtml: "Informe o novo horário da turma presencial",
            daysTitle: "Dias da semana",
            daysHtml: "Escolha os dias das semana no qual terá a aula da turma"
        })
        
        if (!classeData) return

        try {
            const requestObject: RequestsProps = {
                method: "PUT",
                url: environment.URLS.CLASSES.UPDATE + classe.id,
                data: {
                    ...classeData
                }
            }
    
            const request = await RequestsService.call(requestObject)
            if (!request || (request && !request.data)) throw new Error("no response")
            const response: DefaultResponse = request.data

            if (response.success) {
                setTimeout(() => navigate(environment.ROUTES.TURMAS.HOME, { replace: true }), 1000)
            }

            return AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })
        } catch (err) {
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao atualizar a turma no banco de dados" })
        }
    }

    const removeClasse = async (event: any) => {
        event.preventDefault()

        const result = await AlertsService.confirmation({
            title: "Remover turma",
            html: `Você tem certeza que deseja remover a turma <strong>${classe.name}</strong>. Essa ação é irreversível.`,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        })

        if (!result.isConfirmed) return

        try {
            const request = await RequestsService.call({
                method: "DELETE",
                url: environment.URLS.CLASSES.DELETE + classe.id,
                params: {
                    removePermanently: true
                }
            })

            if (!request || request && !request.data) throw new Error("No response found")
            const response: DefaultResponse = request.data

            if (response.success) {
                setTimeout(() => navigate(environment.ROUTES.TURMAS.HOME, { replace: true }), 1500)
            }

            return AlertsService.notification({ icon: response.success ? "success" : "error", title: response.message })
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || err.message || "Ocorreu um erro ao deletar a turma" })
        }
    }

    const presence = async (event: any, student: Users): Promise<any> => {
        event.preventDefault()

        // Verificando se já foi adicionado uma presença para esse estudante
        const presenceList = presenceGet(student)
        if (presenceList != undefined && presenceList.length > 0) {
            const type = presenceList[0].isPresent ? "Presença" : "Falta"
            return AlertsService.notification({ icon: "warning", html: `Já foi adicionado uma '<strong>${type}</strong>' para esse estudante.` })
        }

        const result = await AlertsService.confirmation({
            title: "Adicionar presença para esse aluno?",
            input: "select",
            inputOptions: {
                true: "Presente",
                false: "Faltou"
            },
            html: `Escolha abaixo se o aluno faltou ou compareceu à aula.`,
            confirmButtonText: "Sim, adicionar.",
            cancelButtonText: "Cancelar",
        })

        if (!result.isConfirmed) return;

        const requestObject: RequestsProps = {
            method: "POST",
            url: `${environment.URLS.CLASSES.PRESENCE.ADD}${classe.id}`,
            data: {
                userId: student.id,
                isPresent: result.value,
            }
        }

        const message = "Já foi criado uma presença para esse usuário"
        try {
            const response = await RequestsService.call(requestObject)
            const data: DefaultResponse = response.data

            // Adicionando presença
            if (data.success) {
                presenceAdd(student, result.value)
            }

            return AlertsService.notification({ icon: !data.success ? "warning" : "success", title: data.message })
        } catch (err: any) {
            const { data } = err.response

            if (data.message && data.message == message) {
                presenceAdd(student, data.data.isPresent)
                return AlertsService.notification({ icon: "warning", title: data.message })
            }

            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao adicionar a presença para o usuário" })
        }
    }

    const removePresence = async (event: any, student: Users): Promise<any> => {
        event.preventDefault()

        const result = await AlertsService.confirmation({
            title: "Remover presença desse aluno?",
            html: `Você estará removendo a presença desse aluno.`,
            confirmButtonText: "Sim, remover.",
            cancelButtonText: "Cancelar",
        })

        if (!result.isConfirmed) return;

        const requestObject: RequestsProps = {
            method: "DELETE",
            url: `${environment.URLS.CLASSES.PRESENCE.REMOVE}${classe.id}`,
            data: {
                userId: student.id,
            }
        }

        try {
            const response = await RequestsService.call(requestObject)
            const data: DefaultResponse = response.data

            // Removendo presença
            presenceRemove(student)

            return AlertsService.notification({ icon: !data.success ? "warning" : "success", title: data.message })
        } catch (err: any) {
            const { data } = err.response
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao remover a presença do usuário" })
        }
    }

    const showStudents = () => {
        if (!classe.users) return <></>

        const showPresence = (student: Users) => {
            const presencesList = presenceGet(student)
            const options = {
                text: "Presença",
                onClick: (e: any, user: Users) => presence(e, user),
                class: "turma-content-student-actions-button"
            } 

            if (presencesList != undefined && presencesList.length > 0) {
                options.text = "Retirar presença"
                options.onClick = (e: any, user: Users) => removePresence(e, user)
                options.class = "turma-content-student-actions-button-remove"
            }

            return <ButtonComponent 
                text={options.text} 
                type="button" 
                class={options.class}
                onClick={(e) => options.onClick(e, student)}
            />
        }

        return classe.users.sort((a: Users, b: Users) => {
                if (a.name < b.name) return 1
                if (a.name > b.name) return -1
                return 0
            })
            .map(student => {
                const isAdmin = student.groups?.find((group: Groups) => group.isAdmin)
                const invoices = student.invoices
                const message = isAdmin ? "Usuário administrador do sistema" : ""
                const invoicePaidMessage = student.invoicePaid ? "Fatura paga" : "Aguardando pagamento"
                const nextDue = invoices.length > 0 ? 
                    `Próximo vencimento: ${moment(invoices[0].next_expire_at).format("DD/MM/YYYY")}` : 
                    ""

                return {
                    ...student,
                    isAdmin: isAdmin != undefined,
                    invoicePaidMessage: isAdmin ? "" : invoicePaidMessage,
                    nextDue: isAdmin ? "" : nextDue,
                    message,
                }
            })
            .map(student => (
                <div className="turma-content-student" key={`#${student.id}`}>
                    <div className="turma-content-student-content">
                        <div className="turma-content-student-content-icon">
                            <img src={formatUserPhoto(student)} alt="new"/>
                        </div>

                        <div className="turma-content-student-content-info">
                            <p>{ student.name }</p>
                            <span>{ student.invoicePaidMessage }</span>
                            <span>{ student.nextDue }</span>
                            <span>{ student.message }</span>
                        </div>
                    </div>

                    <div className="turma-content-student-actions">
                        { student.isAdmin ? <></> : showPresence(student) }
                        
                        { student.isAdmin ? <></> : <ButtonComponent 
                            text="Remover aluno"
                            type="button" 
                            class="turma-content-student-actions-button"
                            onClick={(e) => removeStudent(e, student)}
                        /> }
                    </div>
                </div>
            ))
    }

    const showSchedules = () => {
        if (!classe.schedules) return <></>
        if (classe.schedules.length < 1) return <p>Não há agendamentos futuros!</p>       

        const updateConfirm = async (event: any, schedule: ClassesSchedules, isRemove: boolean) => {
            event.preventDefault()

            try {
                if (isRemove) {
                    const result = await AlertsService.confirmation({
                        title: "Remover agendamento?",
                        html: "Ao confirmar, você removerá o agendamento desse usuário na aula.",
                    })
    
                    if (!result.isConfirmed) return;
                    const response = await RequestsService.call({
                        method: "DELETE",
                        url: environment.URLS.CLASSES.SCHEDULE_REMOVE + schedule.id
                    })
    
                    const data: DefaultResponse = response.data
                    if (!data.success) {
                        return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao remover o agendamento" })
                    }
    
                    await fetchClasse()
    
                    return AlertsService.notification({ icon: 'success', title: "Agendamento excluído com sucesso" })
                } else {
                    let response;
                    if (schedule.isConfirmed) {
                        const result = await AlertsService.confirmation({
                            title: "Retirar confirmação?",
                            html: "Ao confirmar, você retirará a confirmação do agendamento desse usuário na aula.",
                        })
        
                        if (!result.isConfirmed) return;
                        // AXIOS
                        response = await RequestsService.call({
                            method: "PUT",
                            url: environment.URLS.CLASSES.SCHEDULE_UPDATE + schedule.id,
                            data: {
                                isConfirmed: false
                            }
                        })
                    } else {
                        const result = await AlertsService.confirmation({
                            title: "Confirmar agendamento?",
                            html: "Se sim, você estará confirmando que recebeu o aviso de agendamento de aula para o aluno em questão.",
                        })
        
                        if (!result.isConfirmed) return;
                        // AXIOS
                        response = await RequestsService.call({
                            method: "PUT",
                            url: environment.URLS.CLASSES.SCHEDULE_UPDATE + schedule.id,
                            data: {
                                isConfirmed: true
                            }
                        })
                    }

                    const data: DefaultResponse = response.data
                    if (!data.success) {
                        return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao atualizar o agendamento" })
                    }
    
                    setFetched(false)
                    await fetchClasse()
    
                    return AlertsService.notification({ icon: 'success', title: "Agendamento atualizado com sucesso" })
                }
            } catch (err: any) {
                console.error(err)
                return AlertsService.notification({ icon: "warning", title: "Ocorreu um erro inesperado." })
            }
        }

        return classe.schedules.map((schedule: ClassesSchedules) => (
            <div key={schedule.id} className="turma-content-schedule">
                <div className="turma-content-schedule-titles">
                    <h3>{schedule.user.name}</h3>
                    <p>
                        { `Agendado para ` }
                        <strong>
                            {moment(schedule.scheduled_at, "YYYY-MM-DD").format("DD/MM/YYYY")}
                        </strong>
                    </p>
                    <p>Confirmado: 
                        <strong>
                            { schedule.isConfirmed ? " Sim" : " Não" }
                        </strong>
                    </p>
                </div>

                <div className="turma-content-schedule-buttons">
                    <ButtonComponent
                        text={ schedule.isConfirmed ? "Confirmado" : "Confirmar" }
                        type="button"
                        class="turma-content-schedule-button"
                        style={{
                            backgroundColor: schedule.isConfirmed ? "aqua" : "green", 
                            color: schedule.isConfirmed ? "black" : "white"
                        }}
                        onClick={(e) => updateConfirm(e, schedule, false)}
                    />

                    <ButtonComponent
                        text="Deletar"
                        type="button"
                        class="turma-content-schedule-button"
                        style={{backgroundColor: "red"}}
                        onClick={(e) => updateConfirm(e, schedule, true)}
                    />
                </div>
            </div>
        ))
    }

    const addStudent = async (event?: any): Promise<any> => {
        if (event) event.preventDefault()

        const userResult = await AlertsService.confirmation({
            title: `Informe o nome do usuário`,
            input: "text",
            icon: "question",
            showLoaderOnConfirm: true,
            confirmButtonText: "Buscar",
            cancelButtonText: "Cancelar",
            preConfirm: async (name) => {
                try {
                    const users = await fetchUsers({ name })
                    if (!users) {
                        throw new Error("Não foi possível buscar os usuários")
                    }

                    return users.success ? users.data : undefined
                } catch (err) {
                    Swal.showValidationMessage(`Ocorreu um erro: ${err}`)
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })

        if (!userResult.isConfirmed || !userResult.value) return
        // Não retornou nada
        if (userResult.value && userResult.value.length < 1) {
            const resultBack = await AlertsService.confirmation({
                title: `A busca não retornou resultados.`,
                icon: "question",
                confirmButtonText: "Voltar",
                cancelButtonText: "Cancelar busca",
            })

            if (!resultBack.isConfirmed) return
            return await addStudent()
        }

        const inputOptions: any = {}
        userResult.value.forEach((user: Users) => {
            if (user) {
                inputOptions[user.id] = user.name
            }
        })

        const result = await AlertsService.confirmation({
            title: "Selecione um usuário",
            input: "select",
            inputOptions,
            confirmButtonText: "Adicionar usuário",
            cancelButtonText: "Cancelar operação"
        })

        if (!result.isConfirmed || !result.value) return;

        // Adicionar usuário
        const requestObject: RequestsProps = {
            method: "POST",
            url: environment.URLS.USER.ADD_CLASSE,
            data: {
                new_classes: [classe.id],
                remove_classes: [],
                user_id: result.value
            }
        }

        const response = await RequestsService.call(requestObject).catch(err => {
            AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao adicionar a turma ao usuário" })
        })

        if (response.status != 200) return;
        const data: DefaultResponse = response.data

        try {
            if (!data.success) AlertsService.notification({ icon: "error", title: data.message })
            else AlertsService.notification({ icon: "success", title: "Aluno adicionado com sucesso a turma." })

            setFetched(false)
        } finally {
            await fetchClasse()
        }
    }

    const removeStudent = async (event: any, student: Users) => {
        event.preventDefault()

        const result = await AlertsService.confirmation({
            title: "Remover usuário da turma?",
            html: `Você estará removendo o(a) <strong>${student.name}</strong> da turma.`,
            confirmButtonText: "Sim, remover.",
            cancelButtonText: "Não"
        })

        if (!result.isConfirmed) return;

        const requestObject: RequestsProps = {
            method: "DELETE",
            url: `${environment.URLS.CLASSES.REMOVE_USER}${classe.id}`,
            data: {
                userId: student.id,
            }
        }

        try {
            const response = await RequestsService.call(requestObject)
            const data: DefaultResponse = response.data

            if (data.success) {
                setFetched(false)
                fetchClasse()
                return AlertsService.notification({ icon: "success", title: "Usuário removido com sucesso!" })
            }

            return AlertsService.notification({ icon: "error", title: data.message })
        } catch (err) {
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao remover o usuário" })
        }
    }

    useEffect(() => {
        // Já foi buscado?
        if (isFetched) return
        // Timeout
        const timeout = setTimeout(async () => {
            try {
                // Verificando se já foi buscado as turmas
                if (isFetched) return

                await Promise.all([
                    fetchClasse(),
                    fetchFrequencies(),
                ])
            } catch (e) {
                AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao buscar a turma cadastrada." })
            }
        }, 0)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>

            <DashboardBack backUrl={ environment.ROUTES.TURMAS.HOME } />

            <div className="turma-content-list">

                <div className="turma-content">
                    <div className="turma-content-header-actions">
                        <ButtonComponent 
                            text="Mensagem para Turma"
                            type="button"
                            class="turma-content-header-actions-button-message"
                            onClick={messageClasse}
                        />

                        <ButtonComponent 
                            text="Editar turma"
                            type="button"
                            class="turma-content-header-actions-button-update"
                            onClick={updateClasse}
                        />

                        <ButtonComponent 
                            class="turma-content-header-actions-button-delete"
                            type="button" 
                            text={(<MdDelete />)} 
                            onClick={removeClasse} 
                        />
                    </div>

                    <div className="turma-content-header">
                        <div className="turma-content-header-icon">
                            <SiGoogleclassroom />
                        </div>

                        <div className="turma-content-title">
                            <p><strong>{ !isFetched ? "..." : classe.name }</strong></p>
                        </div>
                    </div>

                    <div className="turma-content-info">
                        <p><strong>Descrição:</strong> { !isFetched ? "..." : classe.description }</p>
                        <br />
                        <p><strong>Alunos:</strong> { !isFetched ? "..." : classe.users?.length } aluno(s)</p>
                        <p><strong>Dias:</strong> { !isFetched ? "..." : formatDaysWeek(classe) }</p>
                        <p><strong>Horário:</strong> { !isFetched ? "..." : classe.hours }</p>
                    </div>
                </div>

                <div className="turma-content-students">
                    <div className="turma-content-students-title">
                        <h2>Alunos</h2>

                        <ButtonComponent
                            text="+"
                            type="button"
                            class="turma-content-students-button"
                            onClick={addStudent}
                        />
                    </div>

                    <div className="turma-content-students-list">
                        { /* Students */ }
                        { !isFetched ? "Buscando informações..." : showStudents() }
                    </div>
                </div>

                <div className="turma-content-block">
                    <div className="turma-content-frequency">
                        <BlockComponent title="Frequência da turma" legend="Frequência da turma nos últimos 3 meses">
                            <PieGraphComponent
                                pieData={frequencies}
                                color={["#179917", "#a50200"]}
                                content={{
                                    hasLoaded: true,
                                    hasError: errors.filter(name => name == "frequencies") ? errors.filter(name => name == "frequencies")[0] : false,
                                    showTotal: {
                                        enable: true,
                                        title: "Total geral:"
                                    }
                                }}
                            />
                        </BlockComponent>
                    </div>

                    <div className="turma-content-schedule-block">
                        <BlockComponent title="Lista de agendamentos" legend="Lista de agendamentos futuros da turma">
                            <div className="turma-content-schedules">
                                { !isFetched ? "Buscando informações..." : showSchedules() }
                            </div>
                        </BlockComponent>
                    </div>
                </div>
            </div>


        </DashboardCreateComponent>
    )
}

export default TurmasInfo;
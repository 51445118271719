export const environment = {
    VERSION: process.env.REACT_APP_VERSION || "develop-base-0.0.1",

    SERVER: {
        PORT: process.env.REACT_APP_PORT || 3000,
        BASEURL: process.env.REACT_APP_BASEURL || "http://localhost:3001/api",
        DEFAULT_PASSWORD: process.env.REACT_APP_DEFAULTPASSWORD || "localhost123@321",

        TIMEOUT: {
            FETCH_USERS: Number(process.env.REACT_APP_FETCHUSERS || 1000),
            FETCH_CLASSES: Number(process.env.REACT_APP_FETCHCASSES || 1000),
            FETCH_QR_CODE: Number(process.env.REACT_APP_FETCHQRCODE || 10000),
            FETCH_MODULES: Number(process.env.REACT_APP_FETCHMODULES || 1000),
        }
    },

    ROUTES: {
        DASHBOARD: "/dashboard",
        MY_PROFILE: "/meu-perfil",
        TURMAS: {
            HOME: "/classes",
            BY_ID: "/classes/:id"
        },
        MODULES: {
            MY_MODULES: "/my-modules",
            MY_MODULES_BY_ID: "/my-modules/:id",
            HOME: "/modules",
            BY_ID: "/modules/:id"
        },
        NOTIFICATIONS: "/notifications",
        CONFIGURATIONS: {
            HOME: "/configurations",
            USER: {
                CREATE: "/configurations/users/create",
                LIST: "/configurations/users",
                BY_ID: "/configurations/users/profile/:id",
                UPDATE_PERFIL: "/configurations/update/:id",
                UPDATE_ADMIN: "/configurations/users/update/:id",
            },
            PLANS: {
                LIST: "/configurations/plans",
                BY_ID: "/configurations/plan/:id",
                CREATE: "/configurations/create/plan",
            }
        }
    },

    URLS: {
        // BASE //
        BASE: {
            NEWS: process.env.REACT_APP_BASE_NEWS_URL || process.env.REACT_APP_BASEURL,
            ARCHIVE: process.env.REACT_APP_ARCHIVE_NEWS_URL || process.env.REACT_APP_BASEURL
        },

        // USER
        USER: {
            CREATE: "/user/create",
            CREATE_ADMIN: "/user/create/admin",
            LOGIN: "/user/authenticate",
            ADD_CLASSE: "/user/classe",
            INFO: "/user",
            BY_ID: "/user/",
            LIST: "/users",
            DEL: "/user/",
            UPDATE: "/user",
            RESTORE: "/users/restore/",
            GENERATE_PASSWORD_RECOVERY: "/user/generate-password-recovery",
            PASSWORD_RECOVERY: "/user/password-recovery"
        },

        // CLASSES
        CLASSES: {
            CREATE: "/classe/create",
            SCHEDULE_ADD: "/classes/schedule",
            LIST: "/classes",
            ME: "/classes/me",
            BY_ID: "/classe/",
            PRESENCE: {
                ADD: "/classes/presence/",
                REMOVE: "/classes/presence/",
                FREQUENCY: "/classes/frequency/"
            },
            UPDATE: "/classe/",
            SCHEDULE_UPDATE: "/classes/schedule/",
            DELETE: "/classe/",
            REMOVE_USER: "/classe/removeUser/",
            SCHEDULE_REMOVE: "/classes/schedule/",
            MESSAGE: "/classes/message/",
        },

        // FREQUENCY //
        FREQUENCY: {
            ALL: "/frequencies",
            ALL_USER: "/frequencies/user",
            ALL_USER_ID: "/frequencies/user/"
        },

        // INVOICES //
        INVOICES: {
            ALL: "/invoices",
            ALL_USER: "/invoices/user",
            ALL_USER_ID: "/invoices/user/",
            UPDATE: "/invoice/"
        },

        // PLANS //
        PLANS: {
            ALL: "/plans",
            CREATE: "/plan",
            UPDATE: "/plan/",
            DELETE: "/plan/",
        },

        // MODULES //
        MODULES: {
            ALL: "/modules",
            ME: "/modules/me",
            CREATE: "/modules/create",
            BY_ID: "/module/",
            ADD_USER: "/modules/add",
            REMOVE_USER: "/modules/remove",
            DELETE: "/module/",
            UPDATE: "/module/",
            REMOVE_CLASSE: "/modules/removeClasse",

            CLASE: {
                ADD: "/clase/add",
                UPDATE: "/clase/",
                DELETE: "/clase/"
            }
        },

        // REPORTS //
        REPORTS: {
            USERS: "/reports/users",
            INVOICES: "/reports/invoices",
            MODULES_FREQUENCY: "/reports/modules/frequencies"
        },

        // NOTIFICATIONS
        NOTIFICATIONS: {
            LIST: "/notifications",
            LIST_QUEUE: "/notifications/queue",
            SESSION_QRCODE: "/notifications/session/qrcode",
            SESSION_CONNECTED: "/notifications/session/connected",

            SESSION: "/notifications/session",
            CREATE: "/notifications/create",
            SEND_MESSAGE: "/notifications/sendMessage",
            SEND_FILE: "/notifications/sendFile",
        }
    },

    INVOICES: {
        STATUS: {
            WAITING_GENERATION: "AGUARDANDO GERAÇÃO",
            EXPIRED_CHARGE: "COBRANÇA EXPIRADA",
            CHARGE_PAID: "FATURA PAGA",
            WAITING_PAYMENT: "AGUARDANDO PAGAMENTO"
        }
    }
}
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2'

/**
 * @name notification - Cria uma notificação
 * 
 * @param options 
 * 
 * @returns Function
 */
const notification = (options: SweetAlertOptions) => {
    options.toast = true
    options.showConfirmButton = false
    options.timer = !options.timer ? 3000 : options.timer
    options.position = "top-end"

    return Swal.fire(options)
}

/**
 * @name confirmation - Cria um pop-up de confirmação
 * 
 * @param options 
 * @returns 
 */
const confirmation =  (options: SweetAlertOptions): Promise<SweetAlertResult> => {
    options.icon = options.icon || 'warning'
    options.confirmButtonColor = '#3085d6';
    options.cancelButtonColor = '#d33';
    options.confirmButtonText = options.confirmButtonText || 'Sim'
    options.cancelButtonText = options.cancelButtonText || "Não"
    if (options.showCancelButton) options.showCancelButton = true

    return Swal.fire(options)
}

export const AlertsService = {
    notification,
    confirmation
}
import { useState } from "react"
import { MenuPrivateProps } from "../../@types/component.types"
import { DashboardCreateProps } from "../../@types/dashboard.component.types"
import DashboardCreateComponent from "../../component/dashboard/create"
import { myModulesSubMenus } from "./props"
import { ModulesStaticsInterface, MyModules } from "../../@types/modules.types"
import "./index.css"
import ButtonComponent from "../../component/button"
import FilterComponent from "../../component/filter"
import { AlertsService } from "../../services/alerts.service"

const MyModulesFilterComponent = () => {

    const [filtro, setFiltro] = useState(new Map<any, any>())
    const updateFilter = (key: any, value: any) => setFiltro(filtro.set(key, value))

    /**
     * @name changeFiltro - Modifica valores do filtro 
     * 
     * @param event 
     */
    const changeFilter = (event: any) => {
        // Removendo o evento default
        event.preventDefault()
        // Pegando o target
        const { target } = event
        // Pegando o id e o valor
        const { id, value } = target
        // Atualizando filtro
        updateFilter(id, value)
    }

    /**
     * @name resetFilter - Reseta o filtro
     */
    const resetFilter = (event: any) => {
        // Removendo o evento default
        event.preventDefault()
        // Verificando se o filtro está com valores
        if (filtro.size < 1) return AlertsService.notification({ icon: "warning", title: "O filtro já está em seu padrão." })
        // Resetando filtro
        setFiltro(new Map<any, any>())
        // Imprimindo mensagem de sucesso
        AlertsService.notification({ icon: "success", title: "Filtro resetado com sucesso" })
    }

    return (
        <div className="modules-contents-filter">
            {/* Nome do módulo */} 
            <FilterComponent
                type="text"
                id="moduleName"
                name="moduleName"
                placeholder="Informe o nome do módulo"
                onChange={changeFilter}
                style={{
                    padding: ".25rem .35rem",
                    fontSize: "14px"
                }}
            />

            {/* Buscar */}
            <ButtonComponent 
                text="Buscar"
                type="submit"
                class="modules-contents-filter-button"
            />

            {/* Resetar */}
            <ButtonComponent 
                text="Resetar"
                type="reset"
                class="modules-contents-filter-button modules-contents-filter-button-reset"
                onClick={resetFilter}
            />
        </div>
    )
}

export default MyModulesFilterComponent
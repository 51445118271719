import { BlockProps } from "../../@types/component.types"
import "./block.css"

const BlockComponent = (props: BlockProps) => {

    const showTitle = () => {
        if (!props.title) return
        return (
            <div className="block-component-title">
                <h1>{ props.title || "Nenhum titulo informado" }</h1>
            </div>
        )
    }
    
    const showLegend = () => {
        if (!props.legend) return
        return (
            <div className="block-component-legend">
                <p>{ props.legend }</p>
            </div>
        )
    } 

    const className = `block-component ${props.className || ""}`

    return (
        <div className={className} style={props.style}>
            {showTitle()}
            {showLegend()}
            
            <div className="block-component-content">
                { props.children }
            </div>
        </div>
    )
}

export default BlockComponent
import './index.css'

import { SubItemMenuComponentProps } from '../../../@types/menu.component.types'
import { useNavigate } from 'react-router-dom'

const SubItemComponent = (props: SubItemMenuComponentProps) => {

    const navigator = useNavigate()
    const isRoute = props.name === props.route
    const className = `subitem-component${ isRoute ? " subitem-component-transparent" : "" }`

    // Verificando se o sub menu é para administradores
    if (props.isAdmin && props.groups) {
        // Verificando os grupos do usuário
        const findGroup = props.groups.findIndex(group => group.isAdmin)
        // Não achou o grupo administrador
        if (findGroup == -1) {
            return <></>
        }
    }

    const buildIcon = () => {
        if (!props.hasIcon) return
        return ( <span>{props.icon}</span> )
    }

    const buildRoute = () => {
        if (!isRoute) return
        return ( <div className="subitem-component-line" /> );
    }

    const buildOnClick = (e: any) => {
        return props.onClick ? props.onClick(e) : navigator(props.redirectTo || "", { replace: true })
    }

    return (
        <div className={className} onClick={ buildOnClick } key={`#${props.name}-subitem`}>
            {/* icon */}
            { buildIcon() }
            
            <p>{props.name}</p>

            {/* Line */}
            { buildRoute() }
        </div>
    )

}

export default SubItemComponent
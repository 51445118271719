import React from "react"
import MenuComponent from "../../../component/menu"
import { DashboardHeaderProps } from "../../../@types/dashboard.component.types"

const DashboardHeader = (props: DashboardHeaderProps) => {

    const buildMenu = () => {
        if (!props.menu || props.menu && !props.menu.active) return
        return ( <MenuComponent 
            name={props.menu.name} 
            groups={props.groups} 
            user={props.user} 
            key={`#${props.menu.name}-menu`} /> 
        )
    }

    return (
        <div className="dashboard-header">
            {/* Menu */}
            { buildMenu() }

            {/* Sub Menu */}
            <div className="dashboard-sub-menus">
                {props.children}
            </div>
        </div>
    )

}

export default DashboardHeader;
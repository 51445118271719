import { useNavigate } from "react-router-dom";
import { UserCreateInterface } from "../../../../@types/users.types";
import { environment } from "../../../../common/environments";
import BlockComponent from "../../../../component/block";
import DashboardBack from "../../../dashboard/back";
import { CSSProperties, ChangeEventHandler, HTMLInputTypeAttribute, useEffect, useState } from "react";
import InputComponent from "../../../../component/input";
import ButtonComponent from "../../../../component/button";
import moment from "moment";
import './index.css'
import { AlertsService } from "../../../../services/alerts.service";
import { Plans, PlansResponse } from "../../../../@types/plans.types";
import { RequestsProps } from "../../../../@types/request.types";
import { RequestsService } from "../../../../services/requests.service";
import { DefaultResponse } from "../../../../@types/response.types";
import DashboardCreateComponent from "../../../../component/dashboard/create";
import { DashboardCreateProps } from "../../../../@types/dashboard.component.types";
import { configurationsSubMenus } from "../../props";
import { formatPlanPrice } from "../../../../common/utilitaries";

const UserCreateComponent = (props: UserCreateInterface) => {

    const navigate = useNavigate()
    const [data, setData] = useState(new Map<any, any>())
    const [file, setFile] = useState(null)
    const update = (event: any, name: string) => {
        const value = event.target.value
        const files = event.target.files || []
        if (files.length > 0) {
            const file = files[0]
            return setFile(file)
        }

        if (value.length < 1 || value == undefined) return setData(data.set(name, undefined))
        setData(data.set(name, event.target.value))
    }

    const [plans, setPlans] = useState([] as Plans[])
    
    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: "Usuários",
        lateralMenu: "Configurações",
        subMenus: configurationsSubMenus()
    }

    const makeInput = (options: { 
        name?: string;
        id?: string;
        type: HTMLInputTypeAttribute, 
        withStyle?: boolean;
        isNecessary?: boolean;
        labelName?: string, 
        labelDirection?: "right" | "left"
        labelStyle?: CSSProperties
        placeholder?: string, 
        onChange: ChangeEventHandler<HTMLInputElement> 
    }) => {
        return (
            <InputComponent
                id={options.id}
                name={options.name}
                type={options.type}
                hasLabel={ options.labelName != undefined }
                label={options.labelName}
                placeholder={options.placeholder}
                onChange={options.onChange}
                labelStyle={options.labelStyle || { fontSize: "1rem", margin: "0" }}
                labelDirection={options.labelDirection || "left"}
                style={ options.withStyle ? { fontSize: "1rem", width: "100%", marginBottom: "1vh", borderRadius: "5px" } : undefined }
                isNecessary={options.isNecessary}
            />
        )
    }

    const createUser = async (event: any) => {
        event.preventDefault()

        const birthdayIsCorrect = (birthday: any) => {
            try {
                const split = birthday.split("/")
                const day = split[0]
                const month = split[1]
                const year = split[2]
                if (day.length < 2 || month.length < 2 || year.length < 4) throw new Error("Not formated")
                return true
            } catch {
                return false
            }
        }

        if (data.size < 1) {
            return AlertsService.notification({ icon: "error", title: "Você não preencheu o formulário corretamente!" })
        }

        const stringfy = JSON.stringify(Object.fromEntries(data))
        const json = JSON.parse(stringfy)

        const needData = [
            "name",
            "email",
            "password1",
            "password2",
            "rgi",
            "phone",
            "groups",
        ]

        const notIncluded = needData.filter(data => json[data] == undefined ? true : false)
        if (notIncluded.length > 0) {
            return AlertsService.notification({ icon: "error", title: "O campos com * são obrigatórios." })
        }
        
        const { password1, password2 } = json
        if (password1 != password2) {
            return AlertsService.notification({ icon: "error", title: "As senhas devem ser iguais." })
        }

        if (json.groups.length < 1) {
            return AlertsService.notification({ icon: "error", title: "É necessário selecionar um grupo!" })
        }

        const hasUser = json.groups.filter((group: any) => group == 1)
        if (hasUser.length > 0) {
            const plan_id = json.plan_id
            if (!plan_id) {
                return AlertsService.notification({ icon: "error", title: "É necessário selecionar um plano para esse usuário de grupo 'usuário'" })
            }
        }

        if (json.birthday && !birthdayIsCorrect(json.birthday)) {
            return AlertsService.notification({ icon: "error", title: "A data de aniversário tem que ser no formato 00/00/0000" })
        }

        const object: any = {
            name: json.name,
            email: json.email,
            password: json.password1,
            rgi: json.rgi,
            phone: json.phone,
            photo: file,
            birthday: json.birthday,
            facebook: json.facebook,
            instagram: json.instagram,
            xwitter: json.xwitter,
            plan_id: json.plan_id,
            groups: json.groups
        }

        try {
            const formData = new FormData()
            for (var key in object) {
                formData.append(key, object[key])
            }

            const requestObject: RequestsProps = {
                method: "POST",
                url: environment.URLS.USER.CREATE_ADMIN,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const response = await RequestsService.call(requestObject)
            if (!response) throw new Error("Nenhum response")

            const data: DefaultResponse = response.data
            if (!data.success) {
                throw new Error(data.message)
            }

            AlertsService.notification({ icon: "success", title: "Novo usuário criado com sucesso!" })
            setTimeout(() => navigate(environment.ROUTES.CONFIGURATIONS.USER.LIST, { replace: true }), 1500)
        } catch (err: any) {
            const response = err.response
            const data = response.data
            return AlertsService.notification({ icon: "error", title: data.message || "Ocorreu um erro ao criar o novo usuário." })
        }
    }

    const showGroups = () => {
        const insertGroup = (groupId: number) => {
            const groups = data.get("groups") || []
            if (groups.length > 0) {
                const hasUser = groups.filter((group: any) => group == groupId)
                if (hasUser.length > 0) {
                    setData(data.set("groups", groups.filter((group: any) => group != groupId)))
                } else {
                    setData(data.set("groups", groups.concat(groupId)))
                }
            } else {
                setData(data.set("groups", groups.concat(groupId)))
            }
        }

        return (
            <div className="user-create-component-groups">
                <h3>Selecione o grupo do usuário: <span>*</span></h3>
                
                { makeInput({ 
                    type: "checkbox", 
                    labelName: "Usuário",
                    onChange: () => insertGroup(2),
                    labelDirection: "right",
                    labelStyle: { fontSize: "1rem", margin: "0", marginLeft: ".5vh" }
                })}

                { makeInput({ 
                    type: "checkbox", 
                    labelName: "Administrador",
                    onChange: () => insertGroup(1),
                    labelDirection: "right",
                    labelStyle: { fontSize: "1rem", margin: "0", marginLeft: ".5vh" }
                })}
            </div>
        )
    }

    const showPlans = () => {
        const createInput = (plan: Plans) => {
            return makeInput({
                name: "plan",
                type: "radio",
                labelName: plan.name + " (R$" + formatPlanPrice(plan.price.toLocaleString('pt-br')) + ")",
                onChange: () => setData(data.set("plan_id", plan.id)),
                labelDirection: "right",
                labelStyle: { fontSize: "1rem", margin: "0", marginLeft: ".5vh" }
            })
        }

        return (
            <div className="user-create-component-plans" style={{ marginTop: "1vh" }}>
                <h3>Selecione o plano do usuário:</h3>
                { plans.map((plan) => createInput(plan))}
            </div>
        )
    }

    const searchPlans = async () => {
        if (plans.length > 0) return;

        try {
            const requestObject: RequestsProps = {
                method: "GET",
                url: environment.URLS.PLANS.ALL
            }

            const response = await RequestsService.call(requestObject)
            if (!response) throw new Error("Nenhum response")

            const data: PlansResponse = response.data
            if (!data.success) {
                throw new Error(data.message)
            }

            setPlans(data.data || [])
        } catch (err) {
            return AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao obter os planos cadastrados." })
        }
    }

    useEffect(() => {
        const timeout = setTimeout(async () => {
            await searchPlans()
        }, 0)

        setTimeout(() => {
            const price = formatPlanPrice(String("129,90").toLocaleString())
        }, 1500)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>
            <DashboardBack backUrl={`${environment.ROUTES.CONFIGURATIONS.USER.LIST}`} />

            <div className="user-update-block">
                <BlockComponent title="Criar novo usuário" legend="Criação de um novo usuário no sistema" style={{ paddingBottom: "2vh" }}>
                    <div className="user-create-component">
                        { makeInput({ 
                            type: "text", 
                            isNecessary: true, 
                            labelName: "Nome completo", 
                            placeholder: "Nome completo", 
                            onChange: (e: any) => update(e, "name"), 
                            withStyle: true 
                        }) }
                        
                        { makeInput({ 
                            type: "email", 
                            isNecessary: true, 
                            labelName: "E-mail", 
                            placeholder: "Email do usuário", 
                            onChange: (e: any) => update(e, "email"), 
                            withStyle: true 
                        }) }
                        
                        { !props.isAdmin ? makeInput({ type: "password", isNecessary: true, labelName: "Senha antiga", onChange: (e: any) => update(e, "old_password"), withStyle: true }) : undefined }
                        
                        { makeInput({ type: "password", isNecessary: true, labelName: "Senha", onChange: (e: any) => update(e, "password1"), withStyle: true }) }
                        
                        { makeInput({ type: "password", isNecessary: true, labelName: "Repita a senha", onChange: (e: any) => update(e, "password2"), withStyle: true }) }
                        
                        { makeInput({ type: "text", isNecessary: true, labelName: "Registro geral de identificação (RGI)", placeholder: "CPF/CNPJ", onChange: (e: any) => update(e, "rgi"), withStyle: true }) }
                        
                        { makeInput({ type: "number", isNecessary: true, labelName: "Telefone", placeholder: "(99) 9 9999-9999", onChange: (e: any) => update(e, "phone"), withStyle: true }) }
                        
                        { makeInput({ type: "file", labelName: "Foto de perfil", onChange: (e: any) => update(e, "photo"), withStyle: true }) }
                        
                        { makeInput({ type: "text", labelName: "Data de aniversário", placeholder: moment("01/01/2000").format("DD/MM/YYYY"), onChange: (e: any) => update(e, "birthday"), withStyle: true }) }
                        
                        { makeInput({ type: "text", labelName: "Instagram", placeholder: "https://instagram.com/", onChange: (e: any) => update(e, "instagram"), withStyle: true }) }
                        
                        { showGroups() }
                        
                        { showPlans() }

                        <div className="user-create-component-button">
                            <ButtonComponent
                                text="Criar novo usuário"
                                type="button"
                                onClick={createUser}
                            />
                        </div>
                    </div>
                </BlockComponent>
            </div>
        </DashboardCreateComponent>
    )
}

export default UserCreateComponent;
import "./index.css"
import { useEffect, useState } from "react"
import { ModulesInterface } from "../../@types/component.types"
import ButtonComponent from "../../component/button"
import { AlertsService } from "../../services/alerts.service"
import { RequestsService } from "../../services/requests.service"
import { environment } from "../../common/environments"
import { Modules, ModulesResponse, ModulesStaticsInterface } from "../../@types/modules.types"
import ModulesFilterComponent from "./filter"
import { useNavigate } from "react-router-dom"
import { DefaultResponse } from "../../@types/response.types"
import DashboardCreateComponent from "../../component/dashboard/create"
import { DashboardCreateProps } from "../../@types/dashboard.component.types"
import { modulesSubMenus } from "./props"
import { useRequestProvider } from "../../context/request.provider.context"
import { Classes, ClassesResponse } from "../../@types/classes.types"
import Swal from "sweetalert2"

const ModulesComponent = (props: ModulesInterface) => {

    const navigator = useNavigate()
    const { fetchClasses } = useRequestProvider()
    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: "Módulos",
        lateralMenu: "Módulos",
        subMenus: modulesSubMenus()
    }

    const [isFetched, setFetched] = useState(false)
    const [scrollLoading, setScrollLoading] = useState(false)

    const [modules, setModules] = useState<Modules[]>([])
    const [statics, setStatics] = useState<ModulesStaticsInterface>({
        count: 0, total: 0, page: 1, totalPage: 1
    })

    /**
     * @name showNumberResults - Mostra o resultado dos usuários obtidos
     */
    const showNumberResults = () => {
        const total = statics.total
        const count = statics.count

        const countMessage = !isFetched ? "..." : count.toLocaleString("pt-BR")
        const totalMessage = !isFetched ? "..." : total.toLocaleString("pt-BR")

        return (
            <div className="modules-contents-header-text">
                <p>Mostrando { countMessage } de { totalMessage } resultados</p>
            </div>
        )
    }

    /**
     * @name showModules - Mostra os módulos cadastrados
     */
    const showModules = () => {
        if (!isFetched) 
            return <p className="modules-contents-items-loading">Buscando módulos...</p>
        if (isFetched && modules.length < 1) 
            return <h1 className="modules-contents-items-loading">Não há valores!</h1>

        const userAdded = (module: Modules) => {
            const usersSize = module.users.length
            return usersSize === 0 ? "Nenhum usuário." : `${usersSize} ${usersSize === 1 ? "usuário atribuído" : "usuários atribuídos"}`
        }

        return modules.map(module => (
            <div className="modules-contents-item" key={module.id}>
                <div className="modules-contents-item-header">
                    <p>Usuários: { userAdded(module) }</p>
                </div>

                <div className="modules-contents-item-title">
                    <p>{ module.name }</p>
                </div>

                <div className="modules-contents-item-description">
                    <p>{ module.description }</p>
                </div>

                <div className="modules-contents-item-footer">
                    <ButtonComponent
                        text="Ver módulo"
                        type="button"
                        class="modules-contents-item-footer-button"
                        onClick={() => navigator(`/modules/${module.id}`, { replace: true })}
                    />
                </div>
            </div>
        ))
    }

    /**
     * @name onScroll - Scroll para busca de módulos
     */
    const onScroll = (e: any) => {
        e.preventDefault()
        if (scrollLoading) return

        const page = statics.page
        const totalPage = statics.totalPage
        const { scrollHeight, scrollTop, clientHeight } = e.target

        if (scrollHeight - scrollTop < (clientHeight + 300)) {
            const nextPage = Number(page + 1)
            if (nextPage <= totalPage) {
                setScrollLoading(true)
                searchModules({ page: nextPage })
            }
        }
    }

    const create = async (event: any) => {
        event.preventDefault()

        const classesList: Classes[] = []
        const classesInput: any[] = []

        const nameResult = await AlertsService.confirmation({
            title: "Nome do módulo",
            html: "Insira o nome do módulo a ser criado",
            input: "text",
            inputPlaceholder: "Nome do módulo",
            confirmButtonText: "Próximo passo",
            cancelButtonText: "Cancelar"
        })

        if (!nameResult.isConfirmed) return
        const name = nameResult.value

        const descriptionResult = await AlertsService.confirmation({
            title: "Descrição do módulo",
            html: "Insira a descrição do módulo a ser criado",
            input: "textarea",
            inputPlaceholder: "Descrição do módulo",
            confirmButtonText: "Próximo passo",
            cancelButtonText: "Cancelar",
            preConfirm: async () => {
                try {
                    const classes: ClassesResponse = await fetchClasses()
                    if (!classes) {
                        throw new Error("Não foi possível buscar as turmas cadastradas")
                    }

                    classes.data?.forEach((classe: Classes) => {
                        classesList.push(classe)
                        classesInput[classe.id] = classe.name
                    })
                } catch (err) {
                    Swal.showValidationMessage(`Ocorreu um erro: ${err}`)
                }
            }
        })

        if (!descriptionResult.isConfirmed) return
        const description = descriptionResult.value
        
        let classesSelected: number[] = []
        let classesDisplay: any[] = []

        const groupsResult = await AlertsService.confirmation({
            title: "Selecione a turma",
            html: "Selecione a turma que terá acesso ao conteúdo do módulo",
            input: "select",
            confirmButtonText: "Próximo passo",
            cancelButtonText: "Cancelar",
            inputOptions: classesInput,
            inputLabel: "Turma(s) selecionada(s): Nenhuma",
            inputPlaceholder: "Selecione uma turma",
            inputValidator: () => new Promise((resolve) => 
                resolve(classesDisplay.length < 1 ? "É necessário selecionar pelo menos um dia da semana." : null)),
            didOpen: () => {
                Swal.getInput()?.addEventListener("change", async (event: any) => {
                    const classeId = parseInt(event.target.value)
                    const classe: any = classesList.find((classe: Classes) => classe.id === classeId)

                    // Está mostrando
                    if (classesDisplay.find((name: any) => name === classe.name)) {
                        classesDisplay = classesDisplay.filter((name: string) => name !== classe.name)
                        classesSelected = classesSelected.filter((id: number) => id !== classe.id)
                    } else {
                        classesDisplay.push(classe.name)
                        classesSelected.push(classe.id)
                    }

                    classesDisplay = classesDisplay.filter((classe: any) => classe)
                    const sizeOf = classesDisplay.length
                    const text = `Turma(s) selecionada(s): ${sizeOf < 1 ? "Nenhuma" : classesDisplay.join(", ")}.`
                    document.getElementsByClassName("swal2-input-label")[0].innerHTML = text
                })
            }
        })

        if (!groupsResult.isConfirmed) return

        const result = await AlertsService.confirmation({
            title: "Confirmação",
            html: `Confira os campos digitados:<br /><br />
            <strong>Nome</strong>: ${name}<br />
            <strong>Descrição</strong>: ${description}<br />
            <strong>Turmas</strong>: ${classesDisplay.join(", ")}.<br />
            `
        })

        if (!result.isConfirmed) return

        try {
            const request = await RequestsService.call({
                method: "POST",
                url: environment.URLS.MODULES.CREATE,
                data: {
                    name,
                    description,
                    classes: classesSelected
                }
            })

            if (!request || !request.data) throw new Error("No response found")
            const response: DefaultResponse = request.data
            if (!response.success) throw new Error(response.message)

            AlertsService.confirmation({ icon: response.success ? "success" : "error", title: response.message })

            if (response.success) {
                searchModules(undefined, false)
            }
        } catch (err: any) {
            return AlertsService.notification({
                icon: "error",
                title: err.response.data.message || err.message || "Ocorreu um erro na criação do módulo"
            })
        }
    }

    const searchModules = async (params?: any, withHistoric?: boolean) => {
        try {
            const request = await RequestsService.call({
                method: "GET",
                url: environment.URLS.MODULES.ALL,
                params,
            })

            if (!request || !request.data) throw new Error("No response found")
            const response: ModulesResponse = request.data
            if (!response.success) throw new Error(response.message)

            const hasHistoric = withHistoric != undefined ? withHistoric : true
            const data = response.data || []
            const count = (hasHistoric ? (statics.count || 0) : 0) + data.length
            const total = response.total
            const page = response.page || 1
            const totalPage = response.totalPage || 1
            
            setModules(hasHistoric ? modules.concat(data) : data)
            setFetched(true)
            setStatics({ count, total, page, totalPage })
            setScrollLoading(false)

            return AlertsService.notification({ icon: "success", title: response.message })
        } catch (err: any) {
            return AlertsService.notification({ icon: "error", title: err.response.data.message || "Ocorreu um erro ao obter os módulos cadastrados" })
        }
    }

    useEffect(() => {
        if (isFetched) return
        const timeout = setTimeout(() => searchModules(), environment.SERVER.TIMEOUT.FETCH_MODULES)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>
            <div className="modules-contents">
                {/* Header */}
                <div className="modules-contents-header">
                    {/* Novo plano */}
                    <div className="modules-contents-header-button">
                        <ButtonComponent
                            type="button"
                            text="Novo módulo"
                            onClick={create}
                        />
                    </div>

                    {/* Números */}
                    { showNumberResults() }

                    {/* Filtro */}
                    <ModulesFilterComponent />
                </div>

                {/* Conteúdo */}
                <div className="modules-contents-items" onScroll={onScroll}>
                    { showModules() }
                </div>
            </div>
        </DashboardCreateComponent>
    )

}

export default ModulesComponent
import { useEffect, useState } from "react"
import { MenuPrivateProps } from "../../@types/component.types"
import { DashboardCreateProps } from "../../@types/dashboard.component.types"
import DashboardCreateComponent from "../../component/dashboard/create"
import { myModulesSubMenus } from "./props"
import { ModuleResponse, Modules, ModulesResponse, MyModules } from "../../@types/modules.types"
import "./index.css"
import MyModulesFilterComponent from "./filter"
import { AlertsService } from "../../services/alerts.service"
import { RequestsService } from "../../services/requests.service"
import { environment } from "../../common/environments"
import { ClassesResponse } from "../../@types/classes.types"
import ButtonComponent from "../../component/button"
import moment from "moment"
import DashboardBack from "../dashboard/back"
import { useNavigate, useParams } from "react-router-dom"
import { StorageService } from "../../services/storage.service"

const MyModulesInfoComponent = (props: MenuPrivateProps) => {

    const navigate = useNavigate()
    const params = useParams()

    const dashboardOptions: DashboardCreateProps = {
        groups: props.groups,
        user: props.user,
        menuActiveName: "Módulos",
        lateralMenu: "Módulos",
        subMenus: myModulesSubMenus()
    }

    const [isFetched, setFetched] = useState(false)
    const [module, setModule] = useState<MyModules>()

    const searchModule = async () => {
        try {
            if (!params.id) {
                throw new Error("No moduleId found")
            }

            const moduleId = parseInt(params.id)
            const module: MyModules | undefined = StorageService.getMyModuleById(moduleId)
            if (!module) {
                throw new Error("No module found in your sessionStorage")
            }

            setFetched(true)
            setModule(module)

            console.log(module)

            AlertsService.notification({ icon: "success", title: "Módulo obtido com sucesso" })
        } catch (err: any) {
            console.log(err)

            AlertsService.notification({ icon: "error", title: err.response?.data?.message || "Ocorreu um erro ao obter os módulos cadastrados" })
        }
    } 

    useEffect(() => {
        const timeout = setTimeout(() => searchModule(), 0)
        return () => clearTimeout(timeout)   
    }, [])

    return (
        <DashboardCreateComponent { ...dashboardOptions }>
            <DashboardBack backUrl={ environment.ROUTES.MODULES.MY_MODULES } />

        </DashboardCreateComponent>
    )
}

export default MyModulesInfoComponent
import { useEffect, useState } from "react"
import { ConfigurationsUserInfoProps } from "../../../@types/configurations.types"
import { AlertsService } from "../../../services/alerts.service"
import { GraphData } from "../../../@types/graphs.types"
import { RequestsService } from "../../../services/requests.service"
import { environment } from "../../../common/environments"
import { useParams } from "react-router-dom"
import { DefaultResponse } from "../../../@types/response.types"
import { Frequency } from "../../../@types/frequency.types"
import PieGraphComponent from "../../../component/graphs/pie"

const UserFrequencyComponent = (props: ConfigurationsUserInfoProps) => {

    const params = useParams()
    const isProfile = props.isProfile ? props.isProfile : false
    const description = isProfile ? "Sua frequência nas aulas nos últimos 3 meses" : "Frequência geral do aluno nos últimos 3 meses"

    const [isFetched, setFetched] = useState(false)
    const [hasError, setError] = useState(false)
    const [frequencies, setFrequencies] = useState<GraphData[]>([])

    const searchFrequency = async () => {
        try {
            if (isFetched) return

            const { FREQUENCY } = environment.URLS
            const url = isProfile ? FREQUENCY.ALL_USER : FREQUENCY.ALL_USER_ID + params.id
            const request = await RequestsService.call({
                method: "GET",
                url
            })

            if (!request || request && !request.data) throw new Error("No response found")
            const response: DefaultResponse = request.data

            const present = response.data?.filter((frequency: Frequency) => frequency.isPresent)
            const notPresent = response.data?.filter((frequency: Frequency) => !frequency.isPresent)

            setFrequencies([
                { name: "Presenças", value: present?.length || 0 }, 
                { name: "Faltas", value: notPresent?.length || 0 }
            ])

            setFetched(true)
        } catch (err: any) {
            setError(true)
            return AlertsService.notification({ icon: 'error', title: "Ocorreu um erro ao obter a frequência geral" })
        }
    }
    
    useEffect(() => {
        if (isFetched) return

        const timeout = setTimeout(async () => {
            if (isFetched) return
            await searchFrequency()
        })

        return () => clearTimeout(timeout)
    }, [])

    return (
        <div className="configurations-user-info-content-frequency">
            <PieGraphComponent
                pieData={frequencies}
                color={["#179917", "#a50200"]}
                content={{
                    title: "Frequência geral",
                    description,
                    hasLoaded: true,
                    hasError: hasError,
                    showTotal: {
                        enable: true,
                        title: "Total geral:"
                    }
                }}
            />
        </div>
    )
}

export default UserFrequencyComponent
import React, { createContext } from "react"
import { RequestProviderProps, RequestProviderState } from "../@types/request.provider.types"
import { ClassesResponse } from "../@types/classes.types"
import { RequestsProps } from "../@types/request.types"
import { environment } from "../common/environments"
import { RequestsService } from "../services/requests.service"
import { UsersResponse } from "../@types/users.types"
import { FrequencyResponse } from "../@types/frequency.types"
import { InvoicesFetchProps, InvoicesResponse } from "../@types/invoices.types"
import { DefaultResponse } from "../@types/response.types"

const RequestProviderContext = createContext<RequestProviderState>({} as RequestProviderState)

const RequestProvider = (props: RequestProviderProps) => {

    /**
     * @name fetchClasses - Obtém as classes cadastradas
     * 
     * @param params 
     * 
     * @returns Promise
     */
    const fetchClasses = (params?: {}): Promise<ClassesResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const requestObject = {
                    method: "GET",
                    url: environment.URLS.CLASSES.LIST,
                    params,
                } as RequestsProps
                
                RequestsService.call(requestObject).then(response => resolve(response.data)).catch(reject)
            } catch (e) {
                reject(e)
            }
        })
    }

    /**
     * @name fetchClass - Obtém uma classe
     * 
     * @param id 
     * 
     * @returns Promise
     */
    const fetchClass = (id: any, params?: {}): Promise<ClassesResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const requestObject: RequestsProps = {
                    method: "GET",
                    url: `${environment.URLS.CLASSES.BY_ID}${id}`,
                    params
                }
                
                RequestsService.call(requestObject).then(response => resolve(response.data)).catch(reject)
            } catch (e) {
                reject(e)
            }
        })
    }

    /**
     * @name fetchClasses - Obtém as classes cadastradas
     * 
     * @param params 
     * 
     * @returns Promise
     */
    const fetchUsers = (params?: {}): Promise<UsersResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const requestObject = {
                    method: "GET",
                    url: environment.URLS.USER.LIST,
                    params,
                } as RequestsProps
                
                RequestsService.call(requestObject).then(response => resolve(response.data)).catch(reject)
            } catch (e) {
                reject(e)
            }
        })
    }

    /**
     * @name fetchUser - Obtém um usuário
     * 
     * @param id 
     * 
     * @returns Promise
     */
    const fetchUser = (id: any): Promise<UsersResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const requestObject = {
                    method: "GET",
                    url: `${environment.URLS.USER.BY_ID}${id}`,
                } as RequestsProps
                RequestsService.call(requestObject).then(response => resolve(response.data)).catch(reject)
            } catch (e) {
                reject(e)
            }
        })
    }

    /**
     * @name fetchFrequencies - Obtém as frequencias das aulas
     * 
     * @param prop 
     * 
     * @returns Promise
     */
    const fetchFrequencies = (prop: { allUsers?: boolean, allUser?: boolean,  userId?: number }): Promise<FrequencyResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const { ALL, ALL_USER, ALL_USER_ID } = environment.URLS.FREQUENCY
                let url = prop.allUsers ? `${ALL}` : prop.allUser ? `${ALL_USER}` : prop.userId ? `${ALL_USER_ID}${prop.userId}` : undefined
                if (undefined) return reject("No type informed")

                const requestObject = {
                    method: "GET",
                    url,
                } as RequestsProps
                RequestsService.call(requestObject).then(response => resolve(response.data)).catch(reject)
            } catch (e) {
                reject(e)
            }
        })
    }

    /**
     * @name fetchFrequencies - Obtém as frequencias das aulas
     * 
     * @param prop 
     * 
     * @returns Promise
     */
    const fetchInvoices = (prop: InvoicesFetchProps): Promise<InvoicesResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const { ALL, ALL_USER, ALL_USER_ID } = environment.URLS.INVOICES
                let url = prop.allInvoices ? `${ALL}` : prop.userInvoices ? `${ALL_USER}` : prop.userId ? `${ALL_USER_ID}${prop.userId}` : undefined
                if (!url) return reject("No type informed")

                const requestObject = {
                    method: "GET",
                    url,
                    params: {
                        ...prop.query
                    }
                } as RequestsProps
                
                RequestsService.call(requestObject).then(response => resolve(response.data)).catch(reject)
            } catch (e) {
                reject(e)
            }
        })
    }

    /**
     * @name updateInvoice - Atualiza as informações de fatura
     * 
     * @param prop 
     * 
     * @returns Promise
     */
    const updateInvoice = (prop: { invoiceId: number, enable?: boolean, paid?: boolean, discount?: boolean }): Promise<DefaultResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const requestObject: RequestsProps = {
                    method: "PUT",
                    url: `${environment.URLS.INVOICES.UPDATE}${prop.invoiceId}`,
                    params: {
                        enable: prop.enable,
                        paid: prop.paid,
                        discount: prop.discount
                    }
                }
                
                RequestsService.call(requestObject).then(response => resolve(response.data)).catch(reject)
            } catch (e) {
                reject(e)
            }
        })
    }

    return (
        <RequestProviderContext.Provider value={{
            fetchClasses,
            fetchClass,
            fetchUsers,
            fetchUser,
            fetchFrequencies,
            fetchInvoices,
            updateInvoice
        }}>
            { props.children }
        </RequestProviderContext.Provider>
    )

}

const useRequestProvider = (): RequestProviderState => React.useContext(RequestProviderContext)

export { RequestProvider, useRequestProvider }
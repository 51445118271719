import { Bar, BarConfig } from "@ant-design/plots";
import { BarGraph } from "../../../@types/graphs.types";
import "./index.css"

const BarGraphComponent = (props: BarGraph) => {
    
    if (!props.content) return <></>;
    if (!props.content.hasLoaded) return <></>;

    const showGraph = () => {
        if (props.content?.hasError) return <h1>Ocorreu um erro ao buscar os dados do gráfico.</h1>
        if (props.barData.length < 1) return <h1>Não há dados a serem mostrados.</h1>

        const options = {
            fill: "white",
            fontSize: 15
        }

        let config = {
            theme: { styleSheet: { fontFamily: "Poppins" } },
            yAxis: { label: { style: { ...options, ...props.yAxisLabelStyle } } },
            xAxis: { label: { style: { fill: "white", fontSize: 12.5, ...props.xAxisLabelStyle } } },
            legend: {
                position: "bottom",
                itemName: { style: { ...options } }
            },
        } as BarConfig

        switch (props.type) {

            case "BASIC": {
                config = {
                    ...config,
                    data: props.barData,
                    xField: "value",
                    yField: "name",
                    seriesField: "name",
                    color: props.color,
                    label: { style: { ...options } },
                }

                break;
            }

            case "STACKED": {
                config = {
                    ...config,
                    data: props.barData,
                    yField: "name",
                    xField: "value",
                    seriesField: "type",
                    isStack: true,
                    label: {
                        position: "middle",
                        style: { ...options },
                        layout: [
                            { type: "interval-adjust-position" },
                            { type: "interval-hide-overlap" },
                            { type: "adjust-color" },
                        ]
                    }
                }
                break;
            }

            case "GROUPED": {
                config = {
                    ...config,
                    data: props.barData,
                    yField: "name",
                    xField: "value",
                    seriesField: "type",
                    isGroup: true,
                    marginRatio: 0,
                    label: {
                        position: "middle",
                        style: { ...options },
                        layout: [
                            { type: "interval-adjust-position" },
                            { type: "interval-hide-overlap" },
                            { type: "adjust-color" },
                        ]
                    }
                }

                break;
            }

        }

        if (!config || config && (!config.xField || !config.yField)) return <></>
        return (
            <Bar {...config}/>
        )
    }

    const showTitle = () => {
        if (!props.content?.title) return;
        return (
            <div className="component-graph-title" style={props.content.styleTitle}>
                <h1>{props.content.title}</h1>
            </div>
        )
    }

    const showDescription = () => {
        if (!props.content?.description) return;
        return (
            <div className="component-graph-legend" style={props.content.styleDescription}>
                <p>{props.content.description}</p>
            </div>
        )
    }

    return (
        <div className="component-bar" style={props.content.style}>
            { showTitle() }
            { showDescription() }

            <div className="component-bar-graph">
                { showGraph() }
            </div>
        </div>
    )
}

export default BarGraphComponent;
import { useState } from "react"
import ButtonComponent from "../../../component/button"
import FilterComponent from "../../../component/filter"
import { AlertsService } from "../../../services/alerts.service"
import { ClassesFilterComponent } from "../../../@types/classes.types"

const TurmasFilter = (props: ClassesFilterComponent) => {

    const classWeek = [
        { id: undefined, name: "Dia da semana" },
        { id: "1", name: "Segunda" },
        { id: "2", name: "Terça" },
        { id: "3", name: "Quarta" },
        { id: "4", name: "Quinta" },
        { id: "5", name: "Sexta" },
        { id: "6", name: "Sábado" },
        { id: "0", name: "Domingo" },
    ]

    const [filtro, setFiltro] = useState(new Map<any, any>())
    const updateFilter = (key: any, value: any) => setFiltro(filtro.set(key, value))

    /**
     * @name changeFiltro - Modifica valores do filtro 
     * 
     * @param event 
     */
    const changeFilter = (event: any) => {
        // Removendo o evento default
        event.preventDefault()
        // Pegando o target
        const { target } = event
        // Pegando o id e o valor
        const { id, value } = target
        // Atualizando filtro
        updateFilter(id, value)
    }
    
    /**
     * @name searchFilter - Busca as turmas através do filtro
     */
    const searchFilter = (event: any) => {
        // Removendo o evento default
        event.preventDefault()
        // Verificando se há algo no filtro
        if (filtro.size < 1) return AlertsService.notification({ icon: "warning", title: "Não há um filtro escolhido para ser buscado" })
        // Chamando a função que busca as classes
        const jsonStringify = JSON.stringify(Object.fromEntries(filtro))
        // Chamando
        const json = JSON.parse(jsonStringify)
        props.searchClasses({
            ...json,
            isSearch: true
        })
    }

    /**
     * @name resetFilter - Reseta o filtro
     */
    const resetFilter = (event: any) => {
        // Removendo o evento default
        event.preventDefault()
        // Verificando se o filtro está com valores
        if (filtro.size < 1) return AlertsService.notification({ icon: "warning", title: "O filtro já está em seu padrão." })
        // Resetando filtro
        setFiltro(new Map<any, any>())
        // Imprimindo mensagem de sucesso
        AlertsService.notification({ icon: "success", title: "Filtro resetado com sucesso" })

        props.searchClasses({ isSearch: true })
    }

    return (
        <div className="turmas-content-filter">
            {/* Turmas por dia */}
            <FilterComponent
                type="select"
                id="classOfWeek"
                name="classOfWeek"
                title="Turma por dia de semana"
                onChange={changeFilter}
                childrens={
                    classWeek.map((week, index) => (
                        <option key={index} value={week.id}>{week.name}</option>
                    ))
                }
            />

            {/* Buscar */}
            <ButtonComponent 
                text="Buscar"
                type="submit"
                class="turmas-content-filter-button"
                onClick={searchFilter}
            />

            {/* Resetar */}
            <ButtonComponent 
                text="Resetar"
                type="reset"
                class="turmas-content-filter-button turmas-content-filter-button-reset"
                onClick={resetFilter}
            />
        </div>
    )
}

export default TurmasFilter
import './index.css'

import { MenuComponentProps, SubItemMenuComponentProps } from '../../@types/menu.component.types'
import { IoMenuSharp, IoClose } from "react-icons/io5";
import { useState } from 'react';
import SubItemComponent from './subitem';
import ButtonComponent from '../button';
import { AlertsService } from '../../services/alerts.service';
import { useAuth } from '../../context/auth.context';
import { useNavigate } from 'react-router-dom';
import { constructSubitem, formatUserPhoto, logoutUser, menuItems } from '../../common/utilitaries';
import { BiExit } from 'react-icons/bi';

const MenuComponent = (props: MenuComponentProps) => {

    const { removeLoggedAfterTokenExpired } = useAuth()
    const navigate = useNavigate()

    const [showMenuLateral, setMenuLateral] = useState(false)
    const updateMenuLateral = () => setMenuLateral(!showMenuLateral)

    const menuLateral = (bypass?: boolean) => {
        if (!showMenuLateral && (bypass !== undefined && bypass !== true)) return

        return (
            <div className="menu-component-sidebar">
                <div className="menu-component-sidebar-icons">
                    <IoClose 
                        className="menu-component-sidebar-icon" 
                        style={{ cursor: "pointer" }} 
                        onClick={updateMenuLateral} 
                    />

                    <ButtonComponent 
                        type="button" 
                        style={{ cursor: "pointer" }} 
                        text={ (<><BiExit /> Sair</>) } 
                        onClick={ logoutUser(removeLoggedAfterTokenExpired, navigate) } 
                    />
                </div>

                <div className="menu-component-sidebar-profile">
                    <img src={ formatUserPhoto(props.user) } alt="user photo sidebar" />
                    <p>{props.user.name}</p>
                </div>

                {/* SubItens  */}
                { menuItems().map(constructSubitem(props)) }
            </div>
        )
    }

    return (
        <div className="menu-component-icon">
            {/* Menu Lateral */}
            { menuLateral(window.innerWidth > 720) }
            
            <div className="menu-component-icons">
                {/* Ícone do menu */}
                <IoMenuSharp className='menu-component-icon-content' style={{ cursor: "pointer" }} onClick={ updateMenuLateral } />
            </div>
        </div>
    )

}

export default MenuComponent
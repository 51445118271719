import { ChangeEvent, useState } from "react";
import { TableComponentInterface } from "../../@types/component.types";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, ThemeProvider, createTheme } from "@mui/material";

const TableComponent = (props: TableComponentInterface) => {

    const darkTheme = createTheme({
        palette: {
            mode: "dark"
        }
    })

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 10)

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Paper sx={{ width: "100%", overflow: 'hidden' }} style={props.style}>
                <TableContainer sx={{ maxHeight: props.maxHeight || 440 }}>
                        <Table stickyHeader aria-label="sticky label">

                            {/* HEAD */}
                            <TableHead>
                                <TableRow>
                                    {props.columns.map((column) => (
                                        <TableCell key={`#C-${column.id}`} align={column.align} style={{ minWidth: column.minWidth }}>
                                            { column.label }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            {/* BODY */}
                            <TableBody>
                                {props.rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={ `#R-${row.id}` }>
                                            {props.columns.map((column) => {
                                                const value = row[column.id]
                                                return (
                                                    <TableCell key={ `#CL-${column.id}` } align={column.align}>
                                                        { column.format ? column.format(value) : value }
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                )}
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={props.rowsPerPageOptions}
                        rowsPerPage={rowsPerPage}
                        component="div"
                        count={props.rows.length}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}

                        labelRowsPerPage="Itens por página"
                    />
            </Paper>
        </ThemeProvider>
    )

}

export default TableComponent;
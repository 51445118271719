import { DashboardCreateProps } from "../../@types/dashboard.component.types"
import { constructSubMenu } from "../../common/utilitaries"
import DashboardHeader from "../../pages/dashboard/header"
import MenuDesktopComponent from "../menu-desktop"

const DashboardCreateComponent = (props: DashboardCreateProps) => {

    const isMobile = window.innerWidth < 720

    const showMenu = () => {
        if (isMobile) {
            return (
                <DashboardHeader groups={props.groups} user={props.user} menu={{ active: true, name: props.menuActiveName }}>
                    { (props.subMenus || []).map(constructSubMenu) }
                </DashboardHeader>
            )
        }

        return (<MenuDesktopComponent name={props.lateralMenu} groups={props.groups} user={props.user} />)
    }

    const content = () => {
        if (isMobile) return ( props.children )

        return (
            <div className="dashboard-content">
                <div className="dashboard-header">
                    <div className="dashboard-sub-menu">
                        <div className="dashboard-sub-menus">
                            { (props.subMenus || []).map(constructSubMenu) }
                        </div>
                    </div>
                </div>

                { props.children }
            </div>
        )
    }

    return (
        <div className="dashboard">
            {/* Menu */}
            { showMenu() }

            {/* Dashboard content */}
            { content() }
        </div>
    )
}

export default DashboardCreateComponent
import { useNavigate } from 'react-router-dom'
import logo from '../../files/images/Logo preto.png'
import { CSSProperties, useState } from 'react'
import InputComponent from '../../component/input'
import ButtonComponent from '../../component/button'
import "./index.css"
import { AlertsService } from '../../services/alerts.service'
import { RequestsService } from '../../services/requests.service'
import { DefaultResponse } from '../../@types/response.types'
import { InputProps } from '../../@types/component.types'
import * as utils from "../../common/utilitaries"
import { Plans, PlansResponse } from '../../@types/plans.types'
import { environment } from '../../common/environments'
import { RequestsProps } from '../../@types/request.types'

const RegisterComponent = () => {

    const navigate = useNavigate()
    const [data, setData] = useState<Map<any, any>>(new Map<any, any>)
    const [file, setFile] = useState(null)
    const [step, setStep] = useState<any>("showName")

    const [plans, setPlans] = useState<Plans[]>([])
    const [planFetched, setPlanFetched] = useState(false)

    /*
    NOME, FEITO
    EMAIL, FEITO
    PASSWORD, FEITO
    PHONE, FEITO
    RGI/CPF, FEITO
    BIRTHDAY, FEITO
    PHOTO, FEITO
    PLANS, FEITO
    */

    async function handleChange(event: any) {
        const name = event.target.name
        const value = event.target.value
        const files = event.target.files || []
        if (files && files.length > 0) {
            const file = files[0]
            return setFile(file)
        }

        setData(data.set(name, value.length < 1 || value === undefined ? undefined : value))
    }

    function showButton(onClick: any, backButton?: boolean, backStep?: string) {
        const isFinalStep = step === "final"
        const text = isFinalStep ? "Concluir cadastro" : "Próximo passo"
        
        const textBack = "Voltar"
        const back = () => setStep(backStep)

        return (
            <div className="register-button-action">
                {
                    backButton ? <div className="register-buton-action-back">
                        <ButtonComponent class="register-button-back" text={textBack} type="button" onClick={back} />
                        <ButtonComponent class="register-button-next" text={text} type="button" onClick={onClick} />
                    </div> : 
                    <ButtonComponent class="register-button-next" text={text} type="button" onClick={onClick} />
                }
            </div>
        )
    }
    
    function showName() {
        const isStep = step === "showName"
        if (!isStep) return <></>

        const next = () => {
            const name = data.get("name")
            if (!name) {
                return AlertsService.notification({ icon: "warning", title: "Informe seu nome completo para prosseguir com o cadastro." })
            }

            AlertsService.notification({ icon: "success", title: "Nome informado com sucesso!" })
            const timeout = setTimeout(() => setStep("showMail"), 1000)
            return () => clearTimeout(timeout)
        }

        const name = data.get("name")
        return (
            <>
                <h2 style={{ marginTop: "1.5vh" }}>Nome completo</h2>
                <p>Informe seu nome completo para prosseguirmos com seu cadastro.</p>

                <InputComponent 
                    name="name" 
                    placeholder="Digite seu nome completo"
                    value={ name ? name : "" }
                    type="text" 
                    onChange={handleChange} 
                />
                { showButton(next) }
            </>
        )
    }

    function showMail() {
        const isStep = step === "showMail"
        if (!isStep) return <></>

        const next = async () => {
            const mail = data.get("email")
            if (!mail) {
                return AlertsService.notification({ icon: "warning", title: "Informe seu e-mail para prosseguir com o cadastro." })
            }

            try {
                const request = await RequestsService.call({
                    method: "POST",
                    url: "/user/verify-email",
                    data: {
                        email: mail
                    }
                })

                if (!request || request && !request.data) throw new Error("No response found")
                const response: DefaultResponse = request.data

                AlertsService.notification({ icon: "success", title: "Código de validação de e-mail enviado com sucesso!" })
                const timeout = setTimeout(() => setStep("verifyMail"), 1000)
                return () => clearTimeout(timeout)
            } catch (err: any) {
                let message = "Ocorreu um erro ao enviar o código de validação para seu e-mail."
                if (err.response && err.response.data) {
                    message = err.response.data.message
                }

                return AlertsService.notification({ icon: 'error', title: message })
            }
        }

        const mail = data.get("email")
        return (
            <>
                <h2 style={{ marginTop: "1.5vh" }}>E-mail</h2>
                <p>Informe seu email para prosseguirmos com seu cadastro.</p>

                <InputComponent 
                    name="email" 
                    placeholder="Informe seu e-mail"
                    value={ mail ? mail : "" }
                    type="email" 
                    onChange={handleChange} 
                />
                { showButton(next, true, "showName") }
            </>
        )
    }

    function verifyMail() {
        const isStep = step === "verifyMail"
        if (!isStep) return <></>

        const next = async () => {
            const code = data.get("code")
            if (!code) {
                return AlertsService.notification({ icon: "warning", title: "É necessário informar o código para prosseguir" })
            }

            try {
                const request = await RequestsService.call({
                    method: "POST",
                    url: "/user/confirm-email",
                    data: {
                        email: data.get("email"),
                        code,
                    }
                })

                if (!request || request && !request.data) throw new Error("No response found")
                const response: DefaultResponse = request.data

                AlertsService.notification({ icon: "success", title: response.message })
                const timeout = setTimeout(() => setStep("showPassword"), 1000)
                return () => clearTimeout(timeout)
            } catch (err: any) {
                let message = "Ocorreu um erro ao enviar o código de validação para seu e-mail."
                console.log(err)

                if (err.response && err.response.data) {
                    message = err.response.data.message
                }

                return AlertsService.notification({ icon: 'error', title: message })
            }
        }

        return (
            <>
                <h2 style={{ marginTop: "1.5vh" }}>Confirmação de e-mail</h2>
                <p>Informe o código enviado para seu e-mail para prosseguir com o cadastro.</p>

                <InputComponent name="code" placeholder="Informe o código de verificação" type="text" onChange={handleChange} />
                { showButton(next, true, "showMail") }
            </>
        )
    }

    function showPassword() {
        const isStep = step === "showPassword"
        if (!isStep) return <></>

        const next = async () => {
            const password = data.get("password")
            if (!password) {
                return AlertsService.notification({ icon: "warning", title: "É necessário informar uma senha." })
            }

            AlertsService.notification({ icon: "success", title: "Senha informada com sucesso!" })
            const timeout = setTimeout(() => {
                setFile(null)
                setStep("showPersonalInfo")
            }, 1000)
            return () => clearTimeout(timeout)
        }

        return (
            <>
                <h2 style={{ marginTop: "1.5vh" }}>Senha da conta</h2>
                <p>Digite sua senha para acessar a plataforma</p>

                <InputComponent 
                    name="password"
                    id="password"
                    placeholder="Informe sua senha" 
                    type="password" 
                    onBlur={ utils.formatPassword }
                    onChange={handleChange} 
                />

                <p style={{ fontSize: ".8rem", margin: "0", marginTop: "8px" }}>* Precisa ter no mínimo 6 caracteres</p>
                <p style={{ fontSize: ".8rem", margin: "0" }}>* Conter caracteres especiais e números</p>
                <p style={{ fontSize: ".8rem", margin: "0" }}>* Não ter espaços na senha</p>
                { showButton(next, true, "showMail") }
            </>
        )
    }

    function showPersonalInfo() {
        const isStep = step === "showPersonalInfo"
        if (!isStep) return <></>

        const next = async () => {
            const rgi = data.get("rgi")
            if (!rgi) {
                return AlertsService.notification({ icon: "warning", title: "É necessário que você informe seu CPF" })
            }

            const phone = data.get("phone")
            if (!phone) {
                return AlertsService.notification({ icon: "warning", title: "É necessário que você informe seu número de celular" })
            }

            AlertsService.notification({ icon: "success", title: "Sucesso! Mostrando os planos disponíveis" })
            const timeout = setTimeout(() => {
                setStep("showPlans")
                setData(data.set("plan_id", undefined))
            }, 1000)
            return () => clearTimeout(timeout)
        }

        const defaultProps: any = {
            hasLabel: true,
            labelDirection: "left",
            labelStyle: {
                fontSize: "1rem"
            }
        }

        const rgi = data.get("rgi")
        const phone = data.get("phone")
        const birthday = data.get("birthday")
        return (
            <>
                <h2 style={{ marginTop: "1.5vh" }}>Informações</h2>
                <p>Digite algumas informações nos campos abaixos</p>

                <InputComponent 
                    { ...defaultProps }
                    id="rgi"
                    name="rgi"
                    type="text" 
                    label="CPF" 
                    isNecessary={true} 
                    placeholder="Digite apenas números"
                    value={ rgi ? utils.formatCpf(rgi) : "" }
                    maxLength={11}
                    onBlur={ utils.formatCpfInput }
                    onChange={handleChange}
                />
                
                <InputComponent
                    { ...defaultProps } 
                    id="phone"
                    name="phone"
                    type="text" 
                    label="Número de celular" 
                    isNecessary={true} 
                    placeholder='(00) 0 0000-0000'
                    maxLength={16}
                    value={ phone ? utils.formatPhoneNumber(phone) : "" }
                    onBlur={ utils.formatPhoneInput }
                    onChange={handleChange}
                />

                <InputComponent
                    { ...defaultProps }
                    id="birthday"
                    name="birthday"
                    type="text"
                    label="Data de nascimento"
                    placeholder="00/00/0000"
                    maxLength={10}
                    value={ birthday ? utils.formatBirthdayDate(birthday) : "" }
                    onBlur={ utils.formatBirthdayInput }
                    onChange={handleChange}
                />

                <InputComponent
                    { ...defaultProps }
                    id="photo"
                    name="photo"
                    type="file"
                    label="Foto de perfil"
                    onChange={handleChange}
                />

                { showButton(next, true, "showPassword") }
            </>
        )
    }

    function showPlans() {
        const isStep = step === "showPlans"
        if (!isStep) return <></>

        const showPlans = async () => {
            try {
                const request = await RequestsService.call({
                    method: "GET",
                    url: environment.URLS.PLANS.ALL
                })
    
                if (!request || (request && !request.data)) throw new Error("No response found")
                const response: PlansResponse = request.data

                setPlans(response.data || [])
                setPlanFetched(true)
            } catch (err: any) {
                let message = "Ocorreu um erro ao obter os planos"
                console.log(err)

                if (err.response && err.response.data) {
                    message = err.response.data.message
                }

                AlertsService.notification({ icon: 'error', title: message })
                return <></>
            }
        }

        if (!planFetched) showPlans()

        const setPlan = (plan: Plans) => (e: any) => {
            if (!plan) return
            if (e) e.preventDefault()

            setData(data.set("plan_id", plan.id))
            setData(data.set("plan", plan))

            next()
        }

        const next = async () => {
            const plan_id = data.get("plan_id") || undefined
            if (!plan_id) {
                return AlertsService.notification({ icon: "warning", title: "É necessário escolher um plano para ir para ir ao próximo passo" })
            }

            AlertsService.notification({ icon: "success", title: "Sucesso! Confirmando informações..." })
            const timeout = setTimeout(() => setStep("final"), 600)

            return () => clearTimeout(timeout)
        }

        const constructPlan = (plan: Plans, index: number) => {
            const id = `#plan-${index}`
            const planSelected = data.get("plan_id") || undefined
            const isSelected = planSelected ? planSelected === plan.id : false
            const className = `plan-box-select${isSelected ? " plan-box-selected" : ""}`
            
            return (
                <div className={className} key={id} onClick={setPlan(plan)}>
                    <div className="plan-box-select-price">
                        <h1>{ `R$${utils.formatPlanPrice(plan.price.toLocaleString('pt-br'))}` }</h1>
                    </div>

                    <div className="plan-box-select-info">
                        <h3>{plan.name}</h3>
                        <p>{ utils.formatPaymentMethods(plan.paymentMethods, true) }</p>
                    </div>
                </div>
            )
        }

        return (
            <>
                <h2 style={{ marginTop: "1.5vh" }}>Planos</h2>
                <p>Escolha o melhor plano que se adeque melhor à você</p>

                <div className="plans-list">
                    { plans.map(constructPlan) }
                </div>

                { showButton(next, true, "showPersonalInfo") }
            </>
        )
    }

    function final() {
        const isStep = step === "final"
        if (!isStep) return <></>

        const name = data.get("name")
        const email = data.get("email")
        const password = data.get("password")
        const phone = data.get("phone")
        const rgi = data.get("rgi")
        const birthday = data.get("birthday") || undefined
        const plan: Plans = data.get("plan")

        const next = async () => {
            const object: any = {
                name,
                email,
                password,
                rgi,
                phone,
                photo: file,
                birthday,
                plan_id: plan.id,
            }

            try {
                const formData = new FormData()
                for (var key in object) {
                    formData.append(key, object[key])
                }

                const requestObject: RequestsProps = {
                    method: "POST",
                    url: environment.URLS.USER.CREATE,
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                const response = await RequestsService.call(requestObject)
                if (!response) throw new Error("Nenhum response")

                const data: DefaultResponse = response.data
                if (!data.success) {
                    throw new Error(data.message)
                }

                AlertsService.notification({ icon: "success", title: "Usuário registrado com sucesso!" })
                const timeout = setTimeout(() => navigate("/", { replace: true }), 2000)
                return () => clearTimeout(timeout)
            } catch (err: any) {
                let message = "Ocorreu um erro ao criar seu usuário na plataforma"
                if (err.response && err.response.data) {
                    message = err.response.data.message
                }

                return AlertsService.notification({ icon: 'error', title: message })
            }
        }


        return (
            <>
                <h2 style={{ marginTop: "1.5vh" }}>Confirmar informações</h2>
                <p>Verifique as informações cadastradas para concluir o cadastro do seu usuário</p>

                <div className="register-show-info">
                    <p><strong>Nome: </strong>{ name }</p>
                    <p><strong>Email: </strong>{ email }</p>
                    <p><strong>Telefone: </strong>{ utils.formatPhoneNumber(phone) }</p>
                    <p><strong>CPF: </strong>{ utils.formatCpf(rgi) }</p>
                    <p><strong>Data de nascimento: </strong>{ birthday ? utils.formatBirthdayDate(birthday) : "Não informado" }</p>
                    <p><strong>Plano: </strong>{ plan?.name } (R${ utils.formatPlanPrice(plan.price) })</p>
                </div>

                { showButton(next, true, "showPlans") }
            </>
        )
    }

    return (
        <div className="login-container login-align-itens">
            <div className="login-box">
                {/* Header */}
                <div className="login-box-header">
                    <img src={logo} alt="logo"></img>
                </div>

                {/* Content */}
                <div className="login-box-content">
                    {/* Show name */}
                    { showName() }
                    { showMail() }
                    { verifyMail() }
                    { showPassword() }
                    { showPersonalInfo() }
                    { showPlans() }
                    { final() }
                </div>
            </div>
        </div>
    )

}

export default RegisterComponent
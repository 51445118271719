// Stylesheet //
import { CSSProperties } from "react";
import { InputProps } from "../../@types/component.types";

import "./input.css"

/**
 * @name InputComponent - Component dos input
 * 
 * @param props 
 * 
 * @returns Component
 */
const InputComponent = (props: InputProps) => {
    const className = "component-input" + (props.class === undefined ? "" : " " + props.class)

    const buildInput = (className: string, style: CSSProperties | undefined) => {
        const list = ["checkBox", "file"]

        return (
            <input
                id={props.id}
                className={ list.includes(props.type) ? (props.class || undefined) : className }
                name={props.name}
                placeholder={props.placeholder}
                // value={props.value}
                defaultValue={props.value}
                autoComplete={props.autoComplete ? "on" : "off"}
                type={props.type}
                style={style}
                onChange={props.onChange}
                onBlur={props.onBlur}
                onBlurCapture={props.onBlurCapture}
                maxLength={props.maxLength}
            />
        )
    }

    // Quando o input existe um ícone //
    if (props.hasIcon) {
        return (
            <div className="input-components">
                {/* Icons */}
                <span>
                    {props.icon}
                </span>

                {/* Building input property */}
                {buildInput(props.class || "", {})}
            </div>
        )
    }

    // Quando o input é um label //
    if (props.hasLabel) {
        const constructLabel = () => {
            return (
                <label htmlFor={props.name} className="component-input-label" style={props.labelStyle}>
                    {props.label}
                    { props.isNecessary ? <span style={{ color: "red" }}> *</span> : "" }
                </label>
            )
        }

        return (
            <div className="input-components-label" style={props.labelStyle}>
                { props.labelDirection == "left" ? constructLabel() : undefined }
                {/* Building input property */}
                {buildInput(className, props.style)}
                { props.labelDirection == "right" ? constructLabel() : undefined }
            </div>
        )
    }

    return buildInput(className, props.style)
}

export default InputComponent
import { BiListUl } from "react-icons/bi";
import { environment } from "../../common/environments";
import { Classes } from "../../@types/classes.types";
import { AlertsService } from "../../services/alerts.service";
import Swal from "sweetalert2";

export const turmaSubMenus = () => {
    return [
        { name: "Turmas", redirectTo: environment.ROUTES.TURMAS.HOME, hasIcon: true, icon: <BiListUl /> }
    ]
}

const dayOfWeeks = [
    { id: 0, name: "Domingo" },
    { id: 1, name: "Segunda" },
    { id: 2, name: "Terça" },
    { id: 3, name: "Quarta" },
    { id: 4, name: "Quinta" },
    { id: 5, name: "Sexta" },
    { id: 6, name: "Sábado" },
]

interface ModalClasseInterface {
    classe?: Classes

    nameTitle: string
    nameHtml: string
    descriptionTitle: string
    descriptionHtml: string
    daysTitle: string
    daysHtml: string
    hourTitle: string
    hourHtml: string
}

export const modalClasse = async (props: ModalClasseInterface) => {
    const types: any = {
        input: "text",
        confirmButtonText: "Próximo passo",
        cancelButtonText: "Cancelar"
    }

    const { classe } = props

    const nameResult = await AlertsService.confirmation({
        title: props.nameTitle,
        html: props.nameHtml,
        ...types,
        inputPlaceholder: classe?.name || "",
        inputValue: classe?.name || ""
    })

    if (!nameResult.isConfirmed) return;

    const descriptionResult = await AlertsService.confirmation({
        title: props.descriptionTitle,
        html: props.descriptionHtml,
        ...types,
        input: "textarea",
        inputPlaceholder: classe?.description || "",
        inputValue: classe?.description || ""
    })

    if (!descriptionResult.isConfirmed) return;

    const dayOfWeeksFormatted: any[] = []
    dayOfWeeks.forEach((day: any) => {
        dayOfWeeksFormatted[day.id] = day.name
    })

    let daysSelected: number[] = []
    let daysDisplay: any[] = []

    const daysResult = await AlertsService.confirmation({
        title: props.daysTitle,
        html: props.daysHtml,
        ...types,
        input: "select",
        inputOptions: dayOfWeeksFormatted,
        inputLabel: "Dias selecionados: Nenhum.",
        inputPlaceholder: "Selecione os dias da semana",
        inputValidator: () => new Promise((resolve) => 
            resolve(daysDisplay.length < 1 ? "É necessário selecionar pelo menos um dia da semana." : null)),
        didOpen: () => {
            Swal.getInput()?.addEventListener('change', async (event: any) => {
                const dayId = event.target.value
                const day = dayOfWeeks.filter((d: any) => d.id == dayId)[0]
                    
                // Está mostrando
                if (daysDisplay.findIndex((d) => d == day.name) !== -1) {
                    daysDisplay = daysDisplay.filter((d) => d != day.name)
                    daysSelected = daysSelected.filter((d) => d != day.id)
                } else {
                    daysDisplay.push(day.name)
                    daysSelected.push(day.id)
                }
                    
                daysDisplay = daysDisplay.filter((d) => d)
                const sizeOf = daysDisplay.length
                const text = `Dias selecionados: ${sizeOf < 1 ? "Nenhum" : daysDisplay.join(", ")}.`
                document.getElementsByClassName("swal2-input-label")[0].innerHTML = text
            })
        }
    })

    if (!daysResult.isConfirmed) return;

    const hourResult = await AlertsService.confirmation({
        title: props.hourTitle,
        html:  props.hourHtml,
        inputPlaceholder: "hh:mm",
        ...types,
        confirmButtonText: "Validar informações",
    })

    if (!hourResult.isConfirmed) return;

    try {
        const hour = String(hourResult.value)
        if (!hour.includes(":")) {
            return AlertsService.notification({ 
                icon: "error", 
                title: "O formato aceito no horário da turma é hh:mm. Informe novamente." 
            })
        }

        const split = hour.split(":")
        const splitHour = String(split[0])
        const splitMinutes = String(split[1])
        if ((splitHour.toString().length != 2) || (splitMinutes.toString().length != 2)) {
            AlertsService.notification({ 
                icon: "error", 
                title: "O formato aceito no horário da turma é hh:mm. Informe novamente." 
            })

            return
        }
    } catch (err) {
        AlertsService.notification({ 
            icon: "error",
            title: "O formato aceito no horário da turma é hh:mm. Informe novamente." 
        })
        
        return
    }

    const valid = await AlertsService.confirmation({
        title: "Validação de informações",
        html: `
        <strong>Nome</strong>: ${nameResult.value} <br/>
        <strong>Descrição</strong>: ${descriptionResult.value} <br/>
        <strong>Dias da semana</strong>: ${daysDisplay.join(", ")}. <br/>
        <strong>Horário</strong>: ${hourResult.value} <br/>`,
        confirmButtonText: props.classe ? "Atualizar turma" : "Criar turma",
        showCancelButton: true,
        cancelButtonText: "Cancelar operação",
    })

    if (!valid.isConfirmed) {
        AlertsService.notification({ icon: "success", title: "Operação cancelada com sucesso." });
        return
    }

    return {
        name: nameResult.value,
        description: descriptionResult.value,
        daysOfWeek: daysSelected,
        hours: [hourResult.value]
    }
}